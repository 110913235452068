import React, { useState } from "react";
import { TagsInput } from "../form";
import "./email-list.scoped.scss";

const EMAILS_LIMIT = 10;

export type EmailListProps = {
  onListChange?: (t: EmailListModel) => void;
  values?: string[];
};

export type EmailListModel = {
  emails: string[];
  isValidList: boolean;
};

export function EmailList({ onListChange, values }: EmailListProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const validateEmail = (email: string) => {
    return !!email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleOnChange = (
    tags: string[],
    isValidInput: boolean,
    isLimitExceeded: boolean,
    hasDuplicates: boolean
  ): void => {
    let errorMessage = isValidInput ? "" : "Invalid email";
    errorMessage = hasDuplicates
      ? "There are duplicated recipients"
      : errorMessage;
    errorMessage = isLimitExceeded
      ? `It is not possible to register more than ${EMAILS_LIMIT} recipients`
      : errorMessage;
    setErrorMessage(errorMessage);
    onListChange &&
      onListChange({ emails: tags, isValidList: errorMessage === "" });
  };

  return (
    <div className="email-list">
      <div className="step-title-text">
        <span className="step-title-text-main">Share title updates</span>
        <span className="step-title-text-description">(optional)</span>
      </div>
      <span>Add people to share any title updates</span>
      <TagsInput
        placeHolder="Type email..."
        validator={validateEmail}
        onChange={handleOnChange}
        limit={EMAILS_LIMIT}
        values={values}
      />
      <span className="error-message">{errorMessage}</span>
    </div>
  );
}
