import React, { useState, ReactNode } from "react";
import { FaCheckCircle, FaExclamationTriangle, FaTimes } from "react-icons/fa";
import { useSetTimeout } from "../../hooks";
import "./toast.scoped.scss";

const DEFAULT_DURATION = 5000;

export enum ToastType {
  NULL = "",
  SUCCESS = "success",
  ERROR = "error",
}

export type ToastProps = {
  duration?: number;
  type?: ToastType;
  message?: string;
  children?: JSX.Element | HTMLElement | ReactNode | undefined;
  closeable?: boolean;
  toastKey?: string;
  onHide?: (k: string | undefined) => void;
};

/**
 * This component is a Toast notification which is going to display
 * a message for a certain period of time, then it will automatically
 * hide. The css style will be set according to the type property.
 * @param {ToastProps} props - Props values injected to the component.
 * @returns {JSX.Element}
 */
export const Toast = ({
  duration,
  type,
  message,
  children,
  closeable,
  onHide,
  toastKey,
}: ToastProps): JSX.Element => {
  const [hidden, setHidden] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setShowToast = (show: boolean): void => {
    if (show) {
      setHidden("");
    } else {
      setHidden("hidden");
      if (onHide) {
        onHide(toastKey);
      }
    }
  };

  const [reset] = useSetTimeout(() => {
    setShowToast(false);
  }, duration || DEFAULT_DURATION);

  return (
    <div
      className={`uw-toast type-${type as string} ${hidden}`}
      onMouseOver={reset}
      onFocus={reset}
    >
      <div className="uw-toast-container">
        {type === "success" ? (
          <div className="uw-toast-icon">
            <FaCheckCircle />
          </div>
        ) : null}
        {type === "error" ? (
          <div className="uw-toast-icon">
            <FaExclamationTriangle />
          </div>
        ) : null}
        <div className="uw-toast-message">
          {(children as any) ||
            message ||
            (type === ToastType.ERROR ? (
              <div>
                <b>An error ocurred</b>
                <br />
                please try again later.
              </div>
            ) : null)}
        </div>
      </div>
      {closeable ? (
        <button
          className="uw-toast-close"
          type="button"
          onClick={() => setShowToast(false)}
        >
          <FaTimes />
        </button>
      ) : null}
    </div>
  );
};

Toast.defaultProps = {
  duration: DEFAULT_DURATION,
  type: ToastType.NULL,
  children: undefined,
  toastKey: "",
  onHide: () => null,
  closeable: false,
  message: undefined,
};
