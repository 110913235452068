import { isLeapYear } from ".";

export const isValidDay = (dd: number, mm: number, yyyy: number): boolean => {
  const daysByMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  let maxDays = daysByMonth[mm - 1];
  if (isLeapYear(yyyy) && mm === 2) {
    maxDays += 1;
  }
  return dd > 0 && dd <= maxDays;
};
