/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { FaTrash } from "react-icons/fa";
import { ConfirmationDialog } from "../../components/confirmation-dialog";

type DeletePopoverProps = {
  onDeleteHandle: (item: any) => void;
  item: any;
};

export const DeletePopover = ({
  onDeleteHandle,
  item,
}: DeletePopoverProps): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);

  const onConfirmHandle = (): void => {
    setShow(false);
    onDeleteHandle(item);
  };

  return (
    <div>
      <IconButton
        disableRipple
        size="small"
        onClick={() => {
          setShow(true);
        }}
      >
        <FaTrash className="delete-icon" />
      </IconButton>
      {show && (
        <ConfirmationDialog
          handleConfirm={onConfirmHandle}
          handleCancel={() => setShow(false)}
        />
      )}
    </div>
  );
};
