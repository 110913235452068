import { DropZoneFileDTO } from "../components/drop-zone";
import { FileNameParser } from "../utils/FileNameParser";

type VideoFileStatus = {
  message: string;
  status: boolean;
};

export function validateVideoFiles(files: DropZoneFileDTO[]): VideoFileStatus {
  const result: VideoFileStatus = {
    message: "",
    status: false,
  };

  const parser = new FileNameParser(files[0].name);

  if (files.length > 1) {
    result.message = "You can only upload one video file.";
  } else if (!parser.validateVideoFileName()) {
    result.message = "Invalid file name";
  } else if (!parser.isValidLanguageCode()) {
    result.message = "Invalid file language";
  } else {
    result.status = true;
  }

  return result;
}
