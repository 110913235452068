import { DsTitleAPIService } from "./ds-title-api.service";
import { DmdcTitleAPIService } from "./dmdc-title-api.service";
import { TitleAPIService } from "./title-api.types";

export class TitleAPIFactory {
  private static instance: TitleAPIService;
  token: string;
  context: string;

  private constructor(token: string, context: string) {
    this.token = token;
    this.context = context;
  }

  public static getInstance(
    token: string,
    context: string,
    applicationId?: string
  ): TitleAPIService {
    if (context === "DMDC") {
      TitleAPIFactory.instance = new DmdcTitleAPIService(
        token,
        applicationId as string
      );
    } else {
      TitleAPIFactory.instance = new DsTitleAPIService(token);
    }
    return TitleAPIFactory.instance;
  }
}
