import { Root, createRoot } from "react-dom/client";
import {
  StatusWidgetProps,
  StatusWidget,
} from "../lib/widgets/status/status-widget";
import { createElement } from "react";

export class DigitalScreenersStatusWidget extends HTMLElement {
  root: Root | undefined;
  connectedCallback(): void {
    const props = Object.values(this.attributes).map((attribute) => {
      let { name } = attribute;
      if (attribute.name === "x-ds-portal-id") {
        // Mapping x-ds-portal-id prop to a common attribute used in the rest
        // of the application.
        name = "portal";
      }
      return [name, attribute.value];
    });
    this.root = createRoot(this);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.root.render(
      createElement(
        StatusWidget,
        Object.fromEntries(props) as StatusWidgetProps
      )
    );
  }

  disconnectedCallback(): void {
    this.root?.unmount();
  }
}

export default DigitalScreenersStatusWidget;
