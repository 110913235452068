import { TITLE_API_BASE_URI } from "./title-api.const";
import {
  GetTitleChildrenResponse,
  GetTitleDetailsResponse,
  GetTitleVersionsResponse,
  SearchRequest,
  SearchResponse,
  TitleAPIServiceInterface,
} from "./title-api.types";
import { HttpClient } from "../../core/classes/HttpClient";

export class DsTitleAPIService implements TitleAPIServiceInterface {
  token: string | undefined;

  client: HttpClient;

  public constructor(token: string | undefined) {
    this.token = token;
    this.client = new HttpClient(
      window.titleAPIUrl || TITLE_API_BASE_URI || "",
      {
        Authorization: this.token || "",
      }
    );
  }

  public searchTitle = async (
    searchRequest: SearchRequest
  ): Promise<SearchResponse> => {
    const response = await this.client.post("/search", searchRequest);
    return response;
  };

  public getTitleDetails = async (
    titleId: number | string | undefined
  ): Promise<GetTitleDetailsResponse> => {
    const response = await this.client.get(
      `/${(titleId || "").toString()}/details`
    );
    return response;
  };

  public getTitleChildren = async (
    titleId: number | string | undefined
  ): Promise<GetTitleChildrenResponse> => {
    const response = await this.client.get(
      `/${(titleId || "").toString()}/children`
    );
    return response;
  };

  public getTitleVersions = async (
    titleId: number | string | undefined
  ): Promise<GetTitleVersionsResponse> => {
    const response = await this.client.get(
      `/${(titleId || "").toString()}/versions`
    );
    return response;
  };
}
