import { HttpClient } from "../../core/classes/HttpClient";
import {
  SearchResult,
  TitleSearchQuery,
  SearchResponse,
  SearchableProductTypes,
  SearchableProductTypesByKeyword,
  ProductType,
} from "./dmdc-title-api.types";

const TITLE_API_BASE_URI = process.env.REACT_APP_DMDC_TITLE_API__URL as string;

export class DmdcTitleAPIService {
  public constructor(
    protected readonly token: string,
    protected readonly applicationId: string,
    protected readonly client = new HttpClient(TITLE_API_BASE_URI, {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
    })
  ) {}

  public async search(payload: TitleSearchQuery): Promise<SearchResult> {
    payload.fields = payload.fields || [];
    if (!payload.fields.find((field) => field.name === "ProductTypeIds")) {
      payload.fields.push({
        name: "ProductTypeIds",
        value: SearchableProductTypes.join(", "),
      });
    }
    payload.fields.push({
      name: "ApplicationId",
      value: this.applicationId,
    });

    const response: SearchResponse = await this.client.post("/search", {
      body: payload,
    });

    if (response?.data?.items === undefined) {
      throw new Error("Request Error");
    }
    return response.data;
  }

  public async searchTitleByKeyword(
    keyword: string,
    options: TitleSearchQuery = {}
  ): Promise<SearchResult> {
    const payload: TitleSearchQuery = {
      ...options,
      keyword,
      fields: [
        ...(options?.fields || []),
        {
          name: "ProductTypeIds",
          value: SearchableProductTypesByKeyword.join(", "),
        },
      ],
    };
    return await this.search(payload);
  }

  public async searchTitleByVersionId(
    versionId: number,
    options: TitleSearchQuery = {}
  ): Promise<SearchResult> {
    const payload: TitleSearchQuery = {
      ...options,
      fields: [
        ...(options?.fields || []),
        { name: "VersionId", value: versionId },
      ],
    };
    return await this.search(payload);
  }

  public async searchTitleByKeywordOrVersion(
    keyword: string,
    options: TitleSearchQuery = {}
  ): Promise<SearchResult> {
    const payload: TitleSearchQuery = {
      ...options,
      fields: [
        ...(options?.fields || []),
        {
          name: "ProductTypeIds",
          value: SearchableProductTypes.join(", "),
        },
      ],
    };
    if (keyword.startsWith("V")) {
      payload.fields?.push({ name: "VersionId", value: keyword.substring(1) });
    } else {
      payload.keyword = keyword;
    }
    return await this.search(payload);
  }

  public async searchChildTitles(
    productId: string,
    options: TitleSearchQuery = {}
  ): Promise<SearchResult> {
    const payload: TitleSearchQuery = {
      ...options,
      fields: [
        ...(options?.fields || []),
        { name: "ParentTitleId", value: productId },
        {
          name: "ProductTypeIds",
          value: `${ProductType.SEASON}, ${ProductType.EPISODE}`,
        },
      ],
    };
    return await this.search(payload);
  }
}
