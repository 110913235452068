import React, { useState, useRef, useEffect, ReactElement } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { SuggestionList } from "./suggestionList";
import { Input } from "../form/input";
import { DSPackage } from "@screeners/upload-datamodel";

type AutocompleteProps = {
  /* autocomplete?: boolean; */
  className?: string;
  placeholder?: string;
  startAdornment?: ReactElement;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  suggestions?: DSPackage[] | any[];
  disablingCriteria?: (value: any) => boolean;
  disabled: boolean;
  onChange?: (value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: (value: any, index?: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onKeyDown?: (event: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stringMapper?: (item: any, format?: string) => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  suggestionItem?: (props: {
    item: DSPackage;
    disabled: boolean;
  }) => React.ReactElement;
  value?: string;
  loading?: boolean;
};

const defaultProps = {
  /* autocomplete: false, */
  className: "",
  placeholder: "Search",
  startAdornment: null,
  suggestions: [],
  disablingCriteria: () => false,
  disabled: false,
  onChange: undefined,
  onSelect: undefined,
  value: undefined,
  stringMapper: undefined,
  suggestionItem: undefined,
  loading: false,
};

export const Autocomplete = ({
  /* autocomplete, */
  className,
  placeholder,
  startAdornment,
  suggestions,
  disablingCriteria,
  disabled,
  value,
  onChange,
  onSelect,
  onKeyDown,
  stringMapper,
  loading,
  suggestionItem,
}: AutocompleteProps): JSX.Element => {
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>(0);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [stateValue, setStateValue] = useState<string>(value || "");
  const [selectedItem, setSelectedItem] = useState<unknown>(null);

  useEffect(() => {
    setStateValue(value || "");
  }, [value]);

  useEffect(() => {
    setShowSuggestions(true);
  }, [suggestions]);

  useEffect(() => {
    setShowSuggestions(false);
  }, [selectedItem]);

  const inputRef = useRef(null);

  const selectItem = (index: number): void => {
    if (suggestions && !disablingCriteria?.(suggestions[index])) {
      setSelectedItem(suggestions && suggestions[index]);
      setStateValue(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        stringMapper ? stringMapper(suggestions[index]) : suggestions[index]
      );
      if (onSelect) {
        onSelect(suggestions[index], index);
      }
    }
  };

  const onClick = (index: number): void => {
    selectItem(index);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onKeyDownInternal = (event: any): void => {
    if (onKeyDown) {
      onKeyDown(event);
    } else {
      if (suggestions && suggestions.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (event.keyCode === 38 && activeSuggestionIndex > 0) {
          setActiveSuggestionIndex((prev) => prev - 1);
        } else if (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          event.keyCode === 40 &&
          activeSuggestionIndex < suggestions.length - 1
        ) {
          setActiveSuggestionIndex((prev) => prev + 1);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        } else if (event.keyCode === 13) {
          selectItem(activeSuggestionIndex);
        }
      }
    }
  };

  const onChangeInternal = (v: string): void => {
    if (onChange) {
      setActiveSuggestionIndex(0);
      onChange(v);
    }
  };

  function clickOutsideHandler(): void {
    setShowSuggestions(false);
  }

  useOnClickOutside(inputRef, clickOutsideHandler);

  return (
    <div className="input-container" ref={inputRef}>
      <Input
        startAdornment={startAdornment}
        className={`input-element ${className || ""}`}
        data-testid="test-input"
        onChange={onChangeInternal}
        onKeyDown={onKeyDownInternal}
        placeholder={placeholder}
        disabled={disabled}
        value={stateValue}
      />
      {loading ? (
        <CircularProgress
          size={28}
          thickness={6}
          sx={{
            color: "#8b8b8b",
            display: "inline-block",
            padding: "3px",
            position: "absolute",
            right: "8px",
            top: "6px",
          }}
        />
      ) : null}
      {showSuggestions && suggestions && (
        <SuggestionList
          activeIndex={activeSuggestionIndex}
          stringMapper={stringMapper}
          filteredSuggestions={suggestions}
          disablingCriteria={disablingCriteria}
          suggestionItem={suggestionItem}
          onClick={onClick}
        />
      )}
    </div>
  );
};

Autocomplete.defaultProps = defaultProps;
