import React from "react";
import { TagProps } from "./tag.types";
import "./tag.scoped.scss";

export const TagForwardRef = React.forwardRef(
  (props: TagProps, ref: React.ForwardedRef<HTMLSpanElement>) => {
    return <Tag {...props} forwardedRef={ref} />;
  }
);

export const Tag = (props: TagProps) => {
  const { remove, tag, closable = true, forwardedRef } = props;
  const handleOnRemove = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    remove?.(tag);
  };

  return (
    <span
      {...props}
      ref={forwardedRef}
      style={{ backgroundColor: tag.color, borderColor: tag.color }}
      className={`tag ${!tag.isValid || tag.isDuplicated ? "has-errors" : ""}`}
    >
      <span>{tag.value}</span>
      {closable ? (
        <button
          type="button"
          onClick={handleOnRemove}
          aria-label={`remove ${tag.value}`}
        >
          &#10005;
        </button>
      ) : null}
    </span>
  );
};

TagForwardRef.displayName = "TagForwardRef";
Tag.displayName = "Tag";
