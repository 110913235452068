import { createContext, useContext } from "react";

/**
 * This type describes the properties related with a context
 *  for the select and option components communication
 */
type context = {
  selectedOption: string;
  changeSelectedOption: (
    option: string,
    value: unknown,
    hideOnSelect: boolean,
    reser: boolean
  ) => void;
};

const SelectContext = createContext<context>({
  selectedOption: "",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  changeSelectedOption: (option: string) => undefined,
});

const useSelectContext = (): context => {
  const context = useContext(SelectContext);
  if (!context) {
    throw new Error("Error in creating the context");
  }
  return context;
};

export { useSelectContext, SelectContext };
