import {
  FileType,
  FileNameParserInterface,
  ENTERPRISE_NAMING_CONVENTION_REGEX,
  NameGroups,
} from "../const/file-types";

export class EnterpriseFileNameParser implements FileNameParserInterface {
  private groups: NameGroups;
  public constructor(public readonly fileName: string) {
    this.groups = ENTERPRISE_NAMING_CONVENTION_REGEX.exec(this.fileName)
      ?.groups as unknown as NameGroups;
  }
  public getVersion(): number | undefined {
    const versionId = this.groups.versionId;
    return /[a-z]/i.test(this.groups.versionId)
      ? undefined
      : parseInt(versionId);
  }
  public getLanguageCode(): string | undefined {
    if (this.groups.videoSpecification) {
      if (this.getFileType() === FileType.DUBCARD) {
        return this.groups.videoLanguage;
      }
      return this.groups.audioLanguage;
    }
    if (this.groups.audioSpecification) {
      return this.groups.audioLanguage;
    }
    if (this.groups.textSpecification) {
      return this.groups.textLanguage;
    }
    return undefined;
  }

  public getFileDetails(): NameGroups | undefined {
    return this.groups;
  }

  public validateComponentFileName(): boolean {
    return (
      ![FileType.VIDEO, FileType.UNKNOWN].includes(this.getFileType()) &&
      !!this.getLanguageCode()
    );
  }

  public getFileType(): FileType {
    const fileType = FileType.UNKNOWN;
    if (this.groups.videoSpecification) {
      return this.groups.type === "DC" ? FileType.DUBCARD : FileType.VIDEO;
    }
    if (this.groups.audioSpecification) {
      return FileType.AUDIO;
    }
    if (this.groups.textSpecification) {
      return this.groups.timedTextType === "FRCD"
        ? FileType.FORCED
        : FileType.SUBTITLE;
    }

    return fileType;
  }
  public validateVideoFileName(): boolean {
    return this.getFileType() === FileType.VIDEO && !!this.getLanguageCode();
  }
}
