import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdWarning } from "react-icons/io";
import { ItemStatus } from "../../utils";
/**
 * This type describes the properties related with the Status Cell component
 */
type StatusIconProps = {
  status: string;
  className: string;
};

/**
 * This functional component contains the status icon based on
 * the status name
 * @param {StatusIconProps} props
 * @returns {JSX.Element}
 */
export const StatusIcon = ({
  status,
  className,
}: StatusIconProps): JSX.Element =>
  status === ItemStatus.success ? (
    <FaCheckCircle className={className} />
  ) : (
    <IoMdWarning className={className} />
  );
