export const ENTERPRISE_NAMING_CONVENTION_REGEX =
  /(?<initialTitle>[A-Z0-9-]+)_([A-Z0-9]+(-(?<wip>[A-Z0-9]+))?)_V(?<versionId>[0-9A-Z]+)_(?<type>[A-Z]{2})_[A-Z0-9-]{2,}_((?<videoSpecification>(IMFA|IMFD|ProHQ|ProXQ|PXYC|PXYD|Prelim|SCRN|SCRN-GSS|Scan|Scan-NCC|Scan-DRS|Scan-ACES|ISM|EPS|PNG))|(?<audioSpecification>((WAV|ADMBWF|DAMF|RMU|EAC3|PT|SS|MIX|TMPL-LAN|TMPL-PMR)[A-Z-]*))|(?<textSpecification>(SCC|CAP|IMSC|SRT|STL)))(_([(SD|HD|UHD|2K|4K|SDR|HDR|HDRC|EDR|Log|Lin|TH|NF|R128|UN)-]+))?(_((23|24|25|29|30|50|59|60)))?(_(?<videoLanguage>[A-Z]{2}[0-9]|[A-Z]{3})-([0-9]{3}(-FA)?|ALL))?(_(?<audioLanguage>[A-Z]{2}[0-9]|[A-Z]{3})(-[A-Z]{2}[0-9]|[A-Z]{3})*(-(ALL|MO|DMO|20|50|51|5120|70|71|91|120|12CH|24CH|Atmos|BED|OBJ(-[0-9]{2}){1,})))?(_(?<textLanguage>[A-Z]{2}[0-9]|[A-Z]{3})-(?<timedTextType>(CC|SDH|FULL|FRCD|BURN)))?(_[A-Z0-9]+)?(\.([LCR][str]{0,2})|LFE)?\.(avi|mpeg|mxf|ogv|webm|ts|3gp|3g2|mp4|mov|ttml|vtt|wav)/i;
export interface NameGroups {
  initialTitle: string;
  wip: string | undefined;
  type: string;
  versionId: string;
  videoSpecification: string | undefined;
  videoLanguage: string | undefined;
  audioSpecification: string | undefined;
  audioLanguage: string | undefined;
  textLanguage: string | undefined;
  textSpecification: string | undefined;
  timedTextType: string | undefined;
}

export enum FileType {
  DUBCARD = "Dubcard",
  SUBTITLE = "Subtitle",
  FORCED = "Forced",
  AUDIO = "Audio",
  VIDEO = "Video",
  UNKNOWN = "Not Defined",
}

export interface FileNameParserInterface {
  getFileType(): FileType;
  getLanguageCode(): string | undefined;
  getVersion(): number | undefined;
  getFileDetails(): NameGroups | undefined;
  validateVideoFileName(): boolean;
  validateComponentFileName(): boolean;
}
