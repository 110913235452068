import React, { useEffect } from "react";
import { Table } from "../../components/table";
import { DropZoneFileDTO } from "../../components/drop-zone";
import { ColumnDefinitionType } from "../../components/table/table.types";
import { DeletePopover } from "../new-package/delete-popover";
import { useArray } from "../../hooks";
import { TableItemDTO } from "../../utils/validateLanguageFiles";
import { FileNameParser } from "../../utils/FileNameParser";
import { languageNames } from "../../utils";

export type Actions = {
  delete?: (item: DropZoneFileTableItem) => void;
  language?: (item: DropZoneFileTableItem) => void;
};

export type DropZoneFileTableItem = TableItemDTO &
  Actions & {
    component_type?: string;
    component_language?: string;
  };

type OverrideValue = {
  header: string | JSX.Element;
};

export type OverrideColumns = {
  [key: string]: OverrideValue;
};

export type FileTableProps = {
  files: DropZoneFileDTO[];
  className: string;
  displayTrashIcon?: boolean;
  onDelete?: (item: DropZoneFileTableItem) => void;
  // eslint-disable-next-line max-len
  columns?: ColumnDefinitionType<
    DropZoneFileTableItem,
    keyof DropZoneFileTableItem
  >[];
  hide?: string[];
  override?: OverrideColumns;
};

export const FileTable = (props: FileTableProps): JSX.Element => {
  const {
    files,
    onDelete,
    columns,
    hide,
    className,
    override,
    displayTrashIcon,
  } = props;
  // eslint-disable-next-line max-len
  const tableColumns = useArray<
    ColumnDefinitionType<DropZoneFileTableItem, keyof DropZoneFileTableItem>
  >([]);

  useEffect(() => {
    const getFileType = (item: DropZoneFileTableItem) => {
      const componentType = item.component_type;
      if (componentType) {
        return (
          componentType.charAt(0).toUpperCase() +
          componentType.slice(1).toLowerCase()
        );
      }
      const parser = new FileNameParser(item.name);
      return parser.getFileType();
    };
    const getFileLanguage = (item: DropZoneFileTableItem) => {
      const componentLanguage = item.component_language;
      if (componentLanguage && languageNames[componentLanguage]) {
        return languageNames[componentLanguage];
      }
      const parser = new FileNameParser(item.name);
      return parser.mapLanguageCodeToLanguageName();
    };
    const baseColumns: ColumnDefinitionType<
      DropZoneFileTableItem,
      keyof DropZoneFileTableItem
    >[] = [
      {
        key: "name",
        header: "File Name",
      },
      {
        key: "type",
        header: "File Type",
        isAction: true,
        actionMapper: (item: DropZoneFileTableItem) => getFileType(item),
      },
      {
        key: "language",
        header: "Language",
        isAction: true,
        /** Language from File name, need dictionary of languages to display
         * full language name */
        actionMapper: (item: DropZoneFileTableItem) => getFileLanguage(item),
      },
      ...(columns || []),
    ];
    if (override) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < baseColumns.length - 1; i++) {
        if (override[baseColumns[i].key]) {
          baseColumns[i] = {
            ...baseColumns[i],
            ...override[baseColumns[i].key],
          };
        }
      }
    }
    if (onDelete) {
      baseColumns.push({
        key: "delete",
        header: " ",
        isAction: displayTrashIcon,
        actionMapper: (item: DropZoneFileTableItem) => (
          <DeletePopover onDeleteHandle={onDelete} item={item} />
        ),
      });
    }
    if (hide) {
      // eslint-disable-next-line no-plusplus
      for (let i = baseColumns.length - 1; i >= 0; i--) {
        if (hide.includes(baseColumns[i].key)) {
          baseColumns.splice(i, 1);
        }
      }
    }
    tableColumns.set(baseColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, hide, override]);

  return (
    <Table
      className={className}
      columns={tableColumns.array}
      items={files as DropZoneFileTableItem[]}
    />
  );
};

FileTable.defaultProps = {
  columns: [],
  hide: [],
  override: undefined,
  onDelete: () => null,
};
