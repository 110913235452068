import React, { useEffect, useRef } from "react";
import { Toast, ToastProps } from "./toast";
import { useArray } from "../../hooks";
import "./toaster.scoped.scss";

/**
 * Create a local context for this component so it can be used by child elements
 * to add new toasts.
 */
export const ToastContext = React.createContext<unknown>(null);

/**
 * ToasterProps defines the available properties that user can pass to the
 * component.
 */
export type ToasterProps = {
  /** All the elements inside the toaster area. */
  children: JSX.Element | HTMLElement | Node;
  /** Event triggered every time the toasts array is updated. */
  onToastQueueUpdated?: (toasts: ToastProps[]) => void;
};

export type ToasterActions = {
  push: (toastProps: ToastProps) => void;
  clear: () => void;
  remove: (key: string) => void;
};

export type ToastContextDef = {
  actions: ToasterActions;
  toasts: unknown;
};

export const Toaster = (props: ToasterProps): JSX.Element => {
  const { children, onToastQueueUpdated } = props;

  /** Array handler for the toasts. */
  const arrayHook = useArray<ToastProps>([]);
  const { array: toasts } = arrayHook;

  /** Effect used to trigger the onToastQueueUpdated event. */
  useEffect(() => {
    if (onToastQueueUpdated) {
      onToastQueueUpdated(toasts);
    }
  }, [toasts, onToastQueueUpdated]);

  /**
   * Find a specific toast by its key.
   * @param {string} key - Toast key
   * @returns {number} - Toast index if found otherwise -1.
   */
  const getToastIndexByKey = (key: string): number =>
    toasts.findIndex(
      (toastProps: ToastProps): boolean => toastProps.toastKey === key
    );

  /**
   * This function will manage the removal of every toast that change to a
   * hidden state.
   * @param {string} key - Toast key to hide.
   */
  const onHideHandler = (key: string | undefined): void => {
    if (key) {
      arrayHook.remove(getToastIndexByKey(key));
    }
  };

  /** Context actions available to child elements. */
  const actions = useRef<ToasterActions>({
    /**
     * Function to add a new toast to the queue.
     * @param {ToastProps} toastProps
     */
    push: (toastProps: ToastProps): void => {
      arrayHook.push({
        ...toastProps,
        toastKey: `toast-${Math.random()}`,
      });
    },
    /**
     * Clear all the toast queue.
     */
    clear: (): void => {
      arrayHook.clear();
    },
    /**
     * Remove a specific toast by its key.
     * @param {string} key - Toast key to hide.
     */
    remove: (key: string): void => {
      arrayHook.remove(getToastIndexByKey(key));
    },
  });

  const value: ToastContextDef = { toasts, actions: actions.current };

  return (
    <ToastContext.Provider value={value}>
      <div className="toaster">
        {toasts.map(
          (toastProps): JSX.Element => (
            <Toast
              key={toastProps.toastKey}
              toastKey={toastProps.toastKey}
              duration={toastProps.duration}
              message={toastProps.message}
              closeable={toastProps.closeable}
              type={toastProps.type}
              onHide={onHideHandler}
            />
          )
        )}
      </div>
      {children as any}
    </ToastContext.Provider>
  );
};

Toaster.defaultProps = {
  onToastQueueUpdated: () => null,
};
