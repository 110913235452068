/* eslint-disable react/require-default-props */
import React from "react";
import "./tooltip.scoped.scss";

/**
 * This type describes the properties related with a menu component and
 * the expected format for each of them.
 */
export type TooltipProps = {
  text: string;
  children: JSX.Element | HTMLElement | Node | string;
  position?: string;
};

/**
 * This functional component contains the tooltip
 * component presented on the widget
 * @param {TooltipProps} props - Props values injected to the component.
 * @returns {JSX.Element}
 */
export const Tooltip = (props: TooltipProps): JSX.Element => {
  const { text, children, position } = props;
  return (
    <div className="uw-tooltip">
      <div className="tooltip-wrapper">
        <div className={`${text ? "tooltip-trigger" : ""}`}>
          {children as any}
        </div>
        {text.length > 0 && (
          <span className={`tooltip-text ${position || "on-top"}`}>{text}</span>
        )}
      </div>
    </div>
  );
};
