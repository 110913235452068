import { SearchPackageResult } from "../services/upload-api/upload-api.types";
import { SearchPackageResultDataStrings } from "./stringifyMetadata";

/*
 * Templates are composed by properties and they will be joined by the delimiter
 * set on the stringifyMetadata function. When a template component is an array
 * the order of the elements is interpreted as [prefix, <property>, suffix?]
 */

export type ComposedString = {
  prefix?: string;
  component: string;
  suffix?: string;
  modifier?: (v: string) => string | undefined;
};

export const modifyPackageSuggestionTemplate = (
  item: SearchPackageResult
): Array<string | ComposedString> =>
  item?.metadata?.season
    ? [
        SearchPackageResultDataStrings.packageId,
        SearchPackageResultDataStrings.seasonName,
        {
          prefix: "Episode ",
          component: SearchPackageResultDataStrings.episodeNumber,
        },
        SearchPackageResultDataStrings.episodeName,
        SearchPackageResultDataStrings.videoFileName,
        {
          prefix: "Created at: ",
          component: SearchPackageResultDataStrings.createdAt,
        },
      ]
    : [
        SearchPackageResultDataStrings.packageId,
        SearchPackageResultDataStrings.showName,
        SearchPackageResultDataStrings.videoFileName,
        {
          prefix: "Created at: ",
          component: SearchPackageResultDataStrings.createdAt,
        },
      ];

export const statusGridTitleName = (
  item: SearchPackageResult
): Array<string | ComposedString> =>
  item?.metadata?.season
    ? [
        SearchPackageResultDataStrings.showName,
        {
          component: SearchPackageResultDataStrings.seasonNumber,
          modifier: (s) =>
            (s && `S${s.toLocaleLowerCase().replace(/[^0-9]/g, "")}`) ||
            undefined,
        },
        {
          component: SearchPackageResultDataStrings.episodeNumber,
          modifier: (e) =>
            (e && `E${e.toLocaleLowerCase().replace(/[^0-9]/g, "")}`) ||
            undefined,
        },
        SearchPackageResultDataStrings.episodeName,
        SearchPackageResultDataStrings.cpmId,
      ]
    : [
        SearchPackageResultDataStrings.showName,
        SearchPackageResultDataStrings.cpmId,
      ];
