/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { Step } from "./step";
import "./steps.scoped.scss";

export type StepsProps = {
  /** Children property contains all the Step components to render. */
  children: JSX.Element[];
  /** Define the current step to show. */
  currentStep: number;
};

/**
 * This component works as a container for Step components so they can be
 * rendered appropriately and handle the state of each of them according to its
 * defined properties.
 * @param {StepsProps} props - Props values injected to the component.
 * @returns {JSX.Element}
 */
export const Steps = (props: StepsProps): JSX.Element => {
  const { children, currentStep } = props;

  /**
   * This state allows the component to not hide or change the rendering state
   * of the steps that have been already activated.
   * */
  const [maxStepReached, setMaxStepReached] = useState(currentStep || 0);

  /** Ensure that all components inside this component are a Step component. */
  const steps = children.filter(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    (element: JSX.Element) => element.type.name === Step.name
  );

  /** State keys to avoid step re rendering. */
  const [stepKeys] = useState(steps.map(() => Math.random()));

  /** The actual steps to be rendered inside this component content */
  const [contentSteps, setContentSteps] = useState(
    steps.map((step: JSX.Element, stepIndex: number): JSX.Element => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const newProps = {
        ...step.props,
        index: stepIndex,
      };
      return <Step key={`step-${stepKeys[stepIndex]}`} {...newProps} />;
    })
  );

  useEffect(() => {
    /**
     * Validate that the current step is on the limits of the provided
     * content.
     * */
    const internalCurrentStep = ((): number => {
      if (currentStep !== undefined && currentStep < 0) {
        return 0;
      }
      if (currentStep !== undefined && currentStep > steps.length) {
        return steps.length;
      }
      return currentStep !== undefined ? currentStep : 0;
    })();
    /** Set the max step reached so previous ones wont change its state. */
    if (
      internalCurrentStep !== undefined &&
      internalCurrentStep > maxStepReached
    ) {
      setMaxStepReached(internalCurrentStep);
    }
    setContentSteps(
      steps.map((step: JSX.Element, stepIndex: number): JSX.Element => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const newProps = {
          ...step.props,
          key: stepIndex,
          index: stepIndex,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          behavior:
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            stepIndex <= internalCurrentStep ? "shown" : step.props.behavior,
          showLine: stepIndex < internalCurrentStep,
        };
        // eslint-disable-next-line react/jsx-key
        return <Step {...newProps} />;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, children]);

  return <div className="steps">{contentSteps || ""}</div>;
};

Steps.defaultProps = {};
