import React, { useContext } from "react";
import { DigitalScreenersMediaManagerContext } from "../../../app/digital-screeners-media-manager/digital-screeners-media-manager";
import { Field, Select, Option } from "../../../components/form";

const drmBlockedPortals = (
  process.env.REACT_APP_DRM_BLOCKED_PORTALS || ""
).split(",");

export type SecurityFieldProps = {
  onSecuritySelected: (name: string, value: string) => void;
  selectedSecurity: string | undefined;
};

export const SecurityField = (props: SecurityFieldProps) => {
  const uploadWidgetContext = useContext(DigitalScreenersMediaManagerContext);
  const { portal } = uploadWidgetContext as {
    portal: string;
  };
  const { selectedSecurity, onSecuritySelected } = props;
  return (
    <Field label="File Security Instructions*">
      <Select value={selectedSecurity} onOptionChange={onSecuritySelected}>
        {!drmBlockedPortals.includes(portal) && (
          <Option name="DRM" value="DRM" hideOnSelect />
        )}
        <Option
          name="DRM & Watermarking"
          value="DRM & Watermarking"
          hideOnSelect
        />
      </Select>
    </Field>
  );
};
