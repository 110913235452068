/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from "react";
import { Select, Option } from "../select";
import { BasicDateRangePicker } from "./date-filter-range";
import styles from "./date-filter.module.scss";

import {
  DateRangeChangeCallback,
  DateFilterOptionsType,
  DateFilterOptions,
  DateRange,
  DateRangeNull,
} from "./date-filter.types";

interface IDateFilterProps {
  initialValues?: DateRange;
  onChange?: DateRangeChangeCallback;
}

const mapDateOption = (option: string): string => {
  switch (option) {
    case "lastWeek":
      return "Last week";
    case "lastMonth":
      return "Last month";
    case "last90Days":
      return "Last 90 Days";
    case "customRange":
      return "Specific date range";
    case "yearToDate":
      return "Year to date";
    default:
      return "";
  }
};

export const DateFilter = (props: IDateFilterProps): JSX.Element => {
  const { onChange, initialValues } = props;
  const [selectedOption, setSelectedOption] = useState(null);
  const [customRange, setCustomRange] = useState<DateRange>(
    initialValues || DateRangeNull()
  );
  const optionsKeys = Object.keys(DateFilterOptions);
  const rangeInputRef = useRef<HTMLInputElement>(null);
  const didMountRef = useRef(false);

  const triggerRangeChange = (): void => {
    const selectedOpt =
      DateFilterOptions[
        selectedOption as unknown as keyof DateFilterOptionsType
      ];

    let result: DateRange;
    if (typeof selectedOpt === "function") {
      result = selectedOpt();
    } else {
      result = { ...customRange };
    }
    if (result.from) {
      result.from.setHours(0, 0, 0, 0);
    }
    if (result.to) {
      result.to.setHours(23, 59, 59, 99);
    }

    const hasSelectedDateForRange = !(
      selectedOption === "customRange" &&
      result.to === null &&
      result.from === null
    );
    if (onChange && hasSelectedDateForRange) {
      onChange(result);
    }
  };

  useEffect(() => {
    if (didMountRef.current) {
      if (selectedOption || selectedOption === null || customRange.from) {
        triggerRangeChange();
      }
    }
    didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, customRange]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOptionChange = (option: string, value: any): void => {
    if (selectedOption === value || value === undefined) {
      setSelectedOption(null);
      setCustomRange({
        from: null,
        to: null,
      });
    }

    if (value === "clear") {
      setSelectedOption(null);
      setCustomRange({
        from: null,
        to: null,
      });
      return;
    }
    if (value !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setSelectedOption(selectedOption !== value ? value : null);
    }
  };

  const handleCustomRangeChange = (newRange: DateRange): void => {
    if (selectedOption === "customRange") {
      setCustomRange(newRange);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFocusClickHandler = (e: any): void => {
    if (selectedOption === "customRange") {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      e.stopPropagation();
    }
  };

  return (
    <div className="uw-row uw-col">
      <div className={`uw-col as-row ${styles["date-filter"]}`}>
        <Select
          onOptionChange={handleOptionChange}
          placeholder="Show by Date"
          className={styles["date-filter__select"]}
          value="Last week"
        >
          {Object.keys(DateFilterOptions).map((option) => (
            <Option
              name={String(mapDateOption(option) || "")}
              value={option}
              key={option}
              type={option === "customRange" ? "custom" : "checkbox"}
              hideOnSelect={option !== "customRange"}
              customOption={
                option === optionsKeys[optionsKeys.length - 1] ? (
                  <BasicDateRangePicker
                    ref={rangeInputRef}
                    name={String(mapDateOption(option) || "")}
                    value={customRange}
                    onChange={handleCustomRangeChange}
                    onClick={onFocusClickHandler}
                  />
                ) : undefined
              }
            />
          ))}
          <Option className="centered" value="clear" name="Clear" reset />
        </Select>
      </div>
    </div>
  );
};
