/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { Component, useContext } from "react";
import TableContainer from "@mui/material/TableContainer";
import IconButton from "@mui/material/IconButton";
import { InputAdornment } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { StatusRow } from "./status-row";
import { UploadAPIService } from "../../services/upload-api/upload-api.service";
import {
  SearchPackageResponse,
  SearchResponse,
  SortOrder,
  SearchPackageRequestQuery,
} from "../../services/upload-api/upload-api.types";
import {
  DateRange,
  Last90Days,
} from "../../components/form/date-filter/date-filter.types";

import { DateFilter } from "../../components/form/date-filter";
import "./status-widget.scss";
import { DSPackage } from "@screeners/upload-datamodel";
import { useParams } from "react-router-dom";
import { DigitalScreenersMediaManagerContext } from "../../app/digital-screeners-media-manager/digital-screeners-media-manager";

interface StatusGridState {
  dateRange: DateRange;
  currentPortal: string;
  packages: DSPackage[] | undefined;
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  searchBuffer: NodeJS.Timeout | number;
  searchKey: string;
  tableHeight: number;
  tableWidth: number;
  tableHeaders: Record<string, TableHeader>;
  loading: boolean;
}

type RouteParams = {
  packageId?: string;
  show?: string;
  userContext?: string;
};

export type StatusWidgetProps = {
  token: string;
  portal: string;
  context: string;
};

type StatusWidgetClassProps = RouteParams & StatusWidgetProps;

type TableHeader = {
  label: string;
  width: string;
};

const initTableHeaders: Record<string, TableHeader> = {
  title: {
    label: "Series / Feature Title",
    width: "50%",
  },
  seasonNumber: {
    label: "Season Number",
    width: "80px",
  },
  episodeNumber: {
    label: "Episode Number",
    width: "80px",
  },
  episodeTitle: {
    label: "Episode Title",
    width: "50%",
  },
  productType: {
    label: "Product Type",
    width: "125px",
  },
  cpmId: {
    label: "CPM ID",
    width: "88px",
  },
  files: {
    label: "Files",
    width: "70px",
  },
  lastUpdate: {
    label: "Last Update",
    width: "110px",
  },
  packageId: {
    label: "Package ID",
    width: "115px",
  },
  actionColumn: {
    label: "",
    width: "55px",
  },
};

export function StatusWidget(props: StatusWidgetProps) {
  const uploadWidgetContext = useContext(DigitalScreenersMediaManagerContext);
  const { context } = uploadWidgetContext as {
    context: string;
  };
  const { packageId, show } = useParams();
  return (
    <StatusWidgetClass
      packageId={packageId}
      show={show}
      userContext={context}
      {...props}
    />
  );
}

class StatusWidgetClass extends Component<
  StatusWidgetClassProps,
  StatusGridState
> {
  service: UploadAPIService;
  tableContainer = React.createRef<HTMLDivElement>();
  resizeObserver: ResizeObserver | undefined;

  constructor(props: StatusWidgetClassProps) {
    super(props);
    this.service = UploadAPIService.getInstance(
      props.token,
      props.portal,
      props.context
    );
    const dateRange = Last90Days();

    const initState = {
      currentPortal: props.portal,
      dateRange,
      packages: undefined,
      pageNumber: 1,
      pageSize: 25,
      searchBuffer: 0,
      searchKey: props.show && props.packageId ? props.packageId : "",
      totalCount: 0,
      tableHeight: 0,
      tableWidth: 0,
      loading: true,
      tableHeaders: initTableHeaders,
    };

    initState.tableHeaders.lastUpdate.label =
      props.userContext === "DMDC" ? "Last Update (UTC)" : "Last Update";
    this.state = initState;

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.handleSearchButtonClick = this.handleSearchButtonClick.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }
  componentWillUnmount() {
    this.setState(this.state);
    this.resizeObserver?.disconnect();
  }

  componentDidMount(): void {
    this.updateData();
    this.resizeObserver = new ResizeObserver(this.handleResize);
    this.resizeObserver.observe(this.tableContainer.current as Element);
  }

  handleResize = (): void => {
    const newTableHeight =
      window.innerHeight -
      10 -
      (this.tableContainer?.current?.getBoundingClientRect().y || 0);
    const newTableWidth =
      window.innerWidth -
      10 -
      (this.tableContainer?.current?.getBoundingClientRect().x || 0);
    this.updateColumnWidths(newTableWidth);
    this.setState(
      (prev): StatusGridState => ({
        ...prev,
        tableHeight: newTableHeight,
        tableWidth: newTableWidth,
      })
    );
  };

  updateColumnWidths = (tableWidth: number) => {
    if (tableWidth > 1760) {
      this.setState(
        (prev): StatusGridState => ({
          ...prev,
          tableHeaders: {
            ...initTableHeaders,
            lastUpdate: {
              ...initTableHeaders.lastUpdate,
              width: "180px",
            },
            packageId: {
              ...initTableHeaders.packageId,
              width: "330px",
            },
          },
        })
      );
    } else {
      this.setState(
        (prev): StatusGridState => ({
          ...prev,
          tableHeaders: initTableHeaders,
        })
      );
    }
  };

  handleChangePage(
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ): void {
    this.setState(
      (prev): StatusGridState => ({
        ...prev,
        ...{
          pageNumber: page + 1,
        },
      }),
      this.updateData
    );
  }

  handleChangeRowsPerPage(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void {
    const { pageNumber, pageSize } = this.state;
    const currentIdx = (pageNumber - 1) * pageSize + 1;
    const newPageSize = parseInt(event.target.value, 10);
    this.setState(
      (prev): StatusGridState => ({
        ...prev,
        ...{
          pageSize: newPageSize,
          pageNumber: Math.ceil(currentIdx / newPageSize),
        },
      }),
      this.updateData
    );
  }

  handleSearchButtonClick(/* event:any */): any {
    const { searchBuffer } = this.state;
    clearTimeout(searchBuffer as number);
    this.updateData();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  handleSearchChange(event: any): any {
    const { searchBuffer } = this.state;
    clearTimeout(searchBuffer as number);
    this.setState(
      (prev): StatusGridState => ({
        ...prev,
        ...{
          pageNumber: 1,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          searchKey: event.target.value as string,
          searchBuffer: setTimeout((): any => {
            this.updateData();
          }, 1000),
        },
      })
    );
  }

  handleDateRangeChange(dateRange: DateRange): void {
    this.setState(
      (prev): StatusGridState => ({
        ...prev,
        ...{
          dateRange,
        },
      }),
      this.updateData
    );
  }

  updateData(): void {
    const { dateRange, searchKey, pageNumber, pageSize, currentPortal } =
      this.state;
    const filter = [];
    if (searchKey !== "") {
      filter.push({
        name: searchKey,
      });
    }
    this.setState({
      loading: true,
      pageNumber: 1,
      totalCount: 0,
    });

    const query = {
      search: searchKey,
      portal_id: currentPortal,
      pageNumber,
      pageSize,
      sortOrder: "desc" as SortOrder,
      context: "status-page",
    } as SearchPackageRequestQuery;
    if (dateRange.from) {
      query.date_from = dateRange.from?.getTime();
    }
    if (dateRange.to) {
      query.date_to = dateRange.to?.getTime();
    }
    this.service
      .searchPackage({
        query,
      })
      .then((response: SearchPackageResponse) => {
        const data: SearchResponse | undefined = response.data?.response;
        if (data === undefined) {
          // eslint-disable-next-line no-console
          console.error(response);
        } else {
          this.setState(
            (prev): StatusGridState => ({
              ...prev,
              packages: data.results || [],
              pageNumber: data.pagination?.page_number || 1,
              pageSize: data.pagination?.page_size || 25,
              totalCount: data.pagination?.total_count || 0,
            })
          );
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render(): any {
    const {
      packages,
      dateRange,
      pageNumber,
      pageSize,
      searchKey,
      totalCount,
      tableHeight,
      tableHeaders,
      loading,
    } = this.state;

    return (
      <Paper ref={this.tableContainer}>
        <TableContainer
          component={Paper}
          className="status-table__container"
          sx={{ height: `${String(tableHeight)}px` }}
        >
          <Grid
            className="status-table__action-bar"
            container
            spacing={2}
            alignItems="center"
          >
            <Grid item xs={5}>
              <Typography variant="h5" component="h1">
                Package ID list
                <IconButton
                  className="status-table__action-bar__refresh-btn"
                  aria-label="expand row"
                  size="small"
                  disabled={loading}
                  onClick={this.handleSearchButtonClick}
                >
                  {<RefreshIcon />}
                </IconButton>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <DateFilter
                onChange={this.handleDateRangeChange}
                initialValues={dateRange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                placeholder="Search by CPM ID, Package ID, File Name, Uploader"
                className="status-table__action-bar__search-input"
                fullWidth
                defaultValue={searchKey}
                onChange={this.handleSearchChange}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={this.handleSearchButtonClick}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead>
              <TableRow>
                {Object.entries(tableHeaders).map(([id, { label, width }]) => (
                  <TableCell key={id} style={{ width }}>
                    <strong>{label}</strong>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={10}
                    className="status-table__progress-container"
                  >
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {packages?.map((packageRow: DSPackage, rowIndex: number) => (
                  <StatusRow
                    key={packageRow.package_id}
                    row={packageRow}
                    rowIndex={rowIndex}
                  />
                ))}
              </TableBody>
            )}
            <TableFooter
              sx={{ position: "sticky", bottom: "0", background: "white" }}
            >
              <TableRow>
                <TablePagination
                  labelRowsPerPage="Rows per page:"
                  count={totalCount}
                  page={pageNumber - 1}
                  onPageChange={this.handleChangePage}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
}

export default StatusWidget;
