import { DropZoneFileDTO } from "../components/drop-zone";
import { FileType } from "../const/file-types";
import { FileNameParser } from "./FileNameParser";

export type FileCounters = {
  audio: number;
  subtitle: number;
  forcedSubtitle: number;
  dubcard: number;
};

export function calcFilesByType(fileArray: DropZoneFileDTO[]): FileCounters {
  const fileCounters: FileCounters = {
    audio: 0,
    subtitle: 0,
    forcedSubtitle: 0,
    dubcard: 0,
  };
  if (fileArray.length > 0) {
    fileArray.forEach((file: DropZoneFileDTO): void => {
      const parser = new FileNameParser(file.name);
      if (parser.getFileType() === FileType.AUDIO) {
        fileCounters.audio += 1;
      }
      if (parser.getFileType() === FileType.DUBCARD) {
        fileCounters.dubcard += 1;
      }
      if (parser.getFileType() === FileType.SUBTITLE) {
        fileCounters.subtitle += 1;
      }
      if (parser.getFileType() === FileType.FORCED) {
        fileCounters.forcedSubtitle += 1;
      }
    });
  }
  return fileCounters;
}
