import React, { MouseEventHandler, ReactElement } from "react";
import "./sort-label.scoped.scss";
import { AiOutlineArrowUp } from "react-icons/ai";
import { SortOrder } from "../../services/upload-api/upload-api.types";

export type InfoType = "generic" | "error" | "warning" | "success";

export type SortLabelProps = {
  text?: string;
  className: string;
  prefix?: HTMLDivElement;
  suffix?: HTMLDivElement;
  children: Array<HTMLDivElement | ReactElement>;
  sortActive: boolean;
  sortDirection: SortOrder;
  onSort: MouseEventHandler<HTMLButtonElement>;
};

export const SortLabel = (props: SortLabelProps): JSX.Element => {
  const {
    text,
    className,
    prefix,
    suffix,
    children,
    onSort,
    sortActive,
    sortDirection,
  } = props;

  return (
    <div className={`sort-label ${className}`}>
      {prefix}
      {text ?? (children as any) ?? null}
      {suffix}
      <button
        className="sort-label__btn"
        type="button"
        aria-label="expand row"
        onClick={onSort}
      >
        <AiOutlineArrowUp
          className={`${sortActive ? "active" : "inactive"} ${
            sortDirection === "asc" ? "sort-arrow-up" : "sort-arrow-down"
          }`}
        />
      </button>
    </div>
  );
};

SortLabel.defaultProps = {
  text: undefined,
  prefix: undefined,
  suffix: undefined,
};
