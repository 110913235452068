import { DropZoneFileDTO } from "../components/drop-zone";
import { DSFile } from "@screeners/upload-datamodel";
import { FileNameParser } from "./FileNameParser";
import { FileType } from "../const/file-types";
/**
 * This enum describes the different status that an item can have.
 */
export enum ItemStatus {
  success = "SUCCESS",
  warning = "WARNING",
  error = "ERROR",
}

/**
 * This type describes the properties related with the status
 * of an item.
 */
export type TableItemStatusDTO = {
  name: ItemStatus;
  message: string;
};

/**
 * This type describes the properties related with a menu component and
 * the expected format for each of them.
 */
export interface TableItemDTO extends DropZoneFileDTO {
  language?: string;
  status?: TableItemStatusDTO;
}

/**
 * This function assign the status to every item based
 * on naming convention and duplicated file types per language
 * @param {TableItemDTO[]} files
 * @returns {TableItemDTO[]}
 */
export function updateLanguageFilesStatus(
  files: TableItemDTO[],
  // ToDo remove eslint disable
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  existingFiles?: DSFile[]
): TableItemDTO[] {
  // eslint-disable-next-line no-debugger
  const existingLanguageComponents = new Map<string, DSFile>();
  existingFiles?.forEach((ef) => {
    const parser = new FileNameParser(ef?.file_name);
    existingLanguageComponents.set(parser.getTypeAndLanguage(), ef);
  });
  const typeAndLanguageMap = new Map<string, number>();
  const mFiles = files.map((file) => {
    const mFile = file;
    const parser = new FileNameParser(file.name);
    if (
      parser.validateComponentFileName() &&
      parser.getFileType() !== FileType.VIDEO
    ) {
      /** Gets the portion that contains the value TYPE_LANGUAGE */
      const typeAndLanguage = parser.getTypeAndLanguage();

      /** Gets the portion that contains the value TYPE_LANGUAGE */
      if (!typeAndLanguageMap.has(typeAndLanguage)) {
        typeAndLanguageMap.set(typeAndLanguage, 0);
      }
      typeAndLanguageMap.set(
        typeAndLanguage,
        (typeAndLanguageMap.get(typeAndLanguage) as number) + 1
      );

      if (
        typeAndLanguageMap.has(typeAndLanguage) &&
        (typeAndLanguageMap.get(typeAndLanguage) as number) > 1
      ) {
        mFile.status = {
          name: ItemStatus.error,
          message:
            "You are adding more than one component for the same type" +
            " and language.",
        };
      } else if (!parser.isValidLanguageCode()) {
        mFile.status = {
          name: ItemStatus.error,
          message: "The file has a non supported language.",
        };
      } else if (existingLanguageComponents.has(typeAndLanguage)) {
        mFile.status = {
          name: ItemStatus.warning,
          message:
            "The package contains a component for this type and language" +
            " already.",
        };
        mFile.file_id =
          existingLanguageComponents.get(typeAndLanguage)?.file_id;
      } else {
        mFile.status = {
          name: ItemStatus.success,
          message: "",
        };
      }
    } else if (
      parser.validateVideoFileName() &&
      parser.getFileType() === FileType.VIDEO
    ) {
      mFile.status = {
        name: window.location?.hash?.includes("new-package")
          ? ItemStatus.error
          : ItemStatus.warning,
        message: "The package already contains the main file.",
      };
    } else {
      mFile.status = {
        name: ItemStatus.error,
        message: "The are some naming errors detected.",
      };
    }

    return mFile;
  });

  return mFiles;
}
