import React, {
  useState,
  useEffect,
  forwardRef,
  MouseEventHandler,
} from "react";
import { AiFillWarning } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { DateRange } from "./date-filter.types";
import { Button } from "..";
import { useSelectContext } from "../select/selectContext";
import { DateRangeInput } from "./date-filter-range-input";
import styles from "./date-filter-range.module.scss";

type DateRangePickerProps = {
  value: DateRange;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: any) => void;
  // eslint-disable-next-line react/require-default-props
  onClick?: (event: MouseEventHandler) => void;
};

const formatDateString = (date: Date | null | undefined): string => {
  let result = "";
  if (date && !Number.isNaN(date.getTime())) {
    result = [
      date.getFullYear(),
      (date.getMonth() + 1).toString().padStart(2, "0"),
      date.getDate().toString().padStart(2, "0"),
    ].join("/");
  }
  return result;
};

/**
 * Custom date range filter component
 * @param {DateRangePickerProps}
 * @returns {JSX.Element}
 */
// eslint-disable-next-line react/display-name
export const BasicDateRangePicker = forwardRef<
  HTMLInputElement,
  DateRangePickerProps
>((props, ref) => {
  const { onChange, value, name, onClick } = props;
  const [valueFrom, setValueFrom] = useState(formatDateString(value?.from));
  const [valueTo, setValueTo] = useState(formatDateString(value?.to));
  const [showError, setShowError] = useState(false);
  const [isValidRange, setIsValidRange] = useState(false);
  const { selectedOption } = useSelectContext();

  const validateDates = (dateFrom: string, dateTo: string): void => {
    setShowError(false);
    if (dateFrom === "" || dateTo === "") {
      setIsValidRange(false);
    } else if (
      dateFrom !== "" &&
      dateTo !== "" &&
      new Date(dateTo) < new Date(dateFrom)
    ) {
      setShowError(true);
      setIsValidRange(false);
    } else {
      setIsValidRange(true);
    }
  };

  const filterByRange = (e: { stopPropagation?: () => void }): void => {
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    onChange({ from: new Date(valueFrom), to: new Date(valueTo) });
  };

  useEffect(() => {
    const dateFrom = formatDateString(value?.from);
    const dateTo = formatDateString(value?.to);
    setValueFrom(dateFrom);
    setValueTo(dateTo);
  }, [value]);

  useEffect(() => {
    validateDates(valueFrom, valueTo);
  }, [valueFrom, valueTo]);

  return (
    <div className="select-option-radio">
      <div>
        <input
          name="select-radio"
          type="radio"
          checked={selectedOption === name}
          onChange={() => undefined}
        />
        <span>{name}</span>
      </div>
      <div className={styles["date-range-container"]}>
        <div className={styles["date-range-container-inputs"]}>
          <DateRangeInput
            selected={selectedOption === name}
            error={showError}
            ref={ref}
            label="FROM"
            onChange={setValueFrom}
            onClick={onClick}
            value={valueFrom}
          />
          <DateRangeInput
            selected={selectedOption === name}
            error={showError}
            label="TO"
            onChange={setValueTo}
            onClick={onClick}
            value={valueTo}
          />
          <Button
            className={styles["date-range-container-button"]}
            onClick={filterByRange}
            disabled={!isValidRange}
            value={<BsArrowRight />}
          />
        </div>
        <div className="date-range-container-error">
          {showError && (
            <span className={styles["error-message"]}>
              <AiFillWarning />
              <span className={styles["error-message__title"]}>
                The end date must be after the start date.
              </span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
});
