import { useCallback, useEffect, useRef } from "react";

type fn = () => void;

export const useSetTimeout = (callback: () => void, delay: number): fn[] => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const clear = useCallback(
    () => timeoutRef.current && clearTimeout(timeoutRef.current),
    []
  );

  useEffect(() => {
    set();
    return clear;
  }, [delay, set, clear]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  return [reset, clear];
};
