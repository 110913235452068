/**
 * This enum list all the available actions on the app, and the
 * use of this enum is intended for autocompletion and to ensure
 * that all the actions dispatched on the app are documented.
 */
enum UPLOAD_WIDGET_ACTIONS {
  NO_ACTION = "",
}

export default UPLOAD_WIDGET_ACTIONS;
