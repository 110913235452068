// import { handleStatusChange, handleUploadTransferStatus } from './handlers';
import { BehaviorSubject } from "rxjs";
import Connect from "./classes/Connect";
import ConnectInstaller from "./classes/ConnectInstaller";
import {
  Transfer,
  TransferStatus,
  ASPERA_CDN_URL,
  EXTENSION_TIMEOUT,
  TRANSFER_DIRECTION,
  AsperaServiceInterface,
  ConnectStatus,
  AsperaServiceOptions,
  TransfersObject,
  AppId,
  AsperaServiceEvents,
} from ".";

/**
 * @class AsperaHandler
 * @description This class works as an abstraction for the common mechanisms
 * used with the Aspera client sdk
 */
export class AsperaService implements AsperaServiceInterface {
  public ConnectInstance: Connect | undefined = undefined;

  public ConnectInstallerInstance: ConnectInstaller | undefined = undefined;

  public events: AsperaServiceEvents = {
    ready: new BehaviorSubject<boolean>(false),
  };

  public statusEventEmitter = new BehaviorSubject(ConnectStatus.INITIALIZING);

  public transferEventEmitter = new BehaviorSubject<TransferStatus>(undefined);

  public appId: AppId | undefined = undefined;

  constructor(options: AsperaServiceOptions) {
    const { AW4 } = window;
    /**
     * Verify if the Aspera library has been loaded already, otherwise load the
     * script and trigger the initialization after it.
     */
    if (!AW4) {
      const script = document.createElement("script");
      script.src = ASPERA_CDN_URL;
      script.async = true;
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        this.init(options);
      });
    } else {
      this.init(options);
    }
  }

  private init(options: AsperaServiceOptions): void {
    const { AW4 } = window;
    if (!AW4) {
      throw "No AW4";
    }
    /**
     * The configuration objects are sealed to avoid changes inside this
     * class.
     */
    const config = Object.freeze(options.config);
    /** Default config for installer only includes sdk version. */
    const installerConfig = Object.freeze(
      options.installerConfig || {
        sdkLocation: config.sdkLocation,
      }
    );

    if (!options.connectInstance) {
      /** Create the Aspera Connect instance. */
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.ConnectInstance = new AW4.Connect(config);
    } else {
      this.ConnectInstance = options.connectInstance;
    }
    if (!options.connectInstallerInstance) {
      /** Create the Aspera ConnectInstaller instance. */
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.ConnectInstallerInstance = new AW4.ConnectInstaller(installerConfig);
    } else {
      this.ConnectInstallerInstance = options.connectInstallerInstance;
    }

    this.setUpEventListeners();
    this.events.ready.next(true);

    /**
     * Verify if the Aspera extension is already installed on the browser so
     * the Aspera session can be initialized to allow the user to star uploading
     * files.
     */
    this.ConnectInstallerInstance?.isExtensionInstalled(EXTENSION_TIMEOUT, {
      success: () => {
        /** The extension is installed so we can init the Aspera session. */
        this.configureSession();
      },
      timedout: () => {
        this.ConnectInstallerInstance?.showExtensionInstall();
        /**
         * If the extension is not installed we will delegate the flow to the
         * Aspera installation handler provided by the user.
         */
        if (options.installationHandler) {
          options.installationHandler();
        }
      },
    });
  }

  /**
   * This function is in charge of create and set the required listeners for all
   * the events that want to be handled.
   */
  private setUpEventListeners(): void {
    const { AW4 } = window;
    if (!AW4) {
      throw "No AW4";
    }

    /**
     * Creates the status event listener function that will handle all the
     * changes on the ConnectInstance status as well as emit the received value
     * to all the event subscribers in case it needs to be used outside this
     * class.
     * @param {string} eventType - The event that is being handled.
     * @param {ConnectStatus} data - The specific status received.
     */
    const statusEventListener = (
      eventType: string,
      data: ConnectStatus
    ): void => {
      /**
       * ToDO: Because the event listener is set providing the specific event,
       * this condition might not be required.
       */
      if (eventType === AW4.Connect.EVENT.STATUS) {
        this.handleStatusChange(data || "");
        this.statusEventEmitter.next(data);
      }
    };

    /**
     * Creates the listener to handle all the events related with the transfer
     * status of a file or set of files, this listener will send this changes
     * to all subscribed listeners.
     * @param {string} eventType - The event that is being handled.
     * @param {ConnectStatus} data - The specific status received.
     */
    const transferEventListener = (
      eventType: string,
      transferObj: TransfersObject
    ): void => {
      /**
       * ToDO: Because the event listener is set providing the specific event,
       * this condition might not be required.
       */
      if (eventType === AW4.Connect.EVENT.TRANSFER) {
        const { transfers } = transferObj;
        // eslint-disable-next-line max-len
        // https://developer.asperasoft.com/api_docs/connect/3.9/web/objects/TransferInfo/index.html
        if (transfers && transfers.length > 0) {
          transfers.forEach((transfer: Transfer) => {
            const {
              transfer_spec: { direction },
            } = transfer;
            if (direction === TRANSFER_DIRECTION.SEND) {
              this.transferEventEmitter.next({
                transfer,
                status: AW4.Connect.TRANSFER_STATUS,
              });
            }
          });
        }
      }
    };

    /** Add event listeners to the connect instance for the specific event. */
    this.ConnectInstance?.addEventListener(
      AW4.Connect.EVENT?.STATUS,
      statusEventListener
    );
    this.ConnectInstance?.addEventListener(
      AW4.Connect.EVENT?.TRANSFER,
      transferEventListener
    );
  }

  /**
   * This function will initialize the ClientInstance session if possible,
   * otherwise it will trigger the corresponding ClientInstallerInstance event.
   */
  private configureSession = (): void => {
    // If an existing appId is set, the session was already started
    // If Aspera needs to be installed the session wont be initialized.
    if (!this.appId) {
      this.appId = this.ConnectInstance?.initSession();
    }
    /**
     * Once the session has ben initialized we check the status and proceed with
     * the corresponding action.
     */
    const status = this.ConnectInstance?.getStatus();
    this.handleStatusChange(status || "");
  };

  /**
   * This function will handle the current ClientInstance status.
   * @param {string} status
   */
  private handleStatusChange = (status: string): void => {
    if (status) {
      switch (status) {
        case ConnectStatus.INITIALIZING:
          // this.ConnectInstallerInstance?.showLaunching();
          break;
        case ConnectStatus.EXTENSION_INSTALL:
          this.ConnectInstallerInstance?.showExtensionInstall();
          break;
        case ConnectStatus.FAILED:
          this.ConnectInstallerInstance?.showDownload();
          break;
        case ConnectStatus.OUTDATED:
          this.ConnectInstallerInstance?.showUpdate();
          break;
        case ConnectStatus.RUNNING:
          this.ConnectInstallerInstance?.connected();
          break;
        default:
          break;
      }
    }
  };
}
