export const mapStatusColor = (status: string): string => {
  switch (status) {
    case "Uploading":
    case "Unknown State":
      return "#D9D9D9";
    case "Failed upload":
    case "Canceled":
      return "#FF9F9F";
    case "Received for processing":
    case "In progress":
      return "#FCE15B";
    case "Complete":
      return "#D6F6E4";
  }
  return "#D9D9D9";
};
