/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from "react";
import { useSelectContext } from "./selectContext";

/**
 * This type describes the properties related with a Option component and
 * the expected format for each of them.
 * The type property defines if the option would be plain text or checkbox
 */
export type SelectOption = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  name: string;
  value: unknown;
  type?: "text" | "checkbox" | "custom";
  customOnClick?: () => void;
  customOption?: JSX.Element;
  hideOnSelect?: boolean;
  className?: string;
  reset?: boolean;
};

/**
 * This component contains the Option presented
 * that will be displayed inside the Select components
 * @param {SelectOption} props - Props values injected to the component.
 * @returns {JSX.Element}
 */
// ToDo: Implement value propagation on event handler instead name.
export const Option = ({
  name,
  value,
  type,
  customOption,
  customOnClick,
  hideOnSelect,
  className,
  reset,
}: SelectOption): JSX.Element => {
  const { changeSelectedOption, selectedOption } = useSelectContext();
  const hide = hideOnSelect || false;
  const optionRef = useRef(null);

  const optionElement = (): JSX.Element | string | null => {
    switch (type) {
      case "checkbox":
        return (
          <div className="select-option-radio">
            <input
              name="select-radio"
              type="radio"
              checked={selectedOption === name}
              onChange={() => undefined}
              ref={optionRef}
            />
            <span>{name}</span>
          </div>
        );
      case "custom":
        return customOption || null;
      default:
        return name;
    }
  };

  const handleOnClick = (): void => {
    if (type === "custom" && customOnClick) {
      customOnClick();
    }
    changeSelectedOption(name, value, hide, reset === true);
  };

  return (
    <li
      data-testid="select-option"
      onClick={handleOnClick}
      aria-hidden="true"
      className={className}
    >
      {optionElement()}
    </li>
  );
};

Option.defaultProps = {
  type: undefined,
  customOnClick: () => null,
  customOption: undefined,
  hideOnSelect: false,
  className: "",
  reset: false,
};
