import React, {
  forwardRef,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import { Field } from "../field";
import { Input } from "../input";
import { isValidDay, isValidMonth } from "../../../utils";
import styles from "./date-filter-range-input.module.scss";

type DateRangeInputProps = {
  selected: boolean;
  error: boolean;
  label: string;
  // eslint-disable-next-line react/require-default-props
  value?: string;
  onChange: (value: string) => void;
  // eslint-disable-next-line react/require-default-props
  onClick?: (event: MouseEventHandler) => void;
};

/**
 * Input field for date range
 * @param {DateRangeInputProps}
 * @returns {JSX.Element}
 */
// eslint-disable-next-line react/display-name
export const DateRangeInput = forwardRef<HTMLInputElement, DateRangeInputProps>(
  (props, ref) => {
    const { label, value, onChange, onClick, selected, error } = props;
    const [isValid, setIsValid] = useState(false);
    const [dateValue, setDateValue] = useState(value);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onKeyPress = (e: any): void => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const input = e.target;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (e.charCode < 47 || e.charCode > 57) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        e.preventDefault();
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const len = input.value.length;

      if (len === 10) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        e.preventDefault();
      }

      if (len !== 1 || len !== 3) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (e.charCode === 47) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          e.preventDefault();
        }
      }

      if (len === 4) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        input.value += "/";
      }

      if (len === 7) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        input.value += "/";
      }
    };

    const changeDate = (dateString: string): void => {
      setIsValid(false);
      const len = dateString.length;
      const yyyy = len > 4 && parseInt(dateString.substring(0, 5), 10);
      if (yyyy) {
        const mm = len > 6 && parseInt(dateString.substring(5, 7), 10);
        if (mm) {
          if (!isValidMonth(mm)) {
            setDateValue(dateString.substring(0, dateString.length - 2));
            return;
          }
          const dd = len > 9 && parseInt(dateString.substring(8, 10), 10);
          if (dd) {
            if (!isValidDay(dd, mm, yyyy)) {
              setDateValue(dateString.substring(0, dateString.length - 2));
              return;
            }
            setDateValue(dateString);
            setIsValid(true);
          }
        }
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onKeyUp = (e: any): void => {
      setIsValid(false);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const { value: date } = e.target;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      e.preventDefault();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      changeDate(date);
    };

    useEffect(() => {
      if (isValid && dateValue && dateValue !== "") {
        onChange(dateValue);
      } else {
        onChange("");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid, dateValue]);

    useEffect(() => {
      setDateValue(value);
    }, [value]);

    return (
      <Field
        disabled={!selected}
        className={styles["date-range-input"]}
        label={label}
      >
        <div
          className={`${styles["date-range-input-wrapper"]} ${
            error ? styles["error-input"] : ""
          }`}
        >
          <Input
            placeholder="YYYY/MM/DD"
            onKeyPress={onKeyPress}
            onKeyUpCapture={onKeyUp}
            value={dateValue}
            ref={ref}
            onClick={onClick}
          />
        </div>
      </Field>
    );
  }
);
