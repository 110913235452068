/**
 * Map of ISO 639-2B(eng) to full language names(English)
 */

export const languageISOCode: { [x: string]: string } = {
  ABK: "Abkhazian",
  ARE: "Arabic - Egyptian",
  AFK: "Afrikaans",
  MSA: "Arabic",
  ARC: "Aramaic",
  ARL: "Arabic - UAE & Lebanon",
  AYM: "Aymara",
  AZE: "Azerbaijani",
  BEL: "Belarusian",
  BEN: "Bengali",
  BOD: "Tibetan",
  BOS: "Bosnian",
  PTB: "Portuguese - Brazilian",
  BUL: "Bulgarian",
  SCA: "Catalan - Valencian",
  CZE: "Czech",
  CM1: "Chinese Traditional Characters",
  CMN: "Chinese Simplified Characters",
  CN1: "Chinese Simplified Characters - PRC",
  SPC: "Spanish - Castilian",
  DAN: "Danish",
  AST: "German - Austrian",
  GER: "German",
  SPA: "Spanish - Argentinian",
  ECH: "Spanish - Chilean",
  GRK: "Greek - Modern",
  SPM: "Spanish - Mexican",
  ENG: "English",
  AUS: "English - Australian",
  EST: "Estonian",
  EUK: "English - British",
  EUS: "Basque",
  FAO: "Faroese",
  FIN: "Finnish",
  FLM: "Flemish",
  FRC: "French - Canadian (Quebecois)",
  FRP: "French - France",
  GFR: "French",
  GLA: "Scottish Gaelic",
  GLG: "Galician",
  PRC: "Chinese - Mandarin PRC",
  GPO: "Portuguese (Portugal)",
  GRN: "Guarani",
  GSP: "Spanish",
  SWI: "German - Swiss/Alsatian",
  HAU: "Hausa",
  HEB: "Hebrew",
  HIN: "Hindi",
  CRO: "Croatian",
  HUN: "Hungarian",
  HYE: "Armenian",
  INB: "Indonesian Bahasa",
  ICE: "Icelandic",
  ITA: "Italian",
  JPN: "Japanese",
  KAL: "Greenlandic",
  KAN: "Kannada",
  KAT: "Georgian",
  KAZ: "Kazakh",
  KOR: "Korean",
  LAO: "Lao",
  LAS: "Spanish - Latin American",
  LAT: "Latin",
  LTV: "Latvian",
  LTZ: "Luxembourgish",
  MYM: "Malayalam",
  MAR: "Marathi",
  MAS: "Macedonian",
  MLT: "Maltese",
  MON: "Mongolian",
  MAO: "Maori",
  MAB: "Malay Bahasa",
  TAI: "Chinese - Mandarin Taiwan",
  BUR: "Burmese",
  NAV: "Navajo",
  NEP: "Nepali",
  DUT: "Dutch",
  NOR: "Norwegian",
  PAN: "Punjabi",
  POL: "Polish",
  PTE: "Portuguese - Euproean",
  PUS: "Pashto",
  ROM: "Romanian - Moldavian",
  RUS: "Russian",
  SLK: "Slovak",
  SLV: "Slovenian",
  SME: "Northern Sami",
  SOM: "Somali",
  SQI: "Albanian",
  SER: "Serbian",
  SWA: "Swahili",
  SWE: "Swedish",
  TAH: "Tahitian",
  TAM: "Tamil",
  TEL: "Telugu",
  TAG: "Tagalog",
  THA: "Thai",
  TSO: "Tsonga",
  TUR: "Turkish",
  UKR: "Ukrainian",
  URD: "Urdu",
  VIE: "Vietnamese",
  XHO: "Xhosa",
  YOR: "Yoruba",
  YU1: "Chinese Traditional Characters - Hong Kong",
  CNT: "Chinese - Cantonese",
  ZM1: "Chinese Simplified Characters - Malaysia",
  ZS1: "Chinese Simplified Characters - Singapore",
  ZT1: "Chinese Traditional Characters - Taiwan",
  ZUL: "Zulu",
  ZXX: "No Linguistic Content",
};

export const languagesLTMCode = {
  ab: "Abkhazian",
  af: "Afrikaans",
  ar: "Arabic",
  "ar-AE": "Arabic - UAE & Lebanon",
  "ar-EG": "Arabic - Egyptian",
  arc: "Aramaic",
  ay: "Aymara",
  "az-AZ": "Azerbaijani",
  be: "Belarusian",
  bg: "Bulgarian",
  bn: "Bengali",
  bo: "Tibetan",
  "bs-BA": "Bosnian",
  ca: "Catalan - Valencian",
  cmn: "Chinese - Mandarin PRC",
  "cmn-TW": "Chinese - Mandarin Taiwan",
  cs: "Czech",
  da: "Danish",
  de: "German",
  "de-AT": "German - Austrian",
  "de-CH": "German - Swiss/Alsatian",
  el: "Greek - Modern",
  "en-AU": "English - Australian",
  "en-GB": "English - British",
  "en-US": "English",
  es: "Spanish",
  "es-419": "Spanish - Latin American",
  "es-AR": "Spanish - Argentinian",
  "es-CL": "Spanish - Chilean",
  "es-ES": "Spanish - Castilian",
  "es-MX": "Spanish - Mexican",
  et: "Estonian",
  eu: "Basque",
  fi: "Finnish",
  fo: "Faroese",
  fr: "French",
  "fr-CA": "French - Canadian (Quebecois)",
  "fr-FR": "French - France",
  gd: "Scottish Gaelic",
  gl: "Galician",
  gn: "Guarani",
  ha: "Hausa",
  he: "Hebrew",
  hi: "Hindi",
  hr: "Croatian",
  hu: "Hungarian",
  hy: "Armenian",
  id: "Indonesian Bahasa",
  is: "Icelandic",
  it: "Italian",
  ja: "Japanese",
  ka: "Georgian",
  kk: "Kazakh",
  kl: "Greenlandic",
  kn: "Kannada",
  ko: "Korean",
  la: "Latin",
  lb: "Luxembourgish",
  lo: "Lao",
  lv: "Latvian",
  mi: "Maori",
  mk: "Macedonian",
  ml: "Malayalam",
  mn: "Mongolian",
  mr: "Marathi",
  "ms-BN": "Malay Bahasa",
  "ms-MY": "Chinese Simplified Characters - Malaysia",
  mt: "Maltese",
  my: "Burmese",
  nb: "Norwegian",
  ne: "Nepali",
  nl: "Dutch",
  nv: "Navajo",
  pa: "Punjabi",
  pl: "Polish",
  ps: "Pashto",
  pt: "Portuguese (Portugal)",
  "pt-BR": "Portuguese - Brazilian",
  "pt-PT": "Portuguese - Euproean",
  ro: "Romanian - Moldavian",
  ru: "Russian",
  se: "Northern Sami",
  sk: "Slovak",
  sl: "Slovenian",
  so: "Somali",
  sq: "Albanian",
  "sr-SP": "Serbian",
  sv: "Swedish",
  sw: "Swahili",
  ta: "Tamil",
  te: "Telugu",
  th: "Thai",
  tl: "Tagalog",
  tr: "Turkish",
  ts: "Tsonga",
  ty: "Tahitian",
  uk: "Ukrainian",
  ur: "Urdu",
  vi: "Vietnamese",
  vls: "Flemish",
  xh: "Xhosa",
  yo: "Yoruba",
  "zh-HK": "Chinese Traditional Characters - Hong Kong",
  "zh-Hans": "Chinese Simplified Characters",
  "zh-Hans-CN": "Chinese Simplified Characters - PRC",
  "zh-Hant": "Chinese Traditional Characters",
  "zh-Hant-TW": "Chinese Traditional Characters - Taiwan",
  "zh-SG": "Chinese Simplified Characters - Singapore",
  "zh-YUE": "Chinese - Cantonese",
  zu: "Zulu",
};

export const languageNames: { [x: string]: string } = {
  ...languageISOCode,
  ...languagesLTMCode,
};
