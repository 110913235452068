import { RefObject, useEffect } from "react";

/**
 * This type describes an event generated from user input/
 */
type AnyEvent = MouseEvent | TouchEvent;

/**
 * React hook for listening for clicks outside of a specified element
 * This can be useful for closing a modal, a dropdown menu etc.
 * @param {RefObject} ref - Ref of the target component.
 * @param {function} handler - Function to handle event.
 * @returns {JSX.Element}
 */
function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: AnyEvent) => void
): void {
  useEffect(() => {
    const listener = (event: AnyEvent): void => {
      const el = ref?.current;

      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export default useOnClickOutside;
