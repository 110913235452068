/* eslint-disable max-len */
import {
  GenericRequest,
  GenericResponse,
} from "../../core/types/http-service.types";
import { HttpClient } from "../../core/classes/HttpClient";
import { DsTitleAPIService } from "./ds-title-api.service";
import { DmdcTitleAPIService } from "./dmdc-title-api.service";

export type SearchRequestQuery = {
  limit?: number;
};

export const TitleParentTypes: SearchTitleType[] = [
  "feature",
  "series",
  "miniseries",
  "special",
  "short",
  "supplemental",
];

export type SearchTitleType =
  | "bundle"
  | "collection"
  | "component"
  | "episode"
  | "feature"
  | "interstitial"
  | "miniseries"
  | "pilot"
  | "season"
  | "series"
  | "short"
  | "special"
  | "supplemental"
  | "compilation episode"
  | "event"
  | "unknown";

export type SearchRequestBody = {
  searchText?: string;
  typesToReturn?: SearchTitleType[];
};

export type SearchResults = {
  results: ChildEntity[];
};

export interface AlternateNames {
  copyright: Copyright;
  master: Master;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Copyright {}
export interface Master {
  standardized: string;
  disambiguated: string;
  withFormat?: unknown;
  withYear?: unknown;
}
export interface DisneyPlus {
  isDisneyPlusOriginal: boolean;
  isSelected: boolean;
  productDescriptionPrimary: string;
  productDescriptionSecondary: string;
  brands?: BrandsEntity[] | unknown;
  franchises?: unknown[] | unknown;
}
export interface BrandsEntity {
  level1Name: string;
  level2Name: string;
  level3Name: string;
  level1CtamGroupId: string;
  level2CtamGroupId: string;
  level3CtamGroupId: string;
}
export interface ReleasingCompaniesEntity {
  name: string;
}
export interface SystemRepresentations {
  count: number;
  // eslint-disable-next-line camelcase
  blast_dcww_ff: BlastDcwwFf;
  ctam: Ctam;
  radar: Radar;
  sonar: Sonar;
}
export interface BlastDcwwFf {
  id: string;
}
export interface Ctam {
  titleId: string;
}
export interface Radar {
  groupId: string;
  groupNumber: string;
  productId: string;
  productNumber: string;
  productTitle: string;
  language: string;
  productClass: string;
  productSubclass: string;
}
export interface Sonar {
  id: string;
  title: string;
}
export interface TitleAssociations {
  series: Series;
}
export interface ChildEntity {
  eidrL1?: unknown;
  productId: number;
  name: string;
  type?: string;
  securityLevel?: string;
  seasonEpisodeNumber?: string;
  sequence?: number;
  foreignEpisodeNumber?: string;
  foxFinancialId?: unknown;
  episodeNumber?: string;
  cpmId?: string;
  episodeAirDate?: string;
  episodeTitle?: string;
  seasonNumber?: string;
  isActive?: boolean;
  seriesName?: string;
  releaseDate?: string;
}
export interface Series {
  children?: ChildEntity[] | unknown;
  parent: Parent;
}
export interface Parent {
  eidrL1?: unknown;
  name: string;
  productId: number;
  securityLevel: string;
}
export interface UpdateContext {
  system: string;
}
export interface CharactersEntity {
  characterId: number;
  characterName: string;
  worldId: number;
}

export interface TitleDetails {
  eidrL1?: unknown;
  isActive: boolean;
  isPrimary: boolean;
  isReviewed: boolean;
  productId: number;
  radarRegistrationStatus: string;
  securityLevel: string;
  type: string;
  updatedAt: string;
  name: string;
  seriesName: string;
  seasonNumber: string;
  alternateNames: AlternateNames;
  synopsisMedium?: unknown;
  disneyPlus: DisneyPlus;
  ratings?: unknown[] | unknown;
  class: string;
  subclass: string;
  color: string;
  createdAt: string;
  creativeFormat: string;
  isScripted: boolean;
  language: string;
  marketingReleases?: unknown[] | unknown;
  productSource: string;
  productionCompanies?: unknown[] | unknown;
  releases?: unknown[] | unknown;
  earliestReleaseDate?: string;
  releasingCompanies?: ReleasingCompaniesEntity[] | unknown;
  estimatedRuntimeSecs: number;
  seasonEpisodeNumber: string;
  systemRepresentations: SystemRepresentations;
  titleAssociations: TitleAssociations;
  updateContext: UpdateContext;
  characters?: CharactersEntity[] | unknown;
  imageUrl?: unknown;
}
export interface TitleChildren {
  productId: number;
  name: string;
  type: string;
  eidrL1?: null;
  foxFinancialId?: null;
  children?: ChildEntity[] | null;
}

export interface TitleVersion {
  versionId: string;
  name: string;
  eidrL2?: string;
  source?: string;
}

export interface TitleVersions {
  versions: TitleVersion[];
}

export type SearchRequest = GenericRequest<
  SearchRequestQuery,
  SearchRequestBody
>;
export type SearchResponse = GenericResponse<SearchResults>;
export type GetTitleDetailsResponse = GenericResponse<TitleDetails>;
export type GetTitleChildrenResponse = GenericResponse<TitleChildren>;
export type GetTitleVersionsResponse = GenericResponse<TitleVersions>;

export interface TitleAPIServiceInterface {
  readonly token: string | undefined;
  readonly client: HttpClient;
  searchTitle: (searchRequest: SearchRequest) => Promise<SearchResponse>;
  getTitleDetails: (
    titleId: string | number | undefined
  ) => Promise<GetTitleDetailsResponse>;
  getTitleChildren: (
    titleId: string | number | undefined
  ) => Promise<GetTitleChildrenResponse>;
  getTitleVersions: (
    titleId: string | number | undefined
  ) => Promise<GetTitleVersionsResponse>;
}

export type TitleAPIService = DsTitleAPIService | DmdcTitleAPIService;
