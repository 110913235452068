/* eslint-disable react/require-default-props */
import React from "react";
import { MenuItem, MenuItemProps } from "../menu-item";
import "./menu.scss";

/**
 * This type describes the properties related with a menu component and
 * the expected format for each of them.
 */
export type MenuProps = {
  items?: Array<MenuItemProps>;
  children?: JSX.Element | JSX.Element[];
};

/**
 * This functional component contains the main menu presented
 * on the widget that allows the user to select the action to
 * be performed on a package.
 * @param {MenuProps} props - Props values injected to the component.
 * @returns {JSX.Element}
 */
export const Menu = (props: MenuProps): JSX.Element => {
  const { items, children } = props;
  /**
   * ToDo change the key to allow react to optimize the rendering.
   */
  return (
    <div className="uw-menu">
      {items &&
        items.map((item) => (
          <MenuItem
            key={item.key}
            title={item.title}
            description={item.description}
            linkText={item.linkText}
            icon={item.icon}
            to={item.to || undefined}
          />
        ))}
      {!items && children}
    </div>
  );
};
