import Connect from "./Connect";
import ConnectInstaller from "./ConnectInstaller";

export class AW4 {
  static Connect: Connect;

  static ConnectInstaller: ConnectInstaller;
}

export default AW4;
