/* eslint-disable react/no-array-index-key */
import React from "react";
import { ColumnDefinitionType } from "./table.types";

type TableRowsProps<T, K extends keyof T> = {
  data: Array<T>;
  columns: Array<ColumnDefinitionType<T, K>>;
};

const TableRows = <T, K extends keyof T>({
  data,
  columns,
}: TableRowsProps<T, K>): JSX.Element => {
  const rows = data.map((row, index) => (
    <tr key={`row-${index}`} className="uw-table__row">
      {columns.map((column, index2) => (
        <td
          key={`cell-${index2}`}
          style={{ width: column.width ? `${column.width}%` : "" }}
        >
          {(column.isAction &&
            column.actionMapper &&
            column.actionMapper(row)) ||
            ((!column.isAction && row[column.key]) as any)}
        </td>
      ))}
    </tr>
  ));

  return <tbody>{rows}</tbody>;
};

export default TableRows;
