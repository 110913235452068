/* eslint-disable react/require-default-props */
import React from "react";
import { Link } from "react-router-dom";
import "./menu-item.scoped.scss";

/**
 * This type describes the properties related with a menu item component and
 * the expected format for each of them.
 */
export type MenuItemProps = {
  /** Property to assign a unique key for this item */
  // eslint-disable-next-line react/no-unused-prop-types
  key?: string;
  /** The main text to show on the menu item */
  title: string;
  /** The description text to explain the function behind the menu item */
  description: string;
  /** The text on the link that describes where it directs to */
  linkText?: string;
  /** The icon of the menu item */
  icon?: string;
  /** Optional property to define if the menu item will change the active
   * route of the application
   */
  to?: string;
};

/**
 * This functional component describes a menu-item which is going to be used
 * by the main menu view which is the entry point for the application, where
 * the user can select the action to perform (create or edit a vide package).
 * @param {MenuItemProps} props - Props values injected to the component.
 * @returns {JSX.Element}
 */
export const MenuItem = (props: MenuItemProps): JSX.Element => {
  const { title, description, linkText, to = "", icon } = props;

  const commonContent = (
    <Link to={to}>
      <div className="menu-item">
        <img className="menu-item-icon" src={icon} alt={title} />
        <h2 className="menu-item-title">{title}</h2>
        <span className="menu-item-description">{description}</span>

        <span className="menu-item-link-text">{linkText}</span>
      </div>
    </Link>
  );

  if (to) {
    return <>{commonContent}</>;
  }
  return commonContent;
};
