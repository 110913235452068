/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import moment from "moment";
import "./index.scss";
import {
  DigitalScreenersMediaManagerWebComponent,
  DigitalScreenersStatusWidget,
  DigitalScreenersUploadWidget,
} from "./web-components";

moment.locale();
declare global {
  interface Window {
    mountWidget: (
      portal?: string,
      token?: string,
      context?: string,
      userActionFlags?: string
    ) => void;
    langCodes: () => void;
  }
}

/*
addCdnScript('https://unpkg.com/react@17/umd/react.production.min.js');
addCdnScript('https://unpkg.com/react-dom@17/umd/react-dom.production.min.js');
*/
const {
  REACT_APP_BUNDLE,
  REACT_APP_PORTAL,
  REACT_APP_TOKEN,
  REACT_APP_CONTEXT,
  REACT_APP_USER_FLAGS,
} = process.env;

customElements.define(
  "ds-media-manager",
  DigitalScreenersMediaManagerWebComponent
);
customElements.define("ds-upload-widget", DigitalScreenersUploadWidget);
customElements.define("ds-status-widget", DigitalScreenersStatusWidget);
try {
  window.mountWidget = (
    portal?: string,
    token?: string,
    context?: string,
    userActionFlags?: string
  ): void => {
    const component = new DigitalScreenersMediaManagerWebComponent();
    component.portal = portal || REACT_APP_PORTAL || "";
    component.token = token || REACT_APP_TOKEN || "";
    component.context = context || REACT_APP_CONTEXT || "DS";
    component.id = "upload-widget";
    component.userActionFlags = userActionFlags || REACT_APP_USER_FLAGS || "";

    document.getElementById("root")?.appendChild(component);
  };
  window.dispatchEvent(new Event("ds-widget-ready"));
  if (REACT_APP_BUNDLE !== "true") {
    window.mountWidget();
  }
} catch (e) {
  console.log(e);
}
