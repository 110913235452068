/* eslint-disable react/require-default-props */
import React from "react";
import "./confirmation-dialog.scoped.scss";

/**
 * This type describes the properties related with a menu component and
 * the expected format for each of them.
 */
export type ConfirmationDialogProps = {
  handleConfirm?: (item: unknown) => void;
  handleCancel?: () => void;
};

/**
 * This functional component contains the Confirmation Dialog
 * component presented on the widget
 * @param {ConfirmationDialogProps} props - Props values injected to the
 * component.
 * @returns {JSX.Element}
 */
export const ConfirmationDialog = (
  props: ConfirmationDialogProps
): JSX.Element => {
  const { handleConfirm, handleCancel } = props;

  return (
    <div className="confirmation-dialog">
      <button
        type="button"
        className="confirmation-dialog__btn cancel"
        onClick={handleCancel}
      >
        Cancel
      </button>
      <button
        type="button"
        className="confirmation-dialog__btn confirm"
        onClick={handleConfirm}
      >
        Confirm
      </button>
    </div>
  );
};
