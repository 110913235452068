export enum WidgetEventType {
  PACKAGE_ID_CREATED = "PACKAGE_ID_CREATED",
  NEW_PACKAGE_UPLOAD_STARTED = "NEW_PACKAGE_UPLOAD_STARTED",
  UPDATE_PACKAGE_UPLOAD_STARTED = "UPDATE_PACKAGE_UPLOAD_STARTED",
  NEW_PACKAGE_CANCELLED = "NEW_PACKAGE_CANCELLED",
  UPDATE_PACKAGE_CANCELLED = "UPDATE_PACKAGE_CANCELLED",
}

export type DSMediaManagerEvent = {
  type: WidgetEventType;
  payload: unknown;
};

export type DSEventEmitter = {
  next: (ev: DSMediaManagerEvent) => void;
};
