import {
  FileType,
  FileNameParserInterface,
  ENTERPRISE_NAMING_CONVENTION_REGEX,
  NameGroups,
} from "../const/file-types";

import { DsFileNameParser } from "./DsFileNameParser";
import { EnterpriseFileNameParser } from "./EnterpriseFileNameParser";
import { languageNames } from "./languageFullNames";

const NO_LANGUAGE_DEFINED = "Not Defined";
export class FileNameParser implements FileNameParserInterface {
  private parser: DsFileNameParser | EnterpriseFileNameParser;
  public constructor(public readonly fileName: string = "") {
    this.parser = fileName.match(ENTERPRISE_NAMING_CONVENTION_REGEX)
      ? new EnterpriseFileNameParser(this.fileName)
      : new DsFileNameParser(this.fileName);
  }

  public isValidLanguageCode() {
    return this.mapLanguageCodeToLanguageName() !== NO_LANGUAGE_DEFINED;
  }

  public mapLanguageCodeToLanguageName(): string {
    const languageCode = this.getLanguageCode();
    return languageCode
      ? languageNames[languageCode] || NO_LANGUAGE_DEFINED
      : NO_LANGUAGE_DEFINED;
  }

  public getTypeAndLanguage(): string {
    const type = this.getFileType();
    const language = this.getLanguageCode() as string;
    return `${type}_${language}`;
  }

  public getVersion(): number | undefined {
    return this.parser.getVersion();
  }

  public getLanguageCode(): string | undefined {
    return this.parser.getLanguageCode();
  }

  public validateComponentFileName(): boolean {
    return this.parser.validateComponentFileName();
  }

  public getFileDetails(): NameGroups | undefined {
    return this.parser.getFileDetails();
  }

  public getFileType(): FileType {
    return this.parser.getFileType();
  }

  public validateVideoFileName(): boolean {
    return this.parser.validateVideoFileName();
  }
}
