import UPLOAD_WIDGET_ACTIONS from "./actions";
import UploadWidgetState from "./state";

/**
 * This function contains all the reducer handlers for each
 * action contained on the application.
 * @param {UploadWidgetState} state - The value of the state
 * when the action was originated.
 * @param {UPLOAD_WIDGET_ACTIONS} action - An action string
 * that tells the reducer which action needs to be executed.
 * @returns {UploadWidgetState} state - a mutated version of
 * the received state.
 */
export default function UploadWidgetReducer(
  state = {
    fileContainer: {
      files: [],
    },
  },
  action: UPLOAD_WIDGET_ACTIONS | unknown
): UploadWidgetState {
  switch (action) {
    case UPLOAD_WIDGET_ACTIONS.NO_ACTION:
    default:
      return state as UploadWidgetState;
  }
}
