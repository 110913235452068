/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from "react";
import "./step.scoped.scss";

export type StepProps = {
  /** Main title content */
  title: string;
  /** Description title content */
  titleDescription?: string;
  /** Children property contains the HTML inside the Step component tag. */
  children: JSX.Element[] | JSX.Element | HTMLElement | ReactNode;
  /** Defines how the Step will be rendered and also Steps after this. */
  behavior?: "shown" | "hidden";
  /** Defines if the step is the current one on the flow. */
  current?: boolean;
  /** Property automatically generated by Steps component. */
  index?: number;
  /** Property that indicates if step linking line will be shown */
  showLine?: boolean;
  /** Property that adds a form functionality to the step */
  stepForm?: JSX.Element;
};

/**
 * This component is the container for all the steps used inside the Steps
 * component, this component handles the render of all sections inside a Step
 * as well as the css classes generation according to the state of the Step
 * props.
 * @param {StepProps} props - Props values injected to the component.
 * @returns {JSX.Element}
 */
export const Step = (props: StepProps): JSX.Element => {
  const {
    index,
    title,
    titleDescription,
    children,
    current,
    behavior,
    showLine,
    stepForm,
  } = props;

  /**
   * This function checks for conditions on the component properties and returns
   * the css classes value for each element.
   * @returns {string} classes assigned to the step.
   */
  const getClasses = (): string => {
    const classArray = [current ? "current" : "", behavior];
    return classArray.join(" ");
  };

  return (
    <div className={`step ${getClasses()}`}>
      <div className={`step-title ${getClasses()}`}>
        <span className={`step-title-index ${getClasses()}`}>
          {index !== undefined ? index + 1 : 0}
        </span>
        <div className={`step-title-text ${getClasses()}`}>
          <span className={`step-title-text-main ${getClasses()}`}>
            {title}
          </span>
          {titleDescription && (
            <span className={`step-title-text-description ${getClasses()}`}>
              {titleDescription}
            </span>
          )}
          {stepForm && <div className="step-title-form">{stepForm}</div>}
        </div>
      </div>
      <div className={`step-content ${showLine ? "line" : ""} ${getClasses()}`}>
        {children as any}
      </div>
    </div>
  );
};

Step.defaultProps = {
  titleDescription: "",
  behavior: "shown",
  current: false,
  index: 0,
  showLine: false,
};
