import { createStore, Store } from "redux";
import UploadWidgetReducer from "./reducer";
import UploadWidgetState from "./state";

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
const enableReduxDevTools = (window as any)["__REDUX_DEVTOOLS_EXTENSION__"]?.();

/**
 * This function defines the entry point for the redux store, it will
 * create a new instance of the store receiving the reducer handle as
 * its main argument and then returns that new instance.
 * @returns {Store<UploadWidgetState>} store - New store instance.
 */
export function createReduxStore(): Store<UploadWidgetState> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const store = createStore(UploadWidgetReducer, enableReduxDevTools);
  return store as Store<UploadWidgetState>;
}
