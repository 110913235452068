/* eslint-disable @typescript-eslint/no-explicit-any */
import { DSPackage } from "@screeners/upload-datamodel";
import React, { useEffect, useRef } from "react";
import "./autocomplete.scoped.scss";

type SuggestionListProps = {
  activeIndex: number;
  disablingCriteria?: (value: any) => boolean;
  filteredSuggestions: DSPackage[];
  stringMapper: ((item: any, format?: string) => string) | undefined;
  onClick: (event: any) => void;
  suggestionItem:
    | ((props: { item: DSPackage; disabled: boolean }) => React.ReactElement)
    | undefined;
};

export const SuggestionList = ({
  activeIndex,
  disablingCriteria,
  filteredSuggestions,
  stringMapper,
  suggestionItem,
  onClick,
}: SuggestionListProps): JSX.Element => {
  const ulSuggestions = useRef<HTMLUListElement>(null);
  const listItem = (suggestion: DSPackage): any => {
    if (suggestionItem) {
      return suggestionItem({
        item: suggestion,
        disabled: disablingCriteria?.(suggestion) ?? false,
      });
    }
    if (stringMapper) return stringMapper(suggestion);
    return suggestion;
  };

  useEffect(() => {
    if (
      ulSuggestions.current &&
      ulSuggestions.current.children.length - 1 >= activeIndex
    ) {
      ulSuggestions.current.children[activeIndex].scrollIntoView({
        block: "nearest",
      });
    }
  }, [activeIndex]);

  return (
    <>
      {filteredSuggestions.length ? (
        <ul ref={ulSuggestions}>
          {filteredSuggestions.map((suggestion, index) => (
            // eslint-disable-next-line jsx-a11y/role-supports-aria-props
            <li
              aria-hidden="true"
              aria-disabled={disablingCriteria?.(suggestion) ? "true" : "false"}
              className={`input-container__list-item ${
                index === activeIndex ? "active" : ""
              }`}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={
                (/* event */) => {
                  onClick(index);
                }
              }
            >
              {listItem(suggestion)}
            </li>
          ))}
        </ul>
      ) : null}
    </>
  );
};

SuggestionList.defaultProps = {
  disablingCriteria: () => false,
};
