import React from "react";
import { Field, Select, Option } from "../../../components/form";
import { TitleVersion } from "../../../services/title-api/title-api.types";

export type VersionFieldProps = {
  onVersionSelected: (name: string, value: TitleVersion) => void;
  value?: string;
  versions: TitleVersion[];
  disabled: boolean;
  loading?: boolean;
  required: boolean;
};

const getLabel = (version: TitleVersion): string =>
  version.name || version.eidrL2 || version.versionId || "";

export const VersionField = (props: VersionFieldProps) => {
  const { disabled, loading, versions, onVersionSelected, value, required } =
    props;
  return (
    <Field disabled={disabled} label={"Version" + (required ? "*" : "")}>
      <Select
        loading={loading}
        value={value}
        onOptionChange={onVersionSelected}
      >
        {[...versions]
          .sort((a, b) => getLabel(a).localeCompare(getLabel(b)))
          .map((version) => (
            <Option
              name={getLabel(version)}
              value={version}
              key={version.versionId}
              hideOnSelect
            />
          ))}
      </Select>
    </Field>
  );
};
