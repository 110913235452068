/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-one-expression-per-line */
import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AiFillWarning } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import { DropZone, DropZoneFileDTO } from "../../components/drop-zone";
import { Steps, Step } from "../../components/steps";
import { ReadMore } from "../../components/read-more";
import { Button, Field } from "../../components/form";
import { Tooltip } from "../../components/tooltip";
import { StatusIcon } from "../../components/status-icon";
import { DropZoneFileTableItem, FileTable } from "../common/file-table";
import { PackageItem } from "../common/package-item";
import { FullMessageLanguage } from "../new-package/full-message-language";
import { Autocomplete } from "../../components/autocomplete";
import {
  calcFilesByType,
  deleteFromLanguageFilesArray,
  updateLanguageFilesArray,
  updateLanguageFilesStatus,
  ItemStatus,
  TableItemDTO,
} from "../../utils";

import { UploadAPIService } from "../../services/upload-api/upload-api.service";
import {
  SearchPackageResponse,
  UpdatePackageResponse,
} from "../../services/upload-api/upload-api.types";
import { useArray } from "../../hooks";
import {
  ModalContext,
  ModalContextActions,
  ModalProps,
  ModalType,
} from "../../components/modal";
import {
  DigitalScreenersMediaManagerContext,
  // eslint-disable-next-line max-len
} from "../../app/digital-screeners-media-manager/digital-screeners-media-manager";
import {
  ToastContext,
  ToastContextDef,
  ToastType,
} from "../../components/toast";
import { TitleAPIService } from "../../services/title-api/title-api.types";
import { DSEventEmitter, WidgetEventType } from "../../types/eventEmitter";
import { View } from "../../components";
import "./update-package-view.scss";
import { DSFile, DSPackage } from "@screeners/upload-datamodel";
import { EmailList, EmailListModel } from "../../components/email-list";
import {
  DSScreenerTypeValues,
  PreQCTrailer,
  PreliminaryScreener,
} from "../../services/title-api/dmdc-title-api.types";

/**
 * This component will show the main menu view to allow the user to select
 * the action to perform inside the application:
 * - Create a new video
 * - Modify an existing video.
 * @returns {JSX.Element}
 */
export const UpdatePackageView = (): JSX.Element => {
  // #region State
  const contentArray = useArray([] as DropZoneFileTableItem[]);
  const languageFilesArray = useArray<TableItemDTO>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [titleValue, setTitleValue] = useState<DSPackage | undefined>();
  const [emailList, setEmailList] = useState<EmailListModel>({
    emails: [],
    isValidList: true,
  });
  const { packageId: packageIdQuery } = useParams() as { packageId?: string };
  const [noQCRequired, setNoQCRequired] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchingByPackageIdInUrl, setSearchingByPackageIdInUrl] = useState(
    !!packageIdQuery
  );
  const [newSearch, setNewSearch] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [packageId, setPackageId] = useState("");
  const suggestions = useArray<DSPackage>([]);
  const [disableUpload, setDisableUpload] = useState(false);
  const [packageNotFound, setPackageNotFound] = useState({
    state: false,
    message: "",
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
  const [debouncer, setDebouncer] = useState<any>();
  const [languageError, setLanguageError] = useState(false);
  const [selectedLanguageFiles, setSelectedLanguageFiles] = useState<
    DropZoneFileDTO[]
  >([]);
  // #endregion

  // #region Contexts
  const uploadWidgetContext = useContext(DigitalScreenersMediaManagerContext);
  const { uploadAPI, userEmail, portal, eventEmitter, context } =
    uploadWidgetContext as {
      uploadAPI: UploadAPIService;
      titleAPI: TitleAPIService;
      userEmail: string;
      portal: string;
      eventEmitter: DSEventEmitter;
      context: string;
    };
  const toasterContext = useContext(ToastContext);
  const modalContext = useContext(ModalContext);
  // #endregion

  // #region Services
  const { current: AsperaServiceInstance } = useRef(window.AsperaService);
  // #endregion

  // #region Helpers
  const history = useNavigate();

  const getPackageContentFiles = (files: DSFile[]): DropZoneFileTableItem[] => {
    const filesArray = files.map(
      (file) =>
        ({
          name: file.file_name,
          size: file.size,
          type: file.type,
          id: file.file_id,
          component_type: file.component_type,
          component_language: file.component_language,
        } as DropZoneFileTableItem)
    );
    return filesArray;
  };
  // #endregion

  // #region Guards
  const canUpload = (): boolean =>
    languageFilesArray.array.length > 0 &&
    titleValue !== undefined &&
    emailList.isValidList &&
    !languageError &&
    !disableUpload;
  // #endregion

  // #region Event handlers
  const onLanguageFilesSelected = (files: DropZoneFileDTO[]): void => {
    setSelectedLanguageFiles(files);
  };

  const onEmailListChanged = (emailListMoodel: EmailListModel): void => {
    setEmailList(emailListMoodel);
  };

  const onDeleteLanguage = (item: DropZoneFileDTO): void => {
    const newLanguageFileArray = updateLanguageFilesStatus(
      deleteFromLanguageFilesArray(languageFilesArray.array, item),
      titleValue?.files
    );
    languageFilesArray.set(newLanguageFileArray);
  };

  const onSelectItem = (item: DSPackage): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    clearTimeout(debouncer);
    setTitleValue(item);
    setPackageId(item.package_id);
    contentArray.set(getPackageContentFiles(item.files || []));
    const shouldMarkNoQCCheckbox = [
      PreQCTrailer.value,
      PreliminaryScreener.value,
    ].includes(item.metadata?.screenerType as DSScreenerTypeValues);
    setNoQCRequired(shouldMarkNoQCCheckbox);

    setCurrentStep(1);
  };

  const onNoQCRequiredChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setNoQCRequired(e.target.checked);
  };

  const onClickUpload = (): void => {
    setDisableUpload(true);
    if (titleValue) {
      const newPackageBody = {
        package_id: titleValue.package_id,
        metadata: {
          noQCRequired: context === "DMDC" ? noQCRequired : false,
          extras: {
            mail_id: userEmail,
            portal,
            emailsToNotify: emailList.emails,
          },
        },
        files: languageFilesArray.array,
      };
      uploadAPI
        .updatePackage({
          body: newPackageBody,
        })
        .then((transferSpec: UpdatePackageResponse) => {
          if (transferSpec && transferSpec.data) {
            AsperaServiceInstance?.ConnectInstance?.startTransfer(
              transferSpec.data[0].transfer_spec,
              { allow_dialogs: true },
              {
                success: (/* upload: any */) => {
                  eventEmitter.next({
                    type: WidgetEventType.UPDATE_PACKAGE_UPLOAD_STARTED,
                    payload: transferSpec.data,
                  });
                  const duration = 6000;
                  (toasterContext as ToastContextDef).actions.push({
                    duration,
                    message:
                      "Upload has successfully started. " +
                      "You will receive an " +
                      "email with updates.",
                    type: ToastType.SUCCESS,
                  });
                  const route = `/status/${packageId}`;
                  history(route);
                },
                // eslint-disable-next-line no-console
                error: (err: unknown) => console.log(err),
              }
            );
          }
        })
        .finally(() => {
          setDisableUpload(false);
        });
    }
  };
  // #endregion

  // #region Actions
  const searchPackage = async (query: string): Promise<void> => {
    setSearchLoading(true);
    try {
      setPackageNotFound({
        state: false,
        message: "",
      });
      const v: SearchPackageResponse = await uploadAPI.searchPackage({
        query: {
          search: query,
          sortBy: "last_modified",
          sortOrder: "desc",
        },
      });
      setSearchLoading(false);
      if (v && v.data && v.data?.response?.results.length > 0) {
        if (packageIdQuery) {
          onSelectItem(v.data.response.results[0]);
        }
        suggestions.set(v.data.response.results);
      } else if (!window?.navigator?.onLine) {
        // Network Error
        setPackageNotFound({
          state: true,
          message:
            "Something went wrong, connection currently unavailable," +
            "please try again.",
        });
      } else {
        setPackageNotFound({
          state: true,
          message: "This title was not found or does not exist.",
        });
      }
      setSearchingByPackageIdInUrl(false);
    } catch (e) {
      setSearchLoading(false);
      setPackageNotFound({
        state: true,
        message:
          "Something went wrong, connection currently unavailable," +
          "please try again.",
      });
      setSearchingByPackageIdInUrl(false);
    }
  };

  useEffect(() => {
    if (packageIdQuery) {
      void searchPackage(packageIdQuery);
    }
  }, []);

  const createContentTable = (
    content: DropZoneFileTableItem[]
  ): JSX.Element => {
    const video: DropZoneFileTableItem[] = [];
    const languages: DropZoneFileTableItem[] = [];
    content.forEach((element: DropZoneFileTableItem) => {
      if (element.component_type === "VIDEO") {
        video.push(element);
      } else {
        languages.push(element);
      }
    });
    const fileCounters = calcFilesByType(languages);

    return (
      <div className="content">
        <h2 className="table-description">Package ID composition</h2>
        <div className="content-table">
          <h3>Package ID {packageId}</h3>
          <FileTable
            files={video}
            className="content-table-video"
            override={{
              name: {
                header: "Video",
              },
              type: {
                header: "File Type",
              },
              language: {
                header: "Language",
              },
            }}
          />
          <FileTable
            files={languages}
            className="content-table-languages"
            override={{
              name: {
                header: (
                  <div className="language-header">
                    <span className="language-header-title">
                      Language Components
                    </span>
                    <span className="language-header-total">
                      {languages.length}
                    </span>
                    <span className="language-header-files">
                      {fileCounters.audio} Audio | {fileCounters.subtitle}{" "}
                      Subtitle | {fileCounters.forcedSubtitle} Forced Subtitle |{" "}
                      {fileCounters.dubcard} Dubcard
                    </span>
                  </div>
                ),
              },
              type: {
                header: "File Type",
              },
              language: {
                header: "Language",
              },
            }}
          />
        </div>
      </div>
    );
  };

  const refreshSuggestions = (value: string): void => {
    setSearchQuery(value);
  };

  const resetTitle = (): void => {
    setTitleValue(undefined);
    setNewSearch(true);
  };

  const cancel = (): void => {
    const modalProps: ModalProps = {
      type: ModalType.OK_CANCEL,
      title: "If you leave, your changes won’t be saved",
      children: null,
      onCancel: (key: string | undefined) => {
        // eslint-disable-next-line no-console
        console.log("Cancelled", key);
      },
      onSuccess: (/* key: string | undefined */) => {
        eventEmitter.next({
          type: WidgetEventType.UPDATE_PACKAGE_CANCELLED,
          payload: undefined,
        });
        history("/");
      },
      actionStrings: {
        ok: "Leave",
        cancel: "Back to upload",
      },
    };
    (modalContext as { actions: ModalContextActions }).actions.push(modalProps);
  };
  // #endregion

  // #region Effects
  useEffect(() => {
    setLanguageError(
      languageFilesArray.array.some(
        (languageFile: TableItemDTO): boolean =>
          languageFile?.status?.name === ItemStatus.error
      )
    );
  }, [languageFilesArray.array]);

  const [contentTable, setContentTable] = useState<ReactNode>();
  useEffect(() => {
    setContentTable(createContentTable(contentArray.array));
  }, [contentArray.array]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    clearTimeout(debouncer);
    suggestions.clear();
    setDebouncer(
      setTimeout(() => {
        if (searchQuery && searchQuery !== "") {
          void searchPackage(searchQuery);
        }
      }, 1000)
    );
  }, [searchQuery]);

  useEffect(() => {
    const newLanguageFileArray = updateLanguageFilesStatus(
      updateLanguageFilesArray(languageFilesArray.array, selectedLanguageFiles),
      titleValue?.files
    );
    languageFilesArray.set(newLanguageFileArray);
  }, [selectedLanguageFiles]);
  // #endregion

  return (
    <View>
      {(!packageIdQuery ||
        newSearch ||
        (!searchingByPackageIdInUrl &&
          (titleValue || packageNotFound.state))) && (
        <div className="update-package-view">
          <div className="uw-row uw-col">
            <Steps currentStep={currentStep}>
              <Step title="Search title you want to add components to">
                <div className="uw-row uw-col">
                  <div className="uw-row uw-col search">
                    <Field label="Title*">
                      {titleValue ? (
                        <PackageItem
                          className="selected-package-item"
                          item={titleValue}
                          endAdornment={
                            <button
                              className="selected-package-item__change-button"
                              type="submit"
                              onClick={() => resetTitle()}
                            >
                              Change
                              <CancelIcon fontSize="small" />
                            </button>
                          }
                        />
                      ) : (
                        <Autocomplete
                          startAdornment={<SearchIcon />}
                          suggestions={suggestions.array}
                          // eslint-disable-next-line max-len
                          placeholder="Search by Package ID, File Name"
                          // eslint-disable-next-line max-len
                          disablingCriteria={(item: DSPackage) => {
                            const ongoingIngestionStatuses = [
                              "Valid Create Transfer Request",
                              "S3 bucket Uploaded",
                              "Sidecar Delivery Complete",
                              "WonderLand Ingest Complete",
                            ];
                            const videoFile = item.files?.find(
                              (file) => file?.component_type === "VIDEO"
                            );
                            return (
                              !item.vppCatalogId &&
                              !ongoingIngestionStatuses.includes(
                                (videoFile as unknown as DSFile)
                                  ?.request_status as string
                              )
                            );
                          }}
                          suggestionItem={({ item, disabled }) => (
                            <PackageItem item={item} disabled={disabled} />
                          )}
                          onChange={refreshSuggestions}
                          onSelect={onSelectItem}
                          loading={searchLoading}
                        />
                      )}
                    </Field>
                    {packageNotFound.state && !packageId && (
                      <span className="error-message">
                        <AiFillWarning />
                        <span className="error-message__title">
                          {packageNotFound.message}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
                {contentArray.array.length > 0 && contentTable}
              </Step>
              <Step
                title={
                  context === "DMDC"
                    ? "Add or Replace Files to Existing Package"
                    : "Add Language Components"
                }
                titleDescription=""
                behavior="hidden"
                stepForm={
                  context === "DMDC" ? (
                    <>
                      <label htmlFor="no-qc-required">No QC Required</label>
                      <input
                        id="no-qc-required"
                        name="select-radio"
                        type="checkbox"
                        checked={noQCRequired}
                        onChange={onNoQCRequiredChange}
                      />
                    </>
                  ) : undefined
                }
              >
                <div className="language-components">
                  <ReadMore
                    shortMessage="File name requirements and specifications"
                    fullMessage={<FullMessageLanguage />}
                  />
                  <DropZone
                    className={`
                  language-drop-zone 
                  ${languageFilesArray.array.length > 0 ? "condensed" : ""}
                `}
                    title="Drag and drop your files here to upload"
                    // eslint-disable-next-line max-len
                    btnText={
                      languageFilesArray.array.length > 0
                        ? "Add More"
                        : "Select Files"
                    }
                    onFilesSelected={onLanguageFilesSelected}
                    multiple
                  />
                  {languageFilesArray.array.length > 0 && (
                    <FileTable
                      files={
                        languageFilesArray.array as DropZoneFileTableItem[]
                      }
                      className="new-language-files-table content-table-languages"
                      onDelete={onDeleteLanguage}
                      displayTrashIcon={true}
                      columns={[
                        {
                          key: "status",
                          header: "Status",
                          isAction: true,
                          actionMapper: (item: DropZoneFileTableItem) => (
                            <Tooltip text={item?.status?.message || ""}>
                              <StatusIcon
                                status={item?.status?.name || ""}
                                className={`uw-table__icon
                            uw-table__icon-${
                              item?.status?.name?.toLowerCase() || ""
                            }`}
                              />
                            </Tooltip>
                          ),
                        },
                      ]}
                    />
                  )}
                </div>
              </Step>
            </Steps>
            {titleValue && (
              <div className="uw-row uw-col">
                <EmailList
                  values={titleValue.metadata?.extras?.emailsToNotify || []}
                  onListChange={onEmailListChanged}
                />
              </div>
            )}
          </div>
          {currentStep > 0 ? (
            <div className="uw-row uw-col align-right">
              <div className="uw-col as-row align-right">
                <Button
                  variant="boxed"
                  className="bordered"
                  onClick={cancel}
                  value="Cancel"
                />
                <Button
                  variant="boxed"
                  disabled={!canUpload()}
                  onClick={onClickUpload}
                  value="Add Components"
                />
              </div>
            </div>
          ) : null}
        </div>
      )}
    </View>
  );
};
