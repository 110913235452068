export const mapTransferStatus = (transferKey: string): string => {
  switch (transferKey) {
    case "Valid Create Transfer Request":
      return "Uploading";
    case "Valid Update Transfer Request":
      return "Uploading";
    case "Transfer Failed to Respond":
      return "Failed upload";
    case "S3 bucket Uploaded":
      return "Received for processing";
    case "Sidecar Delivery Complete":
      return "In progress";
    case "Wonderland Ingest Time Out":
      return "In progress";
    case "WonderLand Ingest Complete":
      return "Complete";
    case "WonderLand Delivery Requested":
      return "Complete";
    case "WonderLand Delivery Failed":
      return "Canceled";
    case "WonderLand Delivery Complete":
      return "Complete";
    case "Step - Valid Create Transfer Request":
      return "(Step 1/6) Valid Create Transfer Request";
    case "Step - Valid Update Transfer Request":
      return "(Step 1/6) Valid Update Transfer Request";
    case "Step - Transfer Failed to Respond":
      return "(Step 1/6) Transfer Failed to Respond";
    case "Step - S3 bucket Uploaded":
      return "(Step 2/6) S3 bucket Uploaded";
    case "Step - Sidecar Delivery Complete":
      return "(Step 3/6) Sidecar Delivery Complete";
    case "Step - Wonderland Ingest Time Out":
      return "(Step 4/6) Wonderland Ingest Time Out";
    case "Step - WonderLand Ingest Complete":
      return "(Step 4/6) WonderLand Ingest Complete";
    case "Step - WonderLand Delivery Requested":
      return "(Step 5/6) WonderLand Delivery Requested";
    case "Step - WonderLand Delivery Failed":
      return "(Step 5/6) WonderLand Delivery Failed";
    case "Step - WonderLand Delivery Complete":
      return "(Step 6/6) WonderLand Delivery Complete";
    case "Legacy IDVIU File":
      return "Legacy FMC File";
    default:
      return "Unknown State";
  }
};
