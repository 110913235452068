/* eslint-disable react/require-default-props */
import React, { useState, ReactNode } from "react";
import { Button } from "../form/button";
import "./modal.scoped.scss";

const ACTION_STRINGS: ActionStrings = {
  ok: "Ok",
  cancel: "Cancel",
};

export enum ModalType {
  OK = "ok",
  OK_CANCEL = "ok_cancel",
}

export interface ActionStrings {
  ok?: string;
  cancel?: string;
}

export interface ActionSetProps {
  actionStrings: ActionStrings;
  onSuccess?: () => void;
  onCancel?: () => void;
}

export type ModalProps = {
  title: string;
  type: ModalType;
  children: ReactNode;
  modalKey?: string;
  onSuccess?: (key: string | undefined) => void;
  onCancel?: (key: string | undefined) => void;
  onClose?: (key: string | undefined) => void;
  actionStrings?: ActionStrings;
};

const OkCancelActionSet = (props: ActionSetProps): JSX.Element => {
  const { actionStrings, onSuccess, onCancel } = props;
  return (
    <div className="action-set">
      <Button
        value={actionStrings.cancel || ACTION_STRINGS.cancel || ""}
        onClick={onCancel}
        className="transparent"
        buttonId="cancelBtn"
      />
      <Button
        value={actionStrings.ok || ACTION_STRINGS.ok || ""}
        onClick={onSuccess}
        className="ok"
        buttonId="okBtn"
      />
    </div>
  );
};

const OkActionSet = (props: ActionSetProps): JSX.Element => {
  const {
    actionStrings,
    onSuccess,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCancel,
  } = props;
  return (
    <div className="action-set">
      <Button
        value={actionStrings.ok || ACTION_STRINGS.ok || ""}
        onClick={onSuccess}
        buttonId="okBtn"
      />
    </div>
  );
};

/**
 * This component is a Modal which is going to display a message and allow the
 * user to confirm or cancel the action.
 * @param {ModalProps} props - Props values injected to the component.
 * @returns {JSX.Element}
 */
export const Modal = ({
  title,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  modalKey,
  onSuccess,
  onCancel,
  onClose,
  children,
  actionStrings,
  type,
}: ModalProps): JSX.Element => {
  const [closed, setClosed] = useState("");
  const mergedActionStrings = {
    ...ACTION_STRINGS,
    ...actionStrings,
  };
  const onCloseHandler = (): void => {
    setClosed("closed");
    if (onClose) {
      onClose(modalKey);
    }
  };
  const onSuccessWrapperFn = (): void => {
    onCloseHandler();
    if (onSuccess) {
      onSuccess(modalKey);
    }
  };
  const onCancelWrapperFn = (): void => {
    onCloseHandler();
    if (onCancel) {
      onCancel(modalKey);
    }
  };
  return (
    <div className={`uw-modal ${type} ${closed}`}>
      <div className="uw-modal-title">{title}</div>
      {children !== null && <div className="uw-modal-content">{children}</div>}
      <div className="uw-modal-actions">
        {type === ModalType.OK_CANCEL && (
          <OkCancelActionSet
            actionStrings={mergedActionStrings}
            onSuccess={onSuccessWrapperFn}
            onCancel={onCancelWrapperFn}
          />
        )}
        {type === ModalType.OK && (
          <OkActionSet
            actionStrings={mergedActionStrings}
            onSuccess={onSuccessWrapperFn}
          />
        )}
      </div>
    </div>
  );
};
