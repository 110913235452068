/* eslint-disable react/require-default-props */
import React from "react";
import { ColumnDefinitionType } from "./table.types";
import TableHeader from "./table-header";
import TableRows from "./table-row";
import "./table.scss";

/**
 * This functional component contains the table
 * component presented on the widget
 * @param {TableProps} props - Props values injected to the component.
 * @returns {JSX.Element}
 */

export type TableProps<T, K extends keyof T> = {
  items: Array<T>;
  columns: Array<ColumnDefinitionType<T, K>>;
  className?: string;
};

export const Table = <T, K extends keyof T>({
  items: data,
  columns,
  className,
}: TableProps<T, K>): JSX.Element => (
  <table className={`uw-table ${className || ""}`}>
    <TableHeader columns={columns} />
    <TableRows data={data} columns={columns} />
  </table>
);

export default Table;
