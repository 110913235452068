/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-useless-constructor */
/* eslint-disable @typescript-eslint/no-unused-vars */
export type ConnectOptions = {
  connectLaunchWaitTimeoutMs?: number;
  id?: string;
  containerId?: string;
  sdkLocation?: string;
  pollingTime?: number;
  maxActivityOutstanding?: number;
  extensionRequestTimeout?: number;
  minVersion?: string;
  dragDropEnabled?: boolean;
  connectMethod?: "http" | "extension";
};

export enum ConnectStatus {
  INITIALIZING = "INITIALIZING",
  FAILED = "FAILED",
  RETRYING = "RETRYING",
  OUTDATED = "OUTDATED",
  EXTENSION_INSTALL = "EXTENSION_INSTALL",
  RUNNING = "RUNNING",
}

export type AppId = {
  // eslint-disable-next-line camelcase
  app_id: string;
};

export default class Connect {
  public static STATUS: ConnectStatus;

  public static EVENT: {
    ALL: "all";
    STATUS: "status";
    TRANSFER: "transfer";
  };

  public static TRANSFER_STATUS: unknown;

  constructor(options: ConnectOptions) {}

  addEventListener = (...args: unknown[]): unknown => undefined;

  authenticate = (...args: unknown[]): unknown => undefined;

  getAllTransfers = (...args: unknown[]): unknown => undefined;

  getChecksum = (...args: unknown[]): unknown => undefined;

  getStatus = (...args: unknown[]): string => "";

  getTransfer = (...args: unknown[]): unknown => undefined;

  initSession = (...args: unknown[]): AppId | undefined => undefined;

  modifyTransfer = (...args: unknown[]): unknown => undefined;

  readAsArrayBuffer = (...args: unknown[]): unknown => undefined;

  readChunkAsArrayBuffer = (...args: unknown[]): unknown => undefined;

  removeEventListener = (...args: unknown[]): unknown => undefined;

  removeTransfer = (...args: unknown[]): unknown => undefined;

  resumeTransfer = (...args: unknown[]): unknown => undefined;

  setDragDropTargets = (...args: unknown[]): unknown => undefined;

  showAbout = (...args: unknown[]): unknown => undefined;

  showDirectory = (...args: unknown[]): unknown => undefined;

  showPreferences = (...args: unknown[]): unknown => undefined;

  showPreferencesPage = (...args: unknown[]): unknown => undefined;

  showSaveFileDialog = (...args: unknown[]): unknown => undefined;

  showSelectFileDialog = (...args: unknown[]): unknown => undefined;

  showSelectFileDialogPromise = (...args: unknown[]): unknown => undefined;

  showSelectFolderDialog = (...args: unknown[]): unknown => undefined;

  showSelectFolderDialogPromise = (...args: unknown[]): unknown => undefined;

  showTransferManager = (...args: unknown[]): unknown => undefined;

  showTransferMonitor = (...args: unknown[]): unknown => undefined;

  start = (...args: unknown[]): unknown => undefined;

  startTransfer = (...args: unknown[]): unknown => undefined;

  startTransferPromise = (...args: unknown[]): unknown => undefined;

  startTransfers = (...args: unknown[]): unknown => undefined;

  stop = (...args: unknown[]): unknown => undefined;

  stopTransfer = (...args: unknown[]): unknown => undefined;

  testSshPorts = (...args: unknown[]): unknown => undefined;

  version = (...args: unknown[]): unknown => undefined;
}
