import React, { useContext } from "react";
import { Menu } from "../../components/menu";
import { mainDMDCMenuItems, mainMenuItems } from "./main-menu.consts";
// import { ToastContext } from '../../components/toast/toaster';
import { View } from "../../components";
import "./main-menu.scoped.scss";
import { DigitalScreenersMediaManagerContext } from "../../app/digital-screeners-media-manager/digital-screeners-media-manager";

/**
 * This component will show the main menu view to allow the user to select
 * the action to perform inside the application:
 * - Create a new video
 * - Modify an existing video.
 * @returns {JSX.Element}
 */

export const MainMenuView = (): JSX.Element => {
  const uploadWidgetContext = useContext(DigitalScreenersMediaManagerContext);
  const { context } = uploadWidgetContext as {
    context: string;
  };

  return (
    <View>
      <div className="main-menu-view">
        <Menu items={context === "DMDC" ? mainDMDCMenuItems : mainMenuItems} />
      </div>
    </View>
  );
};

// const modalContext = useContext(ModalContext);
// const add = (): void => {
//   (modalContext as {actions: ModalContextActions}).actions.push({
//     type: ModalType.OK_CANCEL,
//     title: 'Modal test',
//     children: (<p>Hello!</p>),
//     onCancel: (key: string | undefined) => {
//       console.log('Cancelled', key);
//     },
//     onSuccess: (key: string | undefined) => {
//       console.log('Success', key);
//     },
//   });
// };
