import React, { useState, useRef } from "react";

import { Tag } from "./tag";
import { Tag as TagModel } from "./tag.types";
import "./tags-input.scoped.scss";

export type TagsInputProps = {
  name?: string;
  placeHolder?: string;
  values?: string[];
  onChange?: (t: string[], v: boolean, l: boolean, d: boolean) => void;
  validator?: (tag: string) => boolean;
  limit?: number;
  allowDuplicates?: boolean;
};

const separators = ["Enter", "Tab", "ArrowRight", "Spacebar", " "];

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
};

export const TagsInput = ({
  name,
  placeHolder,
  values,
  onChange,
  validator,
  limit,
  allowDuplicates = false,
}: TagsInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const setInputFocus = () => {
    inputRef?.current?.focus();
  };

  const validateTag = (value: string) => (validator ? validator(value) : true);

  const hasDuplicates = (value: string, tags: TagModel[]): boolean =>
    tags.filter((tag) => tag.value === value).length > 1;

  const findDuplicatedValues = (tags: TagModel[]): string[] => {
    const duplicatedTags = tags.filter((tag) => hasDuplicates(tag.value, tags));
    return Array.from(new Set(duplicatedTags.map((tag) => tag.value)));
  };

  const markLastDuplicated = (tags: TagModel[]) => {
    tags.forEach((tag) => {
      tag.isDuplicated = false;
    });
    tags.reverse();
    const duplicatedValues = findDuplicatedValues(tags);
    duplicatedValues.forEach((value) => {
      const tagToMark = tags.find((tag) => tag.value === value);
      if (tagToMark) {
        tagToMark.isDuplicated = true;
      }
    });
    tags.reverse();
    return tags;
  };

  const buildTag = (value: string): TagModel => ({
    value,
    isValid: validateTag(value),
    id: value + getRandomInt(10000, 99999).toString(),
    isDuplicated: false,
  });

  const mappedTags: TagModel[] = (values || []).map(buildTag);

  const [tags, setTags] = useState(mappedTags || []);

  const changeTags = (newTags: TagModel[]) => {
    if (onChange) {
      if (!allowDuplicates) {
        markLastDuplicated(newTags);
      }
      const hasDuplicates = findDuplicatedValues(newTags).length >= 1;

      const validTags = newTags
        .filter((tag) => tag.isValid && !tag.isDuplicated)
        .map((tag) => tag.value);

      const isValidInput = validTags.length === newTags.length;
      const isLimitExceeded = !!(
        limit &&
        limit > 0 &&
        validTags.length > limit
      );
      onChange(validTags, isValidInput, isLimitExceeded, hasDuplicates);
    }
    setTags(newTags);
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.value) {
      const newTag = buildTag(target.value);
      changeTags([...tags, newTag]);
      target.value = "";
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const target = e.target as HTMLInputElement;

    if (e.key === " ") {
      e.preventDefault();
    }

    if (e.key === "Backspace" && tags.length && !target.value) {
      target.value = "";
      changeTags([...tags.slice(0, -1)]);
    }

    if (separators.includes(e.key) && target.value) {
      const newTag = buildTag(target.value);
      changeTags([...tags, newTag]);
      target.value = "";
      e.preventDefault();
    }
  };

  const onTagRemove = (tag: TagModel) => {
    changeTags(tags.filter((t) => t.id !== tag.id));
  };

  return (
    <div
      aria-labelledby={name}
      className={"tags-input"}
      onClick={setInputFocus}
      role="presentation"
    >
      {tags.map((tag) => (
        <Tag key={tag.id} tag={tag} remove={onTagRemove} />
      ))}

      <input
        className={"add-tag-input"}
        type="text"
        name={name}
        placeholder={tags.length === 0 ? placeHolder : ""}
        onKeyDown={handleOnKeyDown}
        onBlur={handleOnBlur}
        ref={inputRef}
      />
    </div>
  );
};
