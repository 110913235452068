import { DropZoneFileDTO } from "../components/drop-zone";

export function deleteFromLanguageFilesArray(
  currentFiles: DropZoneFileDTO[],
  file: DropZoneFileDTO
): DropZoneFileDTO[] {
  const newLanguageFilesArray = currentFiles.filter(
    (languageFile: DropZoneFileDTO): boolean => languageFile.id !== file.id
  );
  // updateLanguageFilesStatus(newLanguageFilesArray);
  return newLanguageFilesArray;
}
