/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import { Popover } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Input, Select, Option, Button, Field } from "../form";
import filterIcon from "./assets/filter-icon.svg";
import "./filter-dialog.scss";

/**
 * This type describes the properties related with a menu component and
 * the expected format for each of them.
 */
export type FilterDialogProps = {
  onFilter?: (criteria: string, value: string) => void;
  getContainerRef?: () => HTMLElement;
};

export type FilterCriteriaType = {
  IS_EQUAL_TO: string;
  STARTS_WITH: string;
  CONTAINS: string;
  ENDS_WITH: string;
  USE_ALL_VALUES: string;
};

export type FilterCriteriaFnType = {
  IS_EQUAL_TO: boolean | ((v: string, q: string) => boolean);
  STARTS_WITH: boolean | ((v: string, q: string) => boolean);
  CONTAINS: boolean | ((v: string, q: string) => boolean);
  ENDS_WITH: boolean | ((v: string, q: string) => boolean);
  USE_ALL_VALUES: boolean | ((v: string, q: string) => boolean);
  CLEAR: () => true;
};

export const FilterCriteria: FilterCriteriaType = {
  IS_EQUAL_TO: "Is equal to",
  STARTS_WITH: "Starts with",
  CONTAINS: "Contains",
  ENDS_WITH: "Ends with",
  USE_ALL_VALUES: "Use all values",
};

export const FilterCriteriaFn: FilterCriteriaFnType = {
  IS_EQUAL_TO: (value: string, query: string) =>
    value?.toLocaleLowerCase() === query?.toLocaleLowerCase(),
  STARTS_WITH: (value: string, query: string) =>
    value?.toLocaleUpperCase().indexOf(query?.toLocaleUpperCase()) === 0,
  CONTAINS: (value: string, query: string) =>
    value?.toLocaleUpperCase().includes(query?.toLocaleUpperCase()),
  ENDS_WITH: (value: string, query: string) => {
    const revValue = value?.toLocaleLowerCase().split("").reverse().join("");
    const revQuery = query?.toLocaleLowerCase().split("").reverse().join("");
    if (typeof FilterCriteriaFn.STARTS_WITH === "function") {
      return FilterCriteriaFn.STARTS_WITH(revValue, revQuery);
    }
    return true;
  },
  USE_ALL_VALUES: (value: string, query: string) =>
    value?.toLocaleLowerCase().indexOf(query?.toLocaleLowerCase()) > -1,
  CLEAR: () => true,
};

/**
 * This functional component contains the Filter Dialog
 * component presented on the widget
 * @param {FilterDialogProps} props - Props values injected to the
 * component.
 * @returns {JSX.Element}
 */
export const FilterDialog = (props: FilterDialogProps): JSX.Element => {
  const { onFilter } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [criteria, setCriteria] = useState("");
  const [criteriaText, setCriteriaText] = useState("");
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isFilterBtnEnabled, setIsFilterBtnEnabled] = useState(false);
  const [hasFilterSelected, setHasFilterSelected] = useState(false);

  const onChange = (name: string, value: string): void => {
    setCriteriaText(name);
    setCriteria(value || name);
  };

  const onChangeValue = (v: string): void => {
    setQuery(v);
    if (v !== "") {
      setIsFilterBtnEnabled(true);
    }
  };

  const onFilterInternal = (clear = false): void => {
    setHasFilterSelected(!clear);
    if (onFilter) {
      onFilter(!clear ? criteria : "", !clear ? query : "");
    }
  };

  const clearFilter = (): void => {
    setQuery("");
    setCriteria("");
    setCriteriaText("");
    setIsFilterBtnEnabled(false);
    onFilterInternal(true);
  };

  const openDialog = (event: any): void => {
    setIsOpen(true);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    setAnchorEl(event.currentTarget);
  };

  const closeDialog = (): void => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (query === "" || criteria === "" || criteriaText === "") {
      setIsFilterBtnEnabled(false);
    } else {
      setIsFilterBtnEnabled(true);
    }
  }, [query, criteria]);

  return (
    <div>
      <button
        id="dialog-filter"
        type="button"
        style={{
          padding: "0px 4px",
          border: 0,
          background: hasFilterSelected ? "#4d4d4d" : "none",
          borderRadius: "4px",
          position: "relative",
        }}
        onClick={openDialog}
      >
        <img src={filterIcon} alt="" />
      </button>
      <Popover
        id="dialog-filter"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeDialog}
        PaperProps={{
          sx: {
            overflow: "visible",
          },
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="filter-dialog">
          <div className="filter-dialog__form-select-wrapper">
            <Field label="Value" fieldStyle="inline">
              <Select onOptionChange={onChange} value={criteriaText}>
                {Object.entries(FilterCriteria).map((option) => (
                  <Option
                    name={option[1]}
                    value={option[0]}
                    key={option[0]}
                    hideOnSelect
                  />
                ))}
              </Select>
            </Field>
          </div>
          <div className="filter-dialog__form-input-wrapper">
            <Input onChange={onChangeValue} value={query} />
          </div>
          <div className="filter-dialog__btns-wrapper">
            <Button
              className="filter-dialog__btn bordered"
              onClick={() => [clearFilter(), closeDialog()]}
              value="Clear"
            />
            <Button
              className="filter-dialog__btn"
              onClick={() => [onFilterInternal(), closeDialog()]}
              disabled={!isFilterBtnEnabled}
              value="Filter"
            />
          </div>
        </div>
      </Popover>
    </div>
  );
};
