import { MenuItemProps } from "../../components/menu-item";
import createPackageIcon from "./assets/create-package-icon.svg";
import modifyPackageIcon from "./assets/modify-package-icon.svg";

export const mainMenuItems: MenuItemProps[] = [
  {
    title: "Upload a new title",
    description: "Video and language components",
    icon: createPackageIcon,
    key: "upload-new-video",
    to: "/upload/new-package",
  },
  {
    title: "Add language components",
    description: "To an uploaded video",
    icon: modifyPackageIcon,
    key: "modify-existing-video",
    to: "/upload/update-package",
  },
];

export const mainDMDCMenuItems: MenuItemProps[] = [
  {
    title: "Upload a new package",
    description: "Video and language components",
    icon: createPackageIcon,
    key: "upload-new-video",
    to: "/upload/new-package",
  },
  {
    title: "Add or Replace files",
    description: "For an existing package",
    icon: modifyPackageIcon,
    key: "modify-existing-video",
    to: "/upload/update-package",
  },
];
