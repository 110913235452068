import React, { useRef, useState, useEffect, createContext } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { createReduxStore } from "../../store/redux";
import { AsperaWrapper } from "../../components/aspera-wrapper";
import { Toaster } from "../../components/toast/toaster";
import { ModalWrapper } from "../../components/modal";

import "./digital-screeners-media-manager.scoped.scss";
import { UploadAPIService } from "../../services/upload-api/upload-api.service";
import { TitleAPIFactory } from "../../services/title-api/title-api.factory";
import { TitleAPIService } from "../../services/title-api/title-api.types";

import { UploadWidget } from "../../widgets/upload/upload-widget";
import { StatusWidget } from "../../widgets/status/status-widget";
import { DSEventEmitter } from "../../types/eventEmitter";
import { UserActionFlags } from "../../types/auth-settings.types";

declare global {
  interface Window {
    uploadAPIAuth: string;
    titleAPIAuth: string;
    uploadAPIUrl: string;
    titleAPIUrl: string;
  }
}

export const DigitalScreenersMediaManagerContext = createContext<unknown>(null);

export type DigitalScreenersMediaManagerProps = {
  applicationId?: string;
  token: string;
  portal: string;
  context: string;
  eventEmitter: DSEventEmitter;
  userActionFlags: UserActionFlags;
};

/**
 * This functional component is the global wrapper for all the
 * child sections presented on the widget.
 * @returns {JSX.Element}
 */
export const DigitalScreenersMediaManager = (
  props: DigitalScreenersMediaManagerProps
): JSX.Element => {
  const {
    applicationId,
    portal,
    token: authToken,
    eventEmitter,
    context,
    userActionFlags,
  } = props;

  const [titleAPI, setTitleAPI] = useState<TitleAPIService>(
    TitleAPIFactory.getInstance(authToken, context, applicationId)
  );

  const [uploadAPI, setUploadAPI] = useState<UploadAPIService>(
    UploadAPIService.getInstance(authToken, portal, context)
  );

  useEffect(() => {
    if (window.uploadAPIAuth || window.uploadAPIUrl) {
      setUploadAPI(
        UploadAPIService.getInstance(
          window.uploadAPIAuth || authToken,
          portal,
          context
        )
      );
    }
    if (window.titleAPIAuth || window.titleAPIUrl) {
      setTitleAPI(
        TitleAPIFactory.getInstance(
          window.titleAPIAuth || authToken,
          context,
          applicationId
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    window.uploadAPIAuth,
    window.titleAPIAuth,
    window.uploadAPIUrl,
    window.titleAPIUrl,
  ]);

  const { current: asperaService } = useRef(window.AsperaService);
  /** Destructuring the AsperaService instance from the global scope. */
  // const asperaService: AsperaServiceInterface = window.AsperaService;

  const value = {
    authToken,
    portal,
    titleAPI,
    uploadAPI,
    asperaService,
    eventEmitter,
    context,
  };

  const canUploadMediaFilesForPackaging =
    (userActionFlags &&
      userActionFlags.canUploadMediaFilesForPackaging === true) ||
    false;

  const canViewUploadStatus =
    (userActionFlags && userActionFlags.canViewUploadStatus === true) || false;

  const getUploadDisplayElement = (): JSX.Element => {
    return canUploadMediaFilesForPackaging ? (
      <UploadWidget />
    ) : (
      getDefaultDisplayElement()
    );
  };

  const getStatusDisplayElement = (): JSX.Element => {
    return canUploadMediaFilesForPackaging || canViewUploadStatus ? (
      <StatusWidget token={authToken} portal={portal} context={context} />
    ) : (
      getDefaultDisplayElement()
    );
  };

  const getDefaultDisplayElement = (): JSX.Element => {
    if (canUploadMediaFilesForPackaging) {
      return <UploadWidget />;
    }

    if (canViewUploadStatus) {
      return (
        <StatusWidget token={authToken} portal={portal} context={context} />
      );
    }

    return <></>;
  };

  return (
    <React.StrictMode>
      <DigitalScreenersMediaManagerContext.Provider value={value}>
        <Provider store={createReduxStore()}>
          <div className="App UploadWidget">
            <ModalWrapper>
              <Toaster>
                <AsperaWrapper>
                  <Router>
                    {/* A <Switch> looks through its children <Route>s and
                     * renders the first one that matches the current URL. */}
                    <Routes>
                      <Route
                        path="/upload/*"
                        element={getUploadDisplayElement()}
                      />
                      <Route
                        path="/status/:packageId?/:show?"
                        element={getStatusDisplayElement()}
                      />
                      <Route path="/" element={getDefaultDisplayElement()} />
                    </Routes>
                  </Router>
                </AsperaWrapper>
              </Toaster>
            </ModalWrapper>
          </div>
        </Provider>
      </DigitalScreenersMediaManagerContext.Provider>
    </React.StrictMode>
  );
};
