import axios, { AxiosRequestConfig } from "axios";
import { Request, Response } from "../types/http-service.types";

export class HttpClient {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public baseUrl: string,
    public headers?: {
      [x: string]: string;
    }
  ) {}

  static generateQueryString = (queryObject: {
    [x: string]: string;
  }): string => {
    let queryValue = "";
    if (queryObject) {
      queryValue =
        Object.keys(queryObject).length === 0
          ? ""
          : Object.entries(queryObject)
              .map((entry) => `${entry[0]}=${entry[1]}`)
              .join("&");
    }
    return queryValue;
  };

  public post = async <T>(
    endpoint: string,
    payload?: Request,
    options?: AxiosRequestConfig
  ): Promise<Response> => {
    try {
      const queryValue = HttpClient.generateQueryString(
        payload?.query as { [x: string]: string }
      );

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { data, status } = await axios.post(
        `${this.baseUrl}${endpoint}${queryValue || ""}`,
        payload?.body,
        {
          ...(options || {}),
          headers: {
            ...(options ? options.headers : {}),
            ...this.headers,
          },
        }
      );

      return { status, data: data as T };
    } catch (exception: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return { exception };
    }
  };

  public put = async <T>(
    endpoint: string,
    payload?: Request,
    options?: AxiosRequestConfig
  ): Promise<Response> => {
    try {
      const queryValue = HttpClient.generateQueryString(
        payload?.query as { [x: string]: string }
      );

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { data, status } = await axios.put(
        `${this.baseUrl}${endpoint}${queryValue || ""}`,
        payload?.body,
        {
          ...(options || {}),
          headers: {
            ...(options ? options.headers : {}),
            ...this.headers,
          },
        }
      );

      return { status, data: data as T };
    } catch (exception: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return { exception };
    }
  };

  public get = async <T>(
    endpoint: string,
    payload?: Request,
    options?: AxiosRequestConfig
  ): Promise<Response> => {
    try {
      const queryValue = HttpClient.generateQueryString(
        payload?.query as { [x: string]: string }
      );

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { data, status } = await axios.get(
        `${this.baseUrl}${endpoint}${queryValue ? `?${queryValue}` : ""}`,
        {
          ...(options || {}),
          headers: {
            ...(options ? options.headers : {}),
            ...this.headers,
          },
        }
      );

      return { status, data: data as T };
    } catch (exception: any) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return { exception };
    }
  };
}
