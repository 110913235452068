import { TableItemDTO } from "./validateLanguageFiles";

export function updateLanguageFilesArray(
  currentFiles: TableItemDTO[],
  selectedFiles: TableItemDTO[]
): TableItemDTO[] {
  const mCurrentFiles = currentFiles || [];

  const idSet = new Set(mCurrentFiles.map((cf) => cf.id));

  const newLanguageFileArray: TableItemDTO[] = mCurrentFiles.slice();

  // updateLanguageFilesStatus([...mCurrentFiles, ...mSelectedFiles]);

  if (selectedFiles.length > 0) {
    selectedFiles.forEach((selectedFile: TableItemDTO): void => {
      if (!idSet.has(selectedFile.id)) {
        newLanguageFileArray.push(selectedFile);
      }
    });
  }

  return newLanguageFileArray;
}
