import { useState } from "react";

export type UseArrayHookReturn<T> = {
  array: T[];
  set: (v: T[]) => void;
  push: (v: T) => void;
  filter: (f: (el: T) => boolean) => void;
  update: (i: number, v: T) => void;
  remove: (i: number) => void;
  clear: () => void;
};

export function useArray<T>(defaultValue: T[]): UseArrayHookReturn<T> {
  const [array, setArray] = useState(defaultValue);

  const push = (element: T): void => {
    setArray((a) => [...a, element]);
  };

  const filter = (callback: (element: T) => boolean): void => {
    setArray((a) => a.filter(callback));
  };

  const update = (index: number, newElement: T): void => {
    setArray((a) => [
      ...a.slice(0, index),
      newElement,
      ...a.slice(index + 1, a.length),
    ]);
  };

  const remove = (index: number): void => {
    if (index !== null && index !== undefined && index >= 0) {
      setArray((a) => [...a.slice(0, index), ...a.slice(index + 1, a.length)]);
    }
  };

  const clear = (): void => {
    setArray([]);
  };

  return {
    array,
    set: setArray,
    push,
    filter,
    update,
    remove,
    clear,
  };
}
