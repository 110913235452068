// eslint-disable-next-line import/no-named-as-default
import DigitalScreenersMediaManagerWebComponent from "./DigitalScreenersMediaManager";

/**
 * Creates a new DigitalScreenersUploadWidget instance
 * @class
 */

export class DigitalScreenersUploadWidget extends DigitalScreenersMediaManagerWebComponent {}

export default DigitalScreenersMediaManagerWebComponent;
