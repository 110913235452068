import React from "react";
import "./info.scoped.scss";
import { AiFillWarning } from "react-icons/ai";

export type InfoType = "generic" | "error" | "warning" | "success";

export type InfoProps = {
  type?: InfoType;
  children: JSX.Element | JSX.Element[];
};

const InfoIcon = ({ type }: { type: InfoType | undefined }): JSX.Element => {
  switch (type) {
    case "error":
      return <AiFillWarning />;
    default:
      return <></>;
  }
};

export const Info = (props: InfoProps): JSX.Element => {
  const { children, type } = props;

  return (
    <div className={`info ${type as string}`}>
      <div className="info__icon">
        <InfoIcon type={type} />
      </div>{" "}
      {children}
    </div>
  );
};

Info.defaultProps = {
  type: "generic",
};
