import moment from "moment";

export const formattedDate = (
  ts?: number | string | Date,
  context?: string
): string => {
  if (context === "DMDC") {
    return ts ? moment.utc(ts).format("MM/DD/YYYY hh:mm A") : "";
  }

  return ts ? moment.utc(ts).local().format("MM/DD/YYYY hh:mm A") : "";
};
