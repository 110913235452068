import {
  FileType,
  FileNameParserInterface,
  NameGroups,
} from "../const/file-types";

export class DsFileNameParser implements FileNameParserInterface {
  public constructor(public readonly fileName: string) {}

  public getVersion(): number | undefined {
    const thirdSegment = this.fileName?.split("_")?.[2] || "";
    const versionRegex = /V(?<versionId>[0-9]+)/i;
    const versionId = versionRegex.exec(thirdSegment)?.groups?.versionId;
    return versionId ? parseInt(versionId) : undefined;
  }

  public getLanguageCode(): string {
    const languageCode = this.fileName
      ?.split(".")
      ?.reverse()?.[1]
      ?.split("_")
      ?.reverse()?.[0]
      ?.toLocaleUpperCase();
    return languageCode;
  }

  public getFileDetails(): NameGroups | undefined {
    return undefined;
  }

  public validateComponentFileName(): boolean {
    let isValid = true;

    const rules = [
      {
        regex: /^.*\.(?:mp4|mov|ttml|vtt|wav)$/i,
        description: "Not valid file extension.",
      },
      {
        regex: /^[a-zA-Z0-9\-_.]+$/g,
        description: "Not special characters nor blank spaces.",
      },
      {
        regex: /_([A-Z0-9]{3})(\.[a-zA-Z0-9]{3,})$/,
        description:
          "Language code should be placed just before the extension separator.",
      },
      {
        regex: /.(?:video|audio|subtitle|forced|dubcard)_/i,
        description: "Missing file type at filename.",
      },
    ];

    rules.forEach((rule) => {
      if (!rule.regex.test(this.fileName)) {
        isValid = false;
      }
    });

    return isValid;
  }

  public getFileType(): FileType {
    const types = ["dubcard", "subtitle", "forced", "audio"];
    const type = types.filter((t: string) => {
      const typeRegExp = new RegExp(`[^a-zA-Z]+${t}+[^a-zA-Z]`);
      return typeRegExp.test(this.fileName.toLocaleLowerCase());
    });

    if (!type.length && this.validateVideoFileName()) {
      return FileType.VIDEO;
    }
    return type.length
      ? ((type[0].charAt(0).toLocaleUpperCase() + type[0].slice(1)) as FileType)
      : FileType.UNKNOWN;
  }
  public validateVideoFileName(): boolean {
    let isValid = true;

    const rules = [
      {
        regex: /^.*\.(?:avi|mpeg|mxf|ogv|webm|ts|3gp|3g2|mp4|mov|wav)$/i,
        description: "Not valid file extension.",
      },
      {
        regex: /^[a-zA-Z0-9\-_.]+$/g,
        description: "Not special characters nor blank spaces.",
      },
      {
        regex: /^(?!.*\..*\.).*$/,
        description: "Only one dot as extension separator.",
      },
      {
        regex: /_[A-Z0-9]{3}\..{3,}$/,
        description:
          "Language code should be placed just before the extension separator.",
      },
    ];

    rules.forEach((rule) => {
      if (!rule.regex.test(this.fileName)) {
        isValid = false;
      }
    });

    return isValid;
  }
}
