import React, { useEffect, useState, useContext } from "react";
import { Autocomplete } from "../../../components/autocomplete";

import { Field } from "../../../components/form";
import { DsTitleAPIService } from "../../../services/title-api/ds-title-api.service";
import { useArray } from "../../../hooks";
import {
  ChildEntity,
  SearchResponse,
  GetTitleDetailsResponse,
  TitleDetails,
  TitleVersion,
  TitleParentTypes,
} from "../../../services/title-api/title-api.types";
import { Metadata } from "../../../services/upload-api/upload-api.types";
import {
  ToastContext,
  ToastContextDef,
  ToastType,
} from "../../../components/toast";
import {
  EpisodeField,
  SeasonField,
  SecurityField,
  VersionField,
} from "../form";
import { extractSeasonNumber } from "../../../utils";

export type DsMetadataSectionProps = {
  onMetadataChanges: (meta: Metadata | undefined) => void;
  titleAPI: DsTitleAPIService;
};

export const DsMetadataSection = (
  props: DsMetadataSectionProps
): JSX.Element => {
  const { onMetadataChanges, titleAPI } = props;

  const [metadata, setMetadata] = useState<Metadata>({
    title: undefined,
    season: undefined,
    episode: undefined,
    version: undefined,
    security: undefined,
  });

  const toasterContext = useContext(ToastContext);
  const [searchValue, setSearchValue] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
  const [debouncer, setDebouncer] = useState<any>(null);
  const [loadingTitles, setLoadingTitles] = useState<boolean>(false);
  const [loadingTitleChildren, setLoadingTitleChildren] =
    useState<boolean>(false);
  const [loadingSeasonEpisodes, setLoadingSeasonEpisodes] =
    useState<boolean>(false);
  const [loadingTitleVersion, setLoadingTitleVersion] =
    useState<boolean>(false);
  const suggestions = useArray<ChildEntity>([]);

  const [selectedTitle, setSelectedTitle] = useState<ChildEntity>();

  const seasons = useArray<ChildEntity>([]);
  const [selectedSeason, setSelectedSeason] = useState<ChildEntity | null>();

  const episodes = useArray<TitleDetails>([]);
  const [selectedEpisode, setSelectedEpisode] = useState<ChildEntity | null>();

  const versions = useArray<TitleVersion>([]);
  const [versionedProduct, setVersionedProduct] =
    useState<ChildEntity | null>();
  const [selectedVersion, setSelectedVersion] = useState<TitleVersion | null>();

  const [selectedSecurity, setSelectedSecurity] = useState<string | undefined>(
    "DRM & Watermarking"
  );

  const searchTitle = async (/* searchString: string */): Promise<void> => {
    setLoadingTitles(true);
    try {
      const v: SearchResponse = await titleAPI.searchTitle({
        body: {
          searchText: searchValue,
          typesToReturn: TitleParentTypes,
        },
      });
      if (v.exception) {
        throw new Error("Request error");
      }
      if (v && v.data && v.data?.results?.length > 0) {
        suggestions.set(v.data.results.slice(0, 20));
      }
    } catch (error: any) {
      const duration = 6000;
      (toasterContext as ToastContextDef).actions.push({
        duration,
        closeable: true,
        type: ToastType.ERROR,
      });
    } finally {
      setLoadingTitles(false);
    }
  };

  const getAutoCompleteItemDisplayValue = (
    title: ChildEntity | undefined
  ): string => `${title?.name || ""} - ${title?.productId.toString() || ""}`;

  const getSeasonName = (childEntity: ChildEntity): string => {
    return `Season ${extractSeasonNumber(childEntity.name)}`;
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    clearTimeout(debouncer);
    seasons.clear();
    episodes.clear();
    versions.clear();
    setSelectedSeason(undefined);
    setSelectedEpisode(undefined);
    setSelectedVersion(undefined);
    setDebouncer(
      setTimeout(() => {
        if (
          searchValue !== "" &&
          searchValue !== getAutoCompleteItemDisplayValue(selectedTitle)
        ) {
          void searchTitle();
        }
      }, 1000)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const onChangeSearchQuery = (value: string): void => {
    setSearchValue(value);
  };

  /** *********************************************************************** */
  const getSeasonEpisodes = async (childId: number): Promise<void> => {
    setLoadingSeasonEpisodes(true);
    try {
      const episodesList = await titleAPI.getTitleChildren(childId);
      if (episodesList.exception) {
        throw new Error("Request error");
      }
      // eslint-disable-next-line max-len
      const detailsRequests =
        episodesList?.data?.children?.map((child: ChildEntity) =>
          titleAPI.getTitleDetails(child.productId)
        ) || [];

      const episodeDetails = (await Promise.all(detailsRequests)) || [];
      episodes.set(
        // eslint-disable-next-line max-len
        episodeDetails.map(
          (episodeResponse: GetTitleDetailsResponse): TitleDetails =>
            episodeResponse.data as TitleDetails
        ) || []
      );
    } catch (error: any) {
      const duration = 6000;
      (toasterContext as ToastContextDef).actions.push({
        duration,
        closeable: true,
        type: ToastType.ERROR,
      });
    } finally {
      setLoadingSeasonEpisodes(false);
    }
  };

  useEffect(() => {
    if (selectedSeason) {
      void getSeasonEpisodes(selectedSeason.productId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeason]);

  const onSeasonSelectChange = (name: string, value: ChildEntity): void => {
    if (value?.name !== selectedSeason?.name) {
      episodes.clear();
      versions.clear();
      setSelectedEpisode(undefined);
      setSelectedVersion(undefined);
      setSelectedSeason(value);
    }
  };

  /** *********************************************************************** */

  const getDetailsAndChildren = async (): Promise<void> => {
    if (selectedTitle) {
      const { productId, type } = selectedTitle;
      if (productId) {
        switch (type) {
          case "series":
            setLoadingTitleChildren(true);
            try {
              const children = await titleAPI.getTitleChildren(productId);
              seasons.set(children.data?.children || []);
            } catch (error: any) {
              const duration = 6000;
              (toasterContext as ToastContextDef).actions.push({
                duration,
                closeable: true,
                type: ToastType.ERROR,
              });
            } finally {
              setLoadingTitleChildren(false);
            }
            break;
          case "miniseries":
            await getSeasonEpisodes(productId);
            break;
          default:
            // Get Version
            setVersionedProduct(selectedTitle);
        }
      }
      setLoadingTitleChildren(false);
    }
  };

  useEffect(() => {
    void getDetailsAndChildren();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTitle]);

  const onTitleSelected = (title: ChildEntity): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    clearTimeout(debouncer);
    setSelectedTitle(title);
  };

  /** *********************************************************************** */

  useEffect(() => {
    if (selectedEpisode) {
      setVersionedProduct(selectedEpisode);
    }
  }, [selectedEpisode]);

  const onEpisodeSelectChange = (name: string, value: ChildEntity): void => {
    versions.clear();
    setSelectedVersion(undefined);
    setSelectedEpisode(value);
    setVersionedProduct(undefined);
  };

  /** *********************************************************************** */

  const getVersionForSelectedTitle = async (): Promise<void> => {
    setLoadingTitleVersion(true);
    try {
      const titleVersions = await titleAPI.getTitleVersions(
        versionedProduct?.productId
      );
      if (titleVersions.exception) {
        throw new Error("Request error");
      }
      versions.set(titleVersions?.data?.versions || []);
    } catch (error: any) {
      const duration = 6000;
      (toasterContext as ToastContextDef).actions.push({
        duration,
        closeable: true,
        type: ToastType.ERROR,
      });
    } finally {
      setLoadingTitleVersion(false);
    }
  };

  useEffect(() => {
    if (versionedProduct) {
      void getVersionForSelectedTitle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionedProduct]);

  const onVersionSelected = (name: string, value: TitleVersion): void => {
    setSelectedVersion(value);
  };

  /** *********************************************************************** */

  const onSecuritySelected = (name: string, value: string): void => {
    setSelectedSecurity(value);
  };

  /** *********************************************************************** */

  const isValidMetadata = (): boolean => {
    if (metadata.title === undefined) return false;
    if (
      metadata.title.type === "series" &&
      metadata.season === undefined &&
      metadata.episode === undefined
    )
      return false;
    if (metadata.version === undefined) return false;
    if (metadata.security === undefined) return false;
    return true;
  };

  /** *********************************************************************** */

  useEffect(() => {
    if (onMetadataChanges) {
      onMetadataChanges(isValidMetadata() ? metadata : undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata]);

  useEffect(() => {
    setMetadata(
      (current: Metadata): Metadata => ({
        ...current,
        title: selectedTitle,
        season: selectedSeason || undefined,
        episode: selectedEpisode || undefined,
        version: selectedVersion || undefined,
        security: selectedSecurity || undefined,
      })
    );
  }, [
    selectedEpisode,
    selectedSeason,
    selectedSecurity,
    selectedTitle,
    selectedVersion,
  ]);

  return (
    <div className="uw-row uw-col">
      <div className="uw-row">
        <Field label="Title*">
          <Autocomplete
            loading={loadingTitles}
            onChange={onChangeSearchQuery}
            onSelect={onTitleSelected}
            suggestions={suggestions.array}
            stringMapper={(item: ChildEntity) =>
              getAutoCompleteItemDisplayValue(item)
            }
          />
        </Field>
      </div>
      <div className="uw-row">
        <div className="uw-col">
          <SeasonField
            disabled={seasons.array.length === 0}
            loading={loadingTitleChildren}
            onSeasonSelectChange={onSeasonSelectChange}
            seasons={seasons.array}
            getSeasonName={getSeasonName}
            required={true}
          ></SeasonField>
        </div>
        <div className="uw-col">
          <EpisodeField
            disabled={episodes.array.length === 0}
            loading={loadingSeasonEpisodes}
            onEpisodeSelectChange={onEpisodeSelectChange}
            episodes={episodes.array}
            required={true}
          ></EpisodeField>
        </div>
      </div>
      <div className="uw-row">
        <div className="uw-col">
          <VersionField
            disabled={versions.array.length === 0}
            loading={loadingTitleVersion}
            onVersionSelected={onVersionSelected}
            versions={versions.array}
            required={true}
          ></VersionField>
        </div>
        <div className="uw-col">
          <SecurityField
            selectedSecurity={selectedSecurity}
            onSecuritySelected={onSecuritySelected}
          ></SecurityField>
        </div>
      </div>
    </div>
  );
};
