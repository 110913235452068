/* eslint-disable react/require-default-props */
import React, { ReactNode } from "react";
import { useToggle } from "../../hooks";
import "./read-more.scss";
import InfoImg from "./info.svg";

/**
 * This type describes the properties related with a menu component and
 * the expected format for each of them.
 */
export type ReadMoreProps = {
  shortMessage: string;
  fullMessage: string | ReactNode;
};

/**
 * This functional component contains the read more
 * component presented on the widget
 * @param {ReadMoreProps} props - Props values injected to the component.
 * @returns {JSX.Element}
 */
export const ReadMore = (props: ReadMoreProps): JSX.Element => {
  const { shortMessage, fullMessage } = props;
  const [expanded, toggle] = useToggle(false);
  return (
    <div className="read-more">
      <div className="read-more__content">
        <img src={InfoImg} alt="info-icon" className="read-more__icon" />
        <span className="read-more__message">{shortMessage}</span>
        <span>
          {!expanded && (
            <button
              type="button"
              className="read-more__btn"
              onClick={() => toggle()}
            >
              read more
            </button>
          )}
        </span>
      </div>
      {expanded && (
        <div className="read-more__full-message">
          {fullMessage}
          <button
            type="button"
            className="read-more__btn"
            onClick={() => toggle()}
          >
            read less
          </button>
        </div>
      )}
    </div>
  );
};
