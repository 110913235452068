/* eslint-disable max-classes-per-file */
import { HttpClient } from "../../core/classes/HttpClient";
import {
  CreatePackageRequest,
  CreatePackageResponse,
  UploadAPIServiceInterface,
  SearchPackageResponse,
  UpdatePackageRequest,
  UpdatePackageResponse,
  GetNewPackageIdResponse,
  SearchPackageRequest,
  MapDmdcContextToDsPortalResponse,
} from "./upload-api.types";
import { UPLOAD_API_BASE_URI } from "./upload-api.const";

export class UploadAPIService implements UploadAPIServiceInterface {
  private static instance: UploadAPIService;

  token: string | undefined;

  client!: HttpClient;

  private constructor(
    token: string | undefined,
    portal: string | undefined,
    context: string
  ) {
    this.token = token;
    this.client = new HttpClient(
      window.uploadAPIUrl || UPLOAD_API_BASE_URI || "",
      {
        Authorization: this.token || "",
        "x-ds-portal-id": portal || "",
        "x-client-id": context || "DS",
      }
    );
  }

  public static getInstance(
    token: string | undefined,
    portal: string | undefined,
    context: string
  ): UploadAPIService {
    UploadAPIService.instance = new UploadAPIService(token, portal, context);

    return UploadAPIService.instance;
  }

  public createPackage = async (
    request: CreatePackageRequest
  ): Promise<CreatePackageResponse> => {
    const response = await this.client.post("/create-package", request);
    return response;
  };

  public searchPackage = async (
    request: SearchPackageRequest
  ): Promise<SearchPackageResponse> => {
    const response = await this.client.get("/search", request);
    return response;
  };

  public updatePackage = async (
    request: UpdatePackageRequest
  ): Promise<UpdatePackageResponse> => {
    const response = await this.client.put("/update-package", request);
    return response;
  };

  public getNewPackageId = async (
    portal: string
  ): Promise<GetNewPackageIdResponse> => {
    const response = await this.client.post("/create-package-id", {
      body: {
        portal,
        mail_id: "empty@domain.com",
      },
    });
    return response;
  };

  public mapDmdcContextToDsPortal = async (
    dmdcContext: string
  ): Promise<MapDmdcContextToDsPortalResponse> => {
    const response = await this.client.post("/map-dmdc-context-to-ds-portal", {
      body: {
        dmdcContext,
      },
    });
    return response;
  };
}
