import React, { ReactNode, useContext } from "react";
import { DigitalScreenersMediaManagerContext } from "../../app/digital-screeners-media-manager/digital-screeners-media-manager";
import "./view.scoped.scss";

export const View = (props: { children: ReactNode }): JSX.Element => {
  const uploadWidgetContext = useContext(DigitalScreenersMediaManagerContext);
  const { context } = uploadWidgetContext as {
    context: string;
  };
  const { children } = props;
  return (
    <div className={`view ${context?.toLocaleLowerCase() || ""}`}>
      {children}
    </div>
  );
};
