export type DateRange = {
  from: Date | null;
  to: Date | null;
};
export type ComputedDateRange = () => DateRange;

const endOfTheDay = new Date();
endOfTheDay.setHours(23, 59, 59);

export const LastWeek: ComputedDateRange = () => ({
  from: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
  to: endOfTheDay,
});

export const LastMonth: ComputedDateRange = () => ({
  from: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
  to: endOfTheDay,
});

export const Last90Days: ComputedDateRange = () => ({
  from: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000),
  to: endOfTheDay,
});

export const DateRangeNull: ComputedDateRange = () => ({
  from: null,
  to: null,
});

export type DateFilterOptionsType = {
  lastWeek: ComputedDateRange;
  lastMonth: ComputedDateRange;
  last90Days: ComputedDateRange;
  customRange: DateRange;
};

export const DateFilterOptions: DateFilterOptionsType = {
  lastWeek: LastWeek,
  lastMonth: LastMonth,
  last90Days: Last90Days,
  customRange: DateRangeNull(),
};

export type DateRangeChangeCallback = (dateRange: DateRange) => void;
