import { Tooltip } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { isTextOverflowed } from "../../utils";
import "./overflowable-text.scoped.scss";

/**
 * This type describes the properties related with a menu component and
 * the expected format for each of them.
 */
export type OverflowableTextProps = {
  text: string | undefined;
};

/**
 * This functional component contains the read more
 * component presented on the widget
 * @param {OverflowableTextProps} props - Props values injected to the component
 * @returns {JSX.Element}
 */
export const OverflowableText = (props: OverflowableTextProps): JSX.Element => {
  const { text } = props;
  const spanText = useRef<HTMLDivElement>(null);
  const [isOverflowed, setIsOverflowed] = useState(false);

  const checkIfTextOverflowed = (): void => {
    setIsOverflowed(isTextOverflowed(spanText.current));
  };

  useEffect(() => {
    if (spanText && spanText.current) {
      checkIfTextOverflowed();
      const resizeObserver = new ResizeObserver(() => checkIfTextOverflowed());
      resizeObserver.observe(spanText.current);
    }
  }, []);

  return (
    <div>
      <Tooltip
        title={isOverflowed ? text ?? "" : ""}
        placement="top"
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: "500px",
              whiteSpace: "break-spaces",
            },
          },
        }}
        PopperProps={{
          disablePortal: true,
          popperOptions: {
            strategy: "fixed",
            modifiers: [
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  rootBoundary: "window",
                },
              },
            ],
          },
        }}
      >
        <div className="overflowable-text" ref={spanText}>
          {text ?? ""}
        </div>
      </Tooltip>
    </div>
  );
};
