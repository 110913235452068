import React from "react";
import { Field, Select, Option } from "../../../components/form";
import {
  ChildEntity,
  TitleDetails,
} from "../../../services/title-api/title-api.types";

export type EpisodeFieldProps = {
  onEpisodeSelectChange: (name: string, value: ChildEntity) => void;
  value?: string;
  episodes: TitleDetails[];
  disabled: boolean;
  loading: boolean;
  required: boolean;
};

const getEpisodeValue = (episode: TitleDetails): string => {
  const result = [];
  if (episode.seasonEpisodeNumber) {
    result.push(episode.seasonEpisodeNumber);
  }
  if (episode.name && episode.name.length) {
    result.push(episode.name);
  }
  if (episode.earliestReleaseDate) {
    result.push(episode.earliestReleaseDate.split("-").shift());
  }
  if (episode.productId) {
    result.push(episode.productId);
  }
  return result.join(" - ");
};

export const EpisodeField = (props: EpisodeFieldProps) => {
  const {
    disabled,
    loading,
    episodes,
    onEpisodeSelectChange,
    value,
    required,
  } = props;
  return (
    <Field disabled={disabled} label={"Episode" + (required ? "*" : "")}>
      <Select
        value={value}
        loading={loading}
        onOptionChange={onEpisodeSelectChange}
      >
        {episodes.map((episode) => {
          const episodeName = getEpisodeValue(episode);
          return episodeName.length ? (
            <Option
              name={episodeName}
              value={episode}
              key={episode.productId}
              hideOnSelect
            />
          ) : null;
        })}
      </Select>
    </Field>
  );
};
