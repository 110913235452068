/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-misused-new */
/* eslint-disable camelcase */
import { BehaviorSubject } from "rxjs";
import Connect, {
  AppId,
  ConnectOptions,
  ConnectStatus,
} from "./classes/Connect";
import ConnectInstaller, {
  ConnectInstallerOptions,
} from "./classes/ConnectInstaller";

export * from "./classes";

export type File = {
  lastModifiedDate: string;
  name: string;
  size: number;
  type: string;
};

export type DataTransfer = {
  files: File[];
};

export type Files = {
  dataTransfer: DataTransfer;
};

export type AsperaEvent = {
  event: Event;
  files: Files;
};

export enum FileFormats {
  MP4 = "mp4",
}

export interface FileFilter {
  filter_name: string;
  extensions: Array<FileFormats | string>;
}

export const FileFilters: { [key: string]: FileFilter } = {
  VIDEO_MP4: {
    filter_name: "Video type MP4",
    extensions: ["mp4"],
  },
};

export type Path = {
  source: string;
};

export type TransferSpec = {
  paths: Path[];
  destination_root: string;
  token: string;
  direction: string;
  target_rate_cap_kbps: 1200000;
  cipher: string;
  tags: { [x: string]: unknown };
  authentication: string;
  rate_policy_allowed: string;
  rate_policy: string;
  target_rate_kbps: number;
  min_rate_cap_kbps: number;
  min_rate_kbps: number;
  lock_min_rate: boolean;
  remote_host: string;
  https_fallback_port: string;
  remote_user: string;
  sshfp: unknown;
  fasp_port: number;
  ssh_port: number;
  http_fallback: boolean;
};

export type Transfer = {
  transfer_spec: TransferSpec;
};

export type TransfersObject = {
  transfers: Transfer[];
};

export type TransferStatus =
  | {
      transfer: Transfer;
      status: string;
    }
  | undefined;

export type AsperaServiceOptions = {
  config: ConnectOptions;
  installerConfig?: ConnectInstallerOptions;
  installationHandler?: () => void;
  connectInstance?: Connect;
  connectInstallerInstance?: ConnectInstaller;
};

export type AsperaServiceEvents = {
  ready: BehaviorSubject<boolean>;
};
export interface AsperaServiceInterface {
  /**
   * @property {Connect} Connect - an instance of the Aspera Connect
   * class.
   */
  ConnectInstance: Connect | undefined;

  /**
   * @property {ConnectInstaller} ConnectInstaller - an instance of the
   * Aspera ConnectInstaller class.
   */
  ConnectInstallerInstance: ConnectInstaller | undefined;

  /**
   * @property {any} appId - Value generated by the Aspera session.
   */
  appId: AppId | undefined;

  /**
   * @property {AsperaServiceEvents} events - Event emitters used by the class
   * when an specific event happens inside the lifecycle of the class.
   */
  events: AsperaServiceEvents;

  /**
   * @property {BehaviorSubject<ConnectStatus>} statusEventEmitter - Status
   * event emitter.
   */
  statusEventEmitter: BehaviorSubject<ConnectStatus>;

  /**
   * @property {BehaviorSubject<TransferStatus>} transferEventEmitter - Transfer
   * status event emitter.
   */
  transferEventEmitter: BehaviorSubject<TransferStatus>;
}

/**
 * @description Declare the AW4 property on the global object window, so it can
 * be accessed without lint or compilation errors.
 */
declare global {
  interface Window {
    AW4?: {
      Connect:
        | {
            new (options: ConnectOptions): Connect;
            STATUS: ConnectStatus;
            EVENT: {
              ALL: "all";
              STATUS: "status";
              TRANSFER: "transfer";
            };
            TRANSFER_STATUS: string;
          } & Connect;
      ConnectInstaller:
        | {
            new (options: ConnectInstallerOptions): ConnectInstaller;
          } & ConnectInstaller;
    };
    AsperaService?: AsperaServiceInterface;
  }
}
