/* eslint-disable max-len */
import React, { useContext } from "react";
import { DigitalScreenersMediaManagerContext } from "../../app/digital-screeners-media-manager/digital-screeners-media-manager";

const { REACT_APP_LANG_FILE_SPECS_URL, REACT_APP_DMDC_FILE_SPECS_URL } =
  process.env;
const ASPERA_CLIENT_URL = "https://www.ibm.com/aspera/connect/";

export const FullMessageLanguage = (): JSX.Element => {
  const handleLanguageClick = (): void => {
    if (window && window.langCodes) {
      window.langCodes();
    }
  };

  const uploadWidgetContext = useContext(DigitalScreenersMediaManagerContext);
  const { context } = uploadWidgetContext as {
    context: string;
  };

  return (
    <>
      {context == "DMDC" ? (
        <>
          <ul>
            <li>
              You will need to download the&nbsp;
              <a
                href={ASPERA_CLIENT_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Aspera Client
              </a>
              &nbsp;on your computer
            </li>
            <li>
              To take advantage of <strong>asset auto-tagging</strong> based
              upon file naming, please use the Enterprise naming
              convention&nbsp;
              <a
                href="https://mediatechspecs.disney.com/#/production/naming-convention/naming-convention"
                target="_blank"
                rel="noopener noreferrer"
              >
                Enterprise naming convention
              </a>
              <ul>
                <li>
                  Enterprise file naming examples to provide for optimal asset
                  Auto-Tagging based upon filename (subtitle examples here):
                </li>
              </ul>
              <ol>
                <ul>
                  <li>
                    GreysAnatomy-E0387_ORIG_V30422522_FP_DPO_<b>IMSC</b>
                    _23_ENG-178_<b>ENG-FULL</b>.ttml
                  </li>
                  <li>
                    GreysAnatomy-E0387_ORIG_V30422522_FP_DPO_<b>IMSC</b>
                    _23_ENG-178_<b>ENG-FRCD</b>.ttml
                  </li>
                </ul>
              </ol>
            </li>
            <li>
              Replacement files: use the exact same file name and extension for
              replacing a language component file
            </li>
          </ul>
          <br />
          Click here for&nbsp;
          <a
            href={REACT_APP_DMDC_FILE_SPECS_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Language Codes
          </a>
          <p>Audio containers: WAV, MP4, MOV</p>
          <p>Subtitle containers: TTML, VTT</p>
          <p>Forced subtitle containers: TTML, VTT</p>
          <p>Dub card containers: MP4, MOV</p>
          <p>
            Click here for&nbsp;
            <a
              href={REACT_APP_DMDC_FILE_SPECS_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Language File Specifications
            </a>
          </p>
          <br />
          <br />
        </>
      ) : (
        <>
          <b>For Language / Component Files</b>
          <br />
          <br />
          <ul>
            <li>
              File naming format:
              &lt;TITLENAME&gt;_&lt;TYPE&gt;_&lt;LANGUAGE&gt;.&lt;file
              extension&gt;
            </li>
            <li>
              No spaces or special characters in the file name are allowed, only
              period, underscore and dash are allowed
            </li>
            <li>Supported special characters: - (dash), _ (underscore)</li>
            <li>File name should contain 3 letter language code</li>
            <li>
              Examples:
              <ul>
                <li>Video File: DS_WWOMICKEYMOUSE_102-F002_1080P_ENG.MP4</li>
                <li>
                  Audio File: DS_WWOMICKEYMOUSE_102-F002_1080P_AUDIO_ENG.mp4
                </li>
              </ul>
            </li>
            <li>
              Use exact same file name and extension for replacing a language
              component
            </li>
            <ol>
              <li>Audio</li>
              <li>Subtitle</li>
              <li>Dubcard</li>
              <li>Forced</li>
            </ol>
          </ul>
          <br />
          <b>Language</b>- Click here for &nbsp;
          <button type="button" onClick={handleLanguageClick}>
            Language Codes
          </button>
          <p>Audio containers: MP4, MOV</p>
          <p>Subtitle containers: TTML, VTT</p>
          <p>Forced subtitle containers: TTML, VTT</p>
          <p>Dub card containers: MP4, MOV</p>
          <p>
            Click here for &nbsp;
            <a
              href={REACT_APP_LANG_FILE_SPECS_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              Language File Specifications
            </a>
          </p>
          <br />
          <p>Example : Cruella_AUDIO_ENG.mp4</p>
          <br />
        </>
      )}
    </>
  );
};
