/* eslint-disable max-len */
export const ASPERA_CDN_URL =
  "https://d3gcli72yxqn2z.cloudfront.net/connect/v4/asperaweb-4.min.js";
export const CONNECT_INSTALLER = "//d3gcli72yxqn2z.cloudfront.net/connect/v4";
// TODO: create a techdebt to investigate how to improve this timeout.
export const EXTENSION_TIMEOUT = 3000;

export const CUSTOM_EVENT_NAMES = {
  ASPERA: {
    STATUS_CHANGE: "mcx-event::aspera-status-change",
  },
  ASPERA_UPLOAD: {
    FILE_UPLOAD_COMPLETED: "mcx-event::aspera-upload-file-upload-completed",
    FILE_UPLOAD_FAILED: "mcx-event::aspera-upload-file-upload-failed",
    FILE_UPLOAD_PROGRESS: "mcx-event::aspera-upload-file-upload-progress",
    TRANSFER_COMPLETED: "mcx-event::aspera-upload-transfer-completed",
  },
  HTTP_UPLOAD: {
    FILES_ADDED: "mcx-event::http-upload-files-added",
    FILE_UPLOAD_COMPLETED: "mcx-event::http-upload-file-upload-completed",
    FILE_UPLOAD_FAILED: "mcx-event::http-upload-file-upload-failed",
    FILE_UPLOAD_PAUSED: "mcx-event::http-upload-file-upload-paused",
    FILE_UPLOAD_PROGRESS: "mcx-event::http-upload-file-upload-progress",
  },
  NOTIFICATION: {
    COMMON: {
      /** Specify 'title' and 'message' in CustomEvent detail */
      FAILED: "mcx-notification-event::common-failed",
      /** Specify 'title' and 'message' in CustomEvent detail */
      SUCCESS: "mcx-notification-event::common-success",
      /** Specify 'title' and 'message' in CustomEvent detail */
      WARNING: "mcx-notification-event::common-warning",
    },
  },
};

export const FILE_EXTENSION_MAPPING = [
  {
    extensions: /^.*\.(doc|docx|abw|azw|odt|txt|pdf)$/i,
    icon: "file-word",
    iconLarge: "file-word-lg",
  },
  {
    extensions: /^.*\.(avi|mpeg|mxf|ogv|webm|ts|3gp|3g2|mp4|mov)$/i,
    icon: "file-video", // movie
    iconLarge: "file-video-lg",
  },
  {
    extensions: /^.*\.(bmp|gif|ico|jpeg|jpg|png|svg|tif|tiff|webp)$/i,
    icon: "file-image",
    iconLarge: "file-image-lg",
  },
  {
    extensions: /^.*\.(aac|mid|midi|mp3|oga|opus|wav|weba|3gp|3g2)$/i,
    icon: "file-audio",
    iconLarge: "file-audio-lg",
  },
  {
    extensions: /^.*\.(bz|bz2|epub|gz|zip|7z|rar)$/i,
    icon: "file-zip",
    iconLarge: "file-zip-lg",
  },
  {
    extensions: /^.*\.(xls|xlsx|csv|css|ods)$/i,
    icon: "file-excel",
    iconLarge: "file-excel-lg",
  },
];

export const TRANSFER_DIRECTION = {
  RECEIVE: "receive", // download
  SEND: "send", // upload
};
