import { DSPackage } from "@screeners/upload-datamodel";
import React, { ReactElement, useContext } from "react";
import { OverflowableText } from "../../components/overflowable-text";
import { formattedDate } from "../../utils";
import "./package-item.scoped.scss";
import { DigitalScreenersMediaManagerContext } from "../../app/digital-screeners-media-manager/digital-screeners-media-manager";

export type PackageItemProps = {
  item: DSPackage;
  className?: string;
  endAdornment?: ReactElement;
  disabled?: boolean;
};

export const PackageItem = (props: PackageItemProps): JSX.Element => {
  const { item, className, endAdornment, disabled } = props;

  const uploadWidgetContext = useContext(DigitalScreenersMediaManagerContext);
  const { context } = uploadWidgetContext as {
    context: string;
  };

  const titleName = item.metadata?.title?.name ?? "";
  const seasonNumber: string =
    item.metadata?.episode?.seasonNumber ||
    item.metadata?.season?.seasonNumber ||
    item.metadata?.season?.sequence?.toString() ||
    "";
  const seasonName = seasonNumber ? `S${seasonNumber}` : "";
  const episodeNumber = item.metadata?.episode?.seasonEpisodeNumber
    ? `E${item.metadata?.episode?.seasonEpisodeNumber}`
    : "";
  const episodeName = item.metadata?.episode?.name ?? "";

  const hasEpisodeAndSeasonNumbers = seasonName !== "" && episodeName !== "";
  const episodeDetails = `${seasonName}${
    hasEpisodeAndSeasonNumbers ? "-" : ""
  }${episodeNumber} ${episodeName}`;
  const hasEpispodeDetails = episodeDetails.replaceAll(" ", "") !== "";
  const packageTitle = `${titleName}${
    hasEpispodeDetails ? " | " : ""
  }${episodeDetails}`;

  const fileName = item.files?.[0]?.file_name ?? "";

  const packageId = item.package_id ?? "";
  const lastModifiedDate = formattedDate(item.last_modified, context);

  return (
    <div
      className={`package-item ${className ?? ""} ${
        disabled ? "disabled" : ""
      }`}
    >
      <div className="package-item__info-container">
        <div className="package-item__info-container__title">
          <OverflowableText text={packageTitle} />
        </div>
        <div className="package-item__info-container__detail">
          <b>File Name: </b>
          <span>{fileName}</span>
        </div>
        <div className="package-item__info-container__detail">
          <b>Package ID: </b>
          <span>{packageId}</span>
          {" | "}
          <b>{context === "DMDC" ? "Last Update (UTC):" : "Last Update:"} </b>
          <span>{lastModifiedDate}</span>
        </div>
        {disabled ? (
          <div className="package-item__info-container__error">
            <span>
              Unavailable file, please contact your Business Representative
            </span>
          </div>
        ) : null}
      </div>
      <div className="package-item__end-adornment">{endAdornment}</div>
    </div>
  );
};

PackageItem.defaultProps = {
  className: "",
  endAdornment: undefined,
  disabled: false,
};
