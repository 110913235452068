import { useState, useCallback } from "react";

/**
 * A custom hook to handle toggle logic that can be reused across the app.
 * @param {boolean | undefined} initialValue - Initial value for the toggle
 * hook.
 * @returns [value, toggleValue] - The current value of the toggle and a
 * function to toggle this value.
 */
export const useToggle = (
  initialValue?: boolean
): [
  boolean,
  // eslint-disable-next-line function-paren-newline
  (to?: boolean) => void
] => {
  const [value, setValue] = useState(initialValue || false);
  const toggleValue = useCallback((to?: boolean) => {
    if (to !== undefined) {
      setValue(to);
    } else {
      setValue((v) => !v);
    }
  }, []);
  return [value, toggleValue];
};
