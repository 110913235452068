/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { AsperaService, CONNECT_INSTALLER } from "../../services/aspera";
import Connect from "../../services/aspera/classes/Connect";
import ConnectInstaller from "../../services/aspera/classes/ConnectInstaller";
import "./aspera-wrapper.scoped.scss";

export type AsperaWrapperProps = {
  /** Existing instance of the Aspera AW4.Connect class. */
  connectInstance?: Connect;
  /** Existing instance of the Aspera AW4.ConnectInstaller class. */
  connectInstallerInstance?: ConnectInstaller;
  children?: JSX.Element | HTMLElement;
};

/**
 * This functional component exposes an Aspera wrapper to bootstrap the
 * required instances to interact with the Aspera client, this wrapper is
 * intended to make easier the injection of this instances from the outside
 * if required.
 * @param {AsperaWrapperProps} props - Props values injected to the component.
 * @returns {JSX.Element}
 */
export const AsperaWrapper = (props: AsperaWrapperProps): JSX.Element => {
  const { connectInstance, connectInstallerInstance } = props;

  /** Variable to change the render state when the AsperaService is ready */
  const [isAsperaReady, setIsAsperaReady] = useState(false);

  useEffect(() => {
    /**
     * Create a new AsperaService instance and assign it to the global property
     * with the same name as the service class.
     */
    window.AsperaService = new AsperaService({
      config: {
        // SCRNS-3550: Remove the Aspera Check version condition during Uploads
        // minVersion: "3.10.0",
        dragDropEnabled: true,
        sdkLocation: CONNECT_INSTALLER,
      },
      connectInstance,
      connectInstallerInstance,
    });

    /**
     * Wait for the ready event to be emitted and change the value of the local
     * state.
     */
    window.AsperaService.events.ready.subscribe((isReady) => {
      setIsAsperaReady(isReady);
    });
  });
  return (
    <div className="aspera-wrapper">
      {(isAsperaReady && props.children) as any}
    </div>
  );
};

AsperaWrapper.defaultProps = {
  connectInstance: undefined,
  connectInstallerInstance: undefined,
};
