import React from "react";
import { Root, createRoot } from "react-dom/client";

import {
  DigitalScreenersMediaManager,
  DigitalScreenersMediaManagerProps,
  // eslint-disable-next-line max-len
} from "../lib/app/digital-screeners-media-manager/digital-screeners-media-manager";
import { UploadAPIService } from "../lib/services/upload-api/upload-api.service";
import { UserActionFlags } from "../lib/types/auth-settings.types";
import { DSEventEmitter, DSMediaManagerEvent } from "../lib/types/eventEmitter";

/**
 * Creates a new DigitalScreenersUploadWidget instance
 * @class
 */

export class DigitalScreenersMediaManagerWebComponent extends HTMLElement {
  root: Root | undefined;
  async connectedCallback(): Promise<void> {
    const props = Object.values(this.attributes).map((attribute) => {
      let { name } = attribute;
      // Mapping x-ds-portal-id prop to a common attribute used in the rest of
      // the application.

      if (attribute.name === "useractionflags") {
        let userActionFlags: UserActionFlags = null;

        if (!attribute.value) {
          return ["userActionFlags", userActionFlags];
        }
        try {
          userActionFlags =
            (JSON.parse(attribute.value) as UserActionFlags) || null;
        } catch (e) {
          console.log(attribute.value);
          console.log(e);
        }

        return ["userActionFlags", userActionFlags];
      }

      if (attribute.name === "x-ds-portal-id") {
        name = "portal";
      }

      return [name, attribute.value];
    });

    const eventEmitter: DSEventEmitter = {
      next: (mEvent: DSMediaManagerEvent): void => {
        this.dispatchEvent(
          new CustomEvent(mEvent.type, {
            detail: mEvent.payload,
          })
        );
      },
    };

    const entries = Object.fromEntries(
      props
    ) as DigitalScreenersMediaManagerProps;

    const token = entries.token;
    const context = entries.context;
    let portal = entries.portal;
    const applicationId = entries.portal;
    let userActionFlags: UserActionFlags = entries.userActionFlags;

    if (context === "DMDC") {
      const uploadAPIService = UploadAPIService.getInstance(
        token,
        portal,
        context
      );
      const response = await uploadAPIService.mapDmdcContextToDsPortal(portal);
      portal = response?.data?.portalId as string;
    }

    if (context !== "DMDC") {
      userActionFlags = {
        canViewUploadStatus: true,
        canUploadMediaFilesForPackaging: true,
      };
    }

    this.root = createRoot(this);

    this.root.render(
      React.createElement(DigitalScreenersMediaManager, {
        token,
        portal,
        context,
        applicationId,
        eventEmitter,
        userActionFlags,
      })
    );
  }

  disconnectedCallback(): void {
    this.root?.unmount();
  }

  set token(value: string) {
    this.setAttribute("token", value);
  }

  set portal(value: string) {
    this.setAttribute("x-ds-portal-id", value);
  }

  set context(value: string) {
    this.setAttribute("context", value);
  }

  set userActionFlags(value: string) {
    this.setAttribute("useractionflags", value);
  }
}

export default DigitalScreenersMediaManagerWebComponent;
