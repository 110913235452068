/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { RiCloseCircleFill } from "react-icons/ri";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DropZone, DropZoneFileDTO } from "../../components/drop-zone";
import { Steps, Step } from "../../components/steps";
import { ReadMore } from "../../components/read-more";
import { Tooltip } from "../../components/tooltip";
import { StatusIcon } from "../../components/status-icon";
import { Button } from "../../components/form";
import "./new-package-view.scss";
import {
  calcFilesByType,
  deleteFromLanguageFilesArray,
  ItemStatus,
  TableItemDTO,
  updateLanguageFilesArray,
  updateLanguageFilesStatus,
  validateVideoFiles,
} from "../../utils";
import { DropZoneFileTableItem, FileTable } from "../common/file-table";
import { DsMetadataSection } from "./steps/DsMetadataSection";
import { DmdcMetadataSection } from "./steps/DmdcMetadataSection";
import {
  CreatePackageRequestBody,
  CreatePackageResponse,
  GetNewPackageIdResponse,
  Metadata,
} from "../../services/upload-api/upload-api.types";
import {
  ToastContext,
  ToastContextDef,
  ToastType,
} from "../../components/toast";
import {
  DigitalScreenersMediaManagerContext,
  // eslint-disable-next-line max-len
} from "../../app/digital-screeners-media-manager/digital-screeners-media-manager";
import {
  ModalContext,
  ModalContextActions,
  ModalProps,
  ModalType,
} from "../../components/modal";
import { FullMessageLanguage } from "./full-message-language";
import { DSEventEmitter, WidgetEventType } from "../../types/eventEmitter";
import { DsTitleAPIService } from "../../services/title-api/ds-title-api.service";
import { DmdcTitleAPIService } from "../../services/title-api/dmdc-title-api.service";
import { UploadAPIService } from "../../services/upload-api/upload-api.service";
import { View } from "../../components";
import { useArray } from "../../hooks/useArray";
import { Info } from "../../components/info/info";
import { EmailList, EmailListModel } from "../../components/email-list";
import {
  ChildEntity,
  TitleAPIService,
} from "../../services/title-api/title-api.types";
const { REACT_APP_DMDC_FILE_SPECS_URL, REACT_APP_LANG_FILE_SPECS_URL } =
  process.env;
// eslint-disable-next-line max-len

const ASPERA_CLIENT_URL = "https://www.ibm.com/aspera/connect/";
const allowedMetadataEpisodeProperties = [
  "eidrL1",
  "isActive",
  "isPrimary",
  "isReviewed",
  "productId",
  "radarRegistrationStatus",
  "securityLevel",
  "type",
  "updatedAt",
  "name",
  "seasonName",
  "seriesName",
  "episodeNumber",
  "foreignEpisodeNumber",
  "seasonNumber",
  "seFormat",
  "class",
  "subclass",
  "color",
  "createdAt",
  "creativeFormat",
  "firstAirRunningOrder",
  "language",
  "estimatedRuntimeSecs",
  "seasonEpisodeNumber",
  "releaseDate",
  "sequence",
];
/**
 * This component will show the main menu view to allow the user to select
 * the action to perform inside the application:
 * - Create a new video
 * - Modify an existing video.
 * @returns {JSX.Element}
 */
export const NewPackageView = (): JSX.Element => {
  // #region State
  const [videoFile, setVideoFile] = useState([] as DropZoneFileDTO[]);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [loadingVideoFile, setLoadingVideoFile] = React.useState(false);
  const languageFilesArray = useArray<DropZoneFileDTO>([]);
  const [selectedLanguageFiles, setSelectedLanguageFiles] = useState<
    DropZoneFileDTO[]
  >([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [fileCounters, setFileCounters] = useState({
    audio: 0,
    subtitle: 0,
    forcedSubtitle: 0,
    dubcard: 0,
  });
  const [metadata, setMetadata] = useState<Metadata | undefined>();
  const [packageId, setPackageId] = useState("");
  const [languageError, setLanguageError] = useState(false);
  const [emailList, setEmailList] = useState<EmailListModel>({
    emails: [],
    isValidList: true,
  });
  const [disableUpload, setDisableUpload] = useState(false);
  const [responseError, setResponseError] = useState("");
  // #endregion

  // #region Contexts
  const uploadWidgetContext = useContext(DigitalScreenersMediaManagerContext);
  const { titleAPI, uploadAPI, portal, eventEmitter, context } =
    uploadWidgetContext as {
      uploadAPI: UploadAPIService;
      titleAPI: TitleAPIService;
      userEmail: string;
      portal: string;
      eventEmitter: DSEventEmitter;
      context: string;
    };

  const toasterContext = useContext(ToastContext);
  const modalContext = useContext(ModalContext);
  // #endregion

  // #region Services
  const { current: AsperaServiceInstance } = useRef(window.AsperaService);
  // #endregion

  // #region Helpers
  const history = useNavigate();

  const getNewPackageIdAndSetVideoFile = async (
    files: DropZoneFileDTO[]
  ): Promise<void> => {
    setLoadingVideoFile(true);
    try {
      if (files.length > 0) {
        const fileStatus = validateVideoFiles(files);
        if (fileStatus.status) {
          if (!packageId.length) {
            // eslint-disable-next-line max-len
            const response: GetNewPackageIdResponse =
              await uploadAPI.getNewPackageId(portal);
            const mPackageId = response.data?.response.package_id || "";
            setPackageId(mPackageId);
            setCurrentStep(currentStep + 2);
            eventEmitter.next({
              type: WidgetEventType.PACKAGE_ID_CREATED,
              payload: mPackageId,
            });
          } else {
            setCurrentStep(2);
            setResponseError("");
          }
          setVideoFile(files);
        } else {
          setOpenMessage(true);
        }
      }
      setLoadingVideoFile(false);
    } catch {
      setLoadingVideoFile(false);
    }
  };
  // #endregion

  // #region Guards
  const canUpload = (): boolean =>
    metadata !== undefined &&
    videoFile.length > 0 &&
    emailList.isValidList &&
    !languageError &&
    !disableUpload;
  // #endregion

  // #region Event handlers
  const onVideoFileSelected = (files: DropZoneFileDTO[]): void => {
    void getNewPackageIdAndSetVideoFile(files);
  };

  const onLanguageFilesSelected = (files: DropZoneFileDTO[]): void => {
    setSelectedLanguageFiles(files);
  };

  const onEmailListChanged = (emailListMoodel: EmailListModel): void => {
    setEmailList(emailListMoodel);
  };

  const onDeleteVideo = (): void => {
    setVideoFile([]);
  };

  const onDeleteLanguage = (item: DropZoneFileDTO): void => {
    const newLanguageFileArray = updateLanguageFilesStatus(
      deleteFromLanguageFilesArray(languageFilesArray.array, item)
    );
    languageFilesArray.set(newLanguageFileArray);
  };

  const onMetadataChanged = (metadataObject: Metadata | undefined): void => {
    setMetadata(metadataObject);
  };

  const onMetadataError = (msg: string): void => {
    setVideoFile([]);
    setCurrentStep(0);
    setResponseError(msg);
  };

  const createPackage = (): void => {
    setResponseError("");
    setDisableUpload(true);
    if (metadata) {
      metadata.extras = {
        userEmail: "",
        portal,
        mail_id: "",
        emailsToNotify: emailList.emails,
      };
    }

    const newPackageBody: CreatePackageRequestBody = {
      package_id: packageId,
      metadata: {
        ...metadata,
        episode: Object.fromEntries(
          Object.entries(metadata?.episode || {}).filter(([key]) =>
            allowedMetadataEpisodeProperties.includes(key)
          )
        ) as ChildEntity,
      } as Metadata,
      files: [...videoFile, ...languageFilesArray.array],
    };
    uploadAPI
      .createPackage({
        body: newPackageBody,
      })
      .then((createPackageResponse: CreatePackageResponse) => {
        if (createPackageResponse) {
          if (createPackageResponse.exception) {
            let errorMessage =
              "There was an unexpected error please try again.";
            if (createPackageResponse?.exception?.response?.data) {
              const { error } = createPackageResponse.exception.response.data;
              if (error) {
                errorMessage = error;
              }
            }
            setResponseError(errorMessage);
            return;
          }
          if (createPackageResponse.data) {
            AsperaServiceInstance?.ConnectInstance?.startTransfer(
              createPackageResponse.data[0].transfer_spec,
              { allow_dialogs: true },
              {
                success: (/* upload: any */) => {
                  eventEmitter.next({
                    type: WidgetEventType.NEW_PACKAGE_UPLOAD_STARTED,
                    payload: createPackageResponse.data,
                  });
                  const duration = 6000;
                  (toasterContext as ToastContextDef).actions.push({
                    duration,
                    message:
                      "Upload has successfully started. You will receive " +
                      "an email with updates.",
                    type: ToastType.SUCCESS,
                  });
                  const route = `/status/${packageId}`;
                  history(route);
                },
                // eslint-disable-next-line no-console
                error: (err: unknown) => console.log(err),
              }
            );
          }
        }
      })
      .finally(() => {
        setDisableUpload(false);
      });
  };

  const unpublishedTitleModal = (): void => {
    const modalProps: ModalProps = {
      type: ModalType.OK_CANCEL,
      title: "The chosen title is not enabled",
      children: <span>Are you sure you want to continue the upload?</span>,
      onCancel: () => {
        return;
      },
      onSuccess: (/* key: string | undefined */) => {
        createPackage();
      },
      actionStrings: {
        ok: "Continue",
        cancel: "Back to upload",
      },
    };
    (modalContext as { actions: ModalContextActions }).actions.push(modalProps);
  };

  const onClickUpload = (): void => {
    if (context === "DMDC" && metadata?.title?.isActive === false) {
      unpublishedTitleModal();
    } else {
      createPackage();
    }
  };
  // #endregion

  // #region Actions
  const handleLanguageCodesPopup = (): void => {
    if (window.langCodes) {
      window.langCodes();
    }
  };

  const cancel = (): void => {
    const modalProps: ModalProps = {
      type: ModalType.OK_CANCEL,
      title: "If you leave, your changes won’t be saved",
      children: null,
      onCancel: (key: string | undefined) => {
        // eslint-disable-next-line no-console
        console.log("Cancelled", key);
      },
      onSuccess: (/* key: string | undefined */) => {
        eventEmitter.next({
          type: WidgetEventType.NEW_PACKAGE_CANCELLED,
          payload: undefined,
        });
        history("/");
      },
      actionStrings: {
        ok: "Leave",
        cancel: "Back to upload",
      },
    };
    (modalContext as { actions: ModalContextActions }).actions.push(modalProps);
  };

  const handleCloseMessage = (): void => {
    setOpenMessage(false);
  };
  // #endregion

  // #region Effects
  useEffect(() => {
    const newLanguageFileArray = updateLanguageFilesStatus(
      updateLanguageFilesArray(languageFilesArray.array, selectedLanguageFiles)
    );
    languageFilesArray.set(newLanguageFileArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguageFiles]);

  useEffect(() => {
    setLanguageError(
      languageFilesArray.array.some(
        (languageFile: TableItemDTO): boolean =>
          languageFile?.status?.name === ItemStatus.error
      )
    );
    const newFileCounters = calcFilesByType(languageFilesArray.array);
    setFileCounters(newFileCounters);
  }, [languageFilesArray.array]);
  // #endregion

  const FullMessageVideo = (): JSX.Element => (
    <>
      {context == "DMDC" ? (
        <>
          <h3>For video files</h3>
          <ul>
            <li>
              You will need to download the&nbsp;
              <a
                href={ASPERA_CLIENT_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Aspera Client
              </a>
              &nbsp;on your computer
            </li>
            <li>
              To take advantage of <strong>asset auto-tagging</strong> based
              upon file naming, please use the Enterprise naming
              convention&nbsp;
              <a
                href="https://mediatechspecs.disney.com/#/production/naming-convention/naming-convention"
                target="_blank"
                rel="noopener noreferrer"
              >
                Enterprise naming convention
              </a>
              <ul>
                <li>
                  Enterprise file naming examples to provide for optimal asset
                  Auto-Tagging based upon filename:
                </li>
              </ul>
              <ol>
                <ul>
                  <li>
                    Antlers_OV_V15037423_FP_DPO_SCRN_HD-SDR_23_ENG-239_ENG-20.MP4
                  </li>
                  <li>
                    TripleTap_ORIG-<b>WIP</b>
                    _V30201002_FP_DPO_SCRN_HD-SDR_23_YU1-235_YUE-20.mp4 ; -
                    <b>WIP</b> indication for Preliminary Screeners
                  </li>
                  <li>
                    GreysAnatomy-E0387_ORIG_V30422522_FP_DPO_SCRN_HD-SDR_23_ENG-178_ENG.mp4
                  </li>
                  <li>
                    PromoForCrossover-S1_OV_VNONE_PR_DPO_SCRN_HD-SDR_23_ENG-200_ENG-20.mp4
                  </li>
                </ul>
              </ol>
            </li>
            <li>The only allowed video file formats are MP4 and MOV</li>
            <li>
              Click here for&nbsp;
              <a
                href={REACT_APP_LANG_FILE_SPECS_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                1080p MP4 Specs
              </a>
            </li>
            <li>
              Click here for&nbsp;
              <a
                href={REACT_APP_DMDC_FILE_SPECS_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Language Codes
              </a>
            </li>
          </ul>
          <br />
        </>
      ) : (
        <>
          <h3>For videos</h3>
          <ul>
            <li>
              You will need to download the&nbsp;
              <a
                href={ASPERA_CLIENT_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Aspera Client
              </a>
              &nbsp;on your computer
            </li>
            <li>The only allowed file formats are MP4 and MOV</li>
            <li>
              File Name Format Details:
              &lt;title_name&gt;_&lt;lang&gt;.&lt;ext&gt;
              <ul>
                <li>
                  Video file name should contain 3 letter language code as the
                  last element before the format.
                </li>
                <li>
                  No spaces or special characters in the file name are allowed,
                  &nbsp;only period, underscore and dash are allowed.
                </li>
                <li>Supported special characters: - (dash), _ (underscore)</li>
                <li>
                  Only one . (dot) at the end of the file name, for the file
                  extension.
                </li>
                <li>
                  Example:
                  <ul>
                    <li>
                      <b>DS_WWOMICKEYMOUSE_102-F002_1080P_ENG.MP4</b>
                    </li>
                  </ul>
                </li>
                <li>
                  Make sure for VAM/SFM files, the file name has &quot;VAM&quot;
                </li>
                <li>Include 1080P in the video file name for 1080p files.</li>
                <li>
                  Example:
                  <ul>
                    <li>
                      DS_WWOMICKEYMOUSE_102-F002_
                      <b>1080P</b>
                      _ENG.MP4
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Click here for:&nbsp;
              <a
                href={REACT_APP_LANG_FILE_SPECS_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                1080p MP4 Specs
              </a>
            </li>
            <li>
              Click here for&nbsp;
              <button
                type="button"
                className="link"
                onClick={handleLanguageCodesPopup}
              >
                Language Codes
              </button>
            </li>
          </ul>
        </>
      )}
    </>
  );

  return (
    <View>
      <div className="new-package-view">
        <h1 className="view__title">
          {context == "DMDC"
            ? "Add a video to create a new screener package"
            : "Add a video to create a new title package"}
        </h1>
        <div className="uw-row uw-col">
          <Steps currentStep={currentStep}>
            <Step
              title={
                packageId === ""
                  ? "Add video*"
                  : `Package ID ${packageId} created`
              }
              titleDescription="(required)"
            >
              {videoFile.length === 0 && (
                <div>
                  <ReadMore
                    shortMessage="File name requirements and specifications..."
                    fullMessage={<FullMessageVideo />}
                  />
                  <DropZone
                    className="video-drop-zone"
                    title="Drag and drop your video file here"
                    btnText="Select Files"
                    loading={loadingVideoFile}
                    onFilesSelected={onVideoFileSelected}
                    multiple={false}
                  />
                  <Dialog
                    open={openMessage}
                    onClose={handleCloseMessage}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xs"
                  >
                    <DialogTitle id="alert-dialog-title">
                      <RiCloseCircleFill />
                      Invalid file name
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Please check “File name requirements and specifications”
                        &nbsp;section and try again.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button value="Got it" onClick={handleCloseMessage} />
                    </DialogActions>
                  </Dialog>
                </div>
              )}
              {videoFile.length > 0 && packageId !== "" && (
                <FileTable
                  files={videoFile}
                  className="new-video-table"
                  onDelete={onDeleteVideo}
                  displayTrashIcon={true}
                  override={{
                    name: {
                      header: "Video",
                    },
                    type: {
                      header: "File Type",
                    },
                    language: {
                      header: "Language",
                    },
                  }}
                />
              )}
            </Step>
            <Step title="Add Metadata Information" behavior="hidden">
              {context === "DMDC" ? (
                <DmdcMetadataSection
                  titleAPI={titleAPI as DmdcTitleAPIService}
                  onMetadataChanges={onMetadataChanged}
                  videoFile={videoFile?.[0]}
                  onTitleError={onMetadataError}
                />
              ) : (
                <DsMetadataSection
                  titleAPI={titleAPI as DsTitleAPIService}
                  onMetadataChanges={onMetadataChanged}
                />
              )}
            </Step>
            <Step title="Add Language Files" titleDescription="">
              <div>
                <ReadMore
                  shortMessage="File name requirements and specifications..."
                  fullMessage={<FullMessageLanguage />}
                />
                <DropZone
                  className={`
                  language-drop-zone 
                  ${languageFilesArray.array.length > 0 ? "condensed" : ""}
                `}
                  title="Drag and drop your files here to upload"
                  btnText="Select Files"
                  onFilesSelected={onLanguageFilesSelected}
                  multiple
                />
                {languageFilesArray.array.length > 0 && (
                  <div>
                    <span className="files-text uw-row">
                      Files{" "}
                      <span className="files-number">
                        {languageFilesArray.array.length}
                      </span>
                    </span>
                    <span className="files-type-text uw-row">
                      {fileCounters.audio} Audio | {fileCounters.subtitle}{" "}
                      Subtitle | {fileCounters.forcedSubtitle} Forced Subtitle |{" "}
                      {fileCounters.dubcard} Dubcard
                    </span>
                    <FileTable
                      files={languageFilesArray.array}
                      className="new-language-files-table"
                      onDelete={onDeleteLanguage}
                      displayTrashIcon={true}
                      columns={[
                        {
                          key: "status",
                          header: "Status",
                          isAction: true,
                          actionMapper: (item: DropZoneFileTableItem) => (
                            <Tooltip text={item?.status?.message || ""}>
                              <StatusIcon
                                status={item?.status?.name || ""}
                                className={`uw-table__icon
                            uw-table__icon-${
                              item?.status?.name.toLowerCase() || ""
                            }`}
                              />
                            </Tooltip>
                          ),
                        },
                      ]}
                    />
                  </div>
                )}
              </div>
            </Step>
          </Steps>
        </div>
        {packageId && (
          <div className="uw-row uw-col">
            <EmailList onListChange={onEmailListChanged} />
          </div>
        )}
        <div className="uw-row uw-col">
          <div className="uw-col as-row">
            {responseError !== "" && (
              <Info type="error">{responseError as any}</Info>
            )}
          </div>
        </div>
        <div className="uw-row uw-col align-right">
          <div className="uw-col as-row align-right">
            <Button onClick={cancel} value="Cancel" className="transparent" />
            <Button
              disabled={!canUpload()}
              value="Upload"
              onClick={onClickUpload}
            />
          </div>
        </div>
      </div>
    </View>
  );
};
