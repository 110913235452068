/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Avatar,
} from "@mui/material";
import * as React from "react";
import { useContext, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { StatusFilesGrid } from "../../components/status-files-grid";
import "./status-row.scoped.scss";
import "./status-row.scss";
import { formattedDate } from "../../utils";
import { OverflowableText } from "../../components/overflowable-text";
import { DSPackage } from "@screeners/upload-datamodel";
import { useParams } from "react-router-dom";
import { DigitalScreenersMediaManagerContext } from "../../app/digital-screeners-media-manager/digital-screeners-media-manager";

export const StatusRow = (props: {
  row: DSPackage;
  rowIndex: number;
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { row, rowIndex } = props;
  const params = useParams();
  const isOpen = params.packageId === row.package_id;
  const [open, setOpen] = useState(isOpen);

  const uploadWidgetContext = useContext(DigitalScreenersMediaManagerContext);
  const { context } = uploadWidgetContext as {
    context: string;
  };

  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={rowIndex % 2 === 0 ? "gridRow" : "gridAlternateRow"}
      >
        <TableCell>{row.metadata?.title?.name ?? "-"}</TableCell>
        <TableCell className="centered-content">
          {row.metadata?.season?.seasonNumber ?? "-"}
        </TableCell>
        <TableCell className="centered-content">
          {row.metadata?.episode?.seasonEpisodeNumber ?? "-"}
        </TableCell>
        <TableCell>{row.metadata?.episode?.name ?? "-"}</TableCell>
        <TableCell className="capitalize">
          {row?.metadata?.title?.type}
        </TableCell>
        <TableCell className="nowrap-content centered-content">
          {row?.metadata?.title?.productId}
        </TableCell>
        <TableCell className="centered-content">
          <Avatar
            className="nowrap-content"
            sx={{
              display: "inline-flex",
              bgcolor: "#8b8b8b",
              minWidth: 24,
              width: "auto",
              padding: "0px 5px",
              height: 24,
              fontSize: 14,
            }}
            variant="rounded"
          >
            {row?.files?.length}
          </Avatar>
        </TableCell>
        <TableCell className="no-word-break">
          {formattedDate(row.last_modified, context)}
        </TableCell>
        <TableCell className="ellipsis">
          <OverflowableText text={row.package_id} />
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className="collapseRow">
        <TableCell style={{ padding: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div>
              <StatusFilesGrid rows={row?.files || []} />
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
