import React, { useCallback, useEffect, useState } from "react";
import { useArray } from "../../hooks";
import { Modal, ModalProps } from "./modal";
import "./modal-wrapper.scoped.scss";

/**
 * Create a local context for this component so it can be used by child elements
 * to add new modals.
 */
export const ModalContext = React.createContext<unknown>(null);

export interface ModalContextActions {
  push: (modalProps: ModalProps) => string;
  clear: () => void;
  remove: (key: string) => void;
}

/**
 * ModalWrapperProps defines the available properties that user can pass to the
 * component.
 */
export type ModalWrapperProps = {
  /** All the elements inside the ModalWrapper area. */
  children: JSX.Element | HTMLElement | Node;
  /** Event triggered every time the modals array is updated. */
  onModalWrapperQueueUpdated?: (modals: ModalProps[]) => void;
};

export const ModalWrapper = (props: ModalWrapperProps): JSX.Element => {
  const { children, onModalWrapperQueueUpdated } = props;

  /** Array handler for the modals. */
  const arrayHook = useArray<ModalProps>([]);
  const { array: modals } = arrayHook;

  const keysToRemoveQueue = useArray<string>([]);

  useEffect(() => {
    if (onModalWrapperQueueUpdated) {
      onModalWrapperQueueUpdated(modals);
    }
  }, [modals, onModalWrapperQueueUpdated]);

  /**
   * Find a specific Modal by its key.
   * @param {string} key - Modal key
   * @returns {number} - Modal index if found otherwise -1.
   */
  const getModalIndexByKey = useCallback(
    (key: string | undefined): number =>
      modals.findIndex(
        (modalProps: ModalProps): boolean => modalProps.modalKey === key
      ),
    [modals]
  );

  /** Callback used to set the latest removed modal. */
  const removeCallback = (key: string | undefined): void => {
    keysToRemoveQueue.push(key || "");
  };

  /** Effect used to trigger the modal removal event. */
  useEffect(() => {
    if (keysToRemoveQueue.array.length > 0) {
      const lastKey =
        keysToRemoveQueue.array[keysToRemoveQueue.array.length - 1];
      const index = getModalIndexByKey(lastKey);
      keysToRemoveQueue.remove(keysToRemoveQueue.array.length - 1);
      arrayHook.remove(index);
    }
  }, [keysToRemoveQueue, getModalIndexByKey, arrayHook]);

  /** Context actions available to child elements. */
  const [actions] = useState({
    /**
     * Function to add a new modal to the queue.
     * @param {ModalProps} modalProps
     */
    push: (modalProps: ModalProps): string => {
      const modalKey = `Modal-${Math.random()}`;
      arrayHook.push({
        ...modalProps,
        modalKey,
        onClose: removeCallback,
      });
      return modalKey;
    },
    /**
     * Clear all the Modal queue.
     */
    clear: (): void => {
      arrayHook.clear();
    },
    /**
     * Remove a specific Modal by its key.
     * @param {string} key - Modal key to hide.
     */
    remove: removeCallback,
  });

  const value: unknown = { modals, actions };

  return (
    <ModalContext.Provider value={value}>
      {children as any}
      {modals.length > 0 && (
        <div className="modal-wrapper">
          {modals.map(
            (modalProps: ModalProps): JSX.Element => (
              <Modal
                title={modalProps.title}
                type={modalProps.type}
                key={modalProps.modalKey}
                modalKey={modalProps.modalKey}
                onSuccess={modalProps.onSuccess}
                onCancel={modalProps.onCancel}
                onClose={modalProps.onClose}
                actionStrings={modalProps.actionStrings}
              >
                {modalProps.children}
              </Modal>
            )
          )}
        </div>
      )}
    </ModalContext.Provider>
  );
};

ModalWrapper.defaultProps = {
  onModalWrapperQueueUpdated: () => null,
};
