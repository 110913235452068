/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { SelectContext } from "./selectContext";
import "./select.scss";

/**
 * This type describes the properties related with a Select component and
 * the expected format for each of them.
 */
export type SelectProps = {
  allowFiltering?: boolean;
  className?: string;
  children?: ReactNode | ReactNode[];
  disabled?: boolean;
  onFilterChange?: (filterValue: string) => void;
  onOptionChange?: (newValue: string, value: any) => void;
  placeholder?: string;
  value?: string | null;
  loading?: boolean;
};

/**
 * This component contains the Select presented
 * on the widget that allows the user to select diffrent values
 * for the meta data
 * @param {SelectProps} props - Props values injected to the component.
 * @returns {JSX.Element}
 */
export const Select = ({
  allowFiltering,
  className,
  children,
  disabled,
  onFilterChange,
  onOptionChange,
  placeholder,
  value,
  loading,
}: SelectProps): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [filterValue, setFilterValue] = useState("");

  const selectPlaceholder = placeholder || "Select an option";

  const selectRef = useRef(null);

  function toggleShowOptions(): void {
    setShowOptions(!showOptions);
  }

  function clickOutsideHandler(): void {
    setShowOptions(false);
  }

  function updateSelectedOption(
    option: string,
    val: any,
    hideOnSelect = true,
    reset?: boolean
  ): void {
    const deselecting = option === selectedOption;
    const verifiedOption = deselecting ? "" : option;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const verifiedValue = deselecting ? undefined : val;
    setSelectedOption(verifiedOption);
    if (hideOnSelect === undefined || hideOnSelect) {
      setShowOptions(false);
    }
    if (reset) {
      setSelectedOption("");
    }
    setFilterValue(verifiedOption);
    if (onOptionChange) {
      onOptionChange(verifiedOption, verifiedValue);
    }
  }

  function updateFilterValue(val: string): void {
    setFilterValue(val);
    if (onFilterChange) {
      onFilterChange(val);
    }
  }

  useOnClickOutside(selectRef, clickOutsideHandler);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (children && (children as any).length === 0) {
      setSelectedOption("");
    }
  }, [children]);

  useEffect(() => {
    setSelectedOption(value || selectPlaceholder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <SelectContext.Provider
      value={{ selectedOption, changeSelectedOption: updateSelectedOption }}
    >
      <div
        data-testid="select"
        ref={selectRef}
        className={`select-container ${className || ""} ${
          disabled ? "disabled" : ""
        }`}
      >
        {allowFiltering ? (
          <input
            type="text"
            name="search"
            className="filtering-input"
            onChange={(event) => updateFilterValue(event.target.value)}
            onFocus={() => setShowOptions(true)}
            placeholder={selectPlaceholder}
            value={filterValue}
          />
        ) : (
          <div
            data-testid="selected-option"
            className="text"
            onClick={toggleShowOptions}
            aria-hidden="true"
          >
            {selectedOption.length > 0 ? selectedOption : selectPlaceholder}
            {loading ? (
              <CircularProgress
                size={28}
                thickness={6}
                sx={{
                  color: "#8b8b8b",
                  display: "inline-block",
                  padding: "3px",
                  position: "absolute",
                  right: "8px",
                  top: "6px",
                }}
              />
            ) : (
              <i
                className={`arrow ${showOptions ? "arrow-up" : "arrow-down"}`}
              />
            )}
          </div>
        )}
        {showOptions && (
          <ul
            data-testid="select-options"
            className={showOptions ? "show-dropdown-options" : ""}
          >
            {children}
          </ul>
        )}
      </div>
    </SelectContext.Provider>
  );
};

Select.defaultProps = {
  allowFiltering: false,
  className: "",
  disabled: false,
  onFilterChange: () => null,
  placeholder: "",
  loading: false,
  value: null,
};
