import React from "react";
import { Field, Select, Option } from "../../../components/form";
import { ChildEntity } from "../../../services/title-api/title-api.types";

export type SeasonFieldProps = {
  onSeasonSelectChange: (name: string, value: ChildEntity) => void;
  getSeasonName: (childEntity: ChildEntity) => string;
  value?: string;
  seasons: ChildEntity[];
  disabled: boolean;
  loading: boolean;
  required: boolean;
};

export const SeasonField = (props: SeasonFieldProps) => {
  const {
    disabled,
    loading,
    seasons,
    onSeasonSelectChange,
    value,
    getSeasonName,
    required,
  } = props;
  return (
    <Field disabled={disabled} label={"Season" + (required ? "*" : "")}>
      <Select
        value={value}
        loading={loading}
        onOptionChange={onSeasonSelectChange}
      >
        {seasons.map((season) => (
          <Option
            name={`${getSeasonName(season)}`}
            value={season}
            key={season.productId}
            hideOnSelect
          />
        ))}
      </Select>
    </Field>
  );
};
