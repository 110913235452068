/* eslint-disable react/no-array-index-key */
import React from "react";
import { ColumnDefinitionType } from "./table.types";

type TableHeaderProps<T, K extends keyof T> = {
  columns: Array<ColumnDefinitionType<T, K>>;
};

const TableHeader = <T, K extends keyof T>({
  columns,
}: TableHeaderProps<T, K>): JSX.Element => {
  const headers = columns.map((column, index) => (
    <th key={`headCell-${index}`}>{(column.header || column.key) as any}</th>
  ));

  return (
    <thead>
      <tr>{headers}</tr>
    </thead>
  );
};

export default TableHeader;
