/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import "./field.scoped.scss";

export type FieldProps = {
  label?: string;
  disabled?: boolean;
  children: JSX.Element;
  className?: string;
  fieldStyle?: undefined | "inline";
};

/**
 * The Field component works as a wrapper to create a common style that can be
 * easily extended to add features like error handling and validation.
 * @param {FieldProps} props - Props values injected to the component.
 * @returns {JSX.Element}
 */
export const Field = (props: FieldProps): JSX.Element => {
  const { label, children, disabled, className, fieldStyle } = props;
  /** Randomly generated id to link the field with the label. */
  const [inputId] = useState(`inp-${Math.random()}`);
  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return React.cloneElement(child, {
        id: inputId,
        disabled,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
    }
    return child;
  });
  return (
    <div
      className={`
      field 
      ${disabled ? "disabled" : ""} 
      ${fieldStyle || ""} 
      ${className || ""}
    `}
    >
      {label && <label htmlFor={inputId}>{label}</label>}
      <div className="field-component">{childrenWithProps}</div>
    </div>
  );
};

Field.defaultProps = {
  disabled: false,
  label: undefined,
  fieldStyle: undefined,
  className: "",
};
