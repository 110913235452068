import React, { useContext, useEffect, useRef, useState } from "react";
import md5 from "md5";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { SortLabel } from "../sort-label";
import { SortOrder } from "../../services/upload-api/upload-api.types";
import { FilterCriteriaFn, FilterDialog } from "../filter-dialog";
import { useArray } from "../../hooks";
import "./status-files-grid.scoped.scss";
import "./status-files-grid.scss";
import { formattedDate, languageNames, mapTransferStatus } from "../../utils";
import { TagForwardRef } from "../form/tags-input/tag";
import { OverflowableText } from "../overflowable-text";
import { DSFile, DSFileStatus } from "@screeners/upload-datamodel";
import {
  ResizableOptions,
  ResizableTableColumns,
} from "../../../../node_modules/@validide/resizable-table-columns/dist/js/es6/index.js";
import "../../../../node_modules/@validide/resizable-table-columns/dist/css/resizable-table-columns.min.css";
import { mapStatusColor } from "../../utils/mapStatusColor";
import { FileNameParser } from "../../utils/FileNameParser";
import { DigitalScreenersMediaManagerContext } from "../../app/digital-screeners-media-manager/digital-screeners-media-manager";

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-assignment
const FilesTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#8b8b8b",
    color: "#f0f0f0",
    fontSize: 12,
    whiteSpace: "nowrap",
    borderColor: "#f0f0f0",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-assignment
const ContentTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    textOverflow: "ellipsis",
  },
  [`&.${tableCellClasses.body}`]: {
    textOverflow: "ellipsis",
  },
}));

export type ComputedRow = {
  fileId: string;
  fileName: string;
  shortId?: string;
  assetId: string;
  language: string;
  createdByFullName: string;
  createdByEmail: string;
  lastModifiedDate: string;
  status: string;
  statusDetail: string;
  fileType: string;
};

export type StatusFilesGridProps = {
  rows: DSFile[];
};

export type TableField = {
  key: string;
  value: string;
  styles?: {
    width?: string;
    minWidth?: string;
    maxWidth?: string;
  };
};

const parseFullName = (fr: DSFile): string =>
  `${fr?.created_by?.first_name || ""} ${fr?.created_by?.last_name || ""}`;

export const StatusFilesGrid = (props: StatusFilesGridProps): JSX.Element => {
  const { rows } = props;
  const computedRows = useArray<ComputedRow>([]);
  const computedFilteredRows = useArray<ComputedRow>([]);
  const computedFilteredAndSortedRows = useArray<ComputedRow>([]);
  const [sortBy, setSortBy] = useState<keyof ComputedRow>("fileName");
  const [sortDirection, setSortDirection] = useState("desc" as SortOrder);
  const [dataIsSetted, setDataIsSetted] = useState<boolean>(false);
  const uploadWidgetContext = useContext(DigitalScreenersMediaManagerContext);
  const { context } = uploadWidgetContext as {
    context: string;
  };
  const [fields] = useState<TableField[]>([
    {
      key: "status",
      value: "Delivery Status",
      styles: { width: "150px" },
    },
    {
      key: "fileType",
      value: "Asset Type",
      styles: { width: "120px" },
    },
    {
      key: "language",
      value: "Language",
      styles: { width: "140px" },
    },
    {
      key: "lastModifiedDate",
      value: context === "DMDC" ? "Uploaded Date (UTC)" : "Uploaded Date",
      styles: { width: "180px" },
    },
    {
      key: "fileName",
      value: "File Name",
      styles: { width: "20%" },
    },
    {
      key: "createdByEmail",
      value: "Uploader Email",
      styles: { width: "20%" },
    },
    {
      key: "shortId",
      value: "Media ID",
      styles: { width: "120px" },
    },
    {
      key: "assetId",
      value: "Asset ID",
      styles: { width: "240px" },
    },
  ]);

  const sortRows = (
    array: Array<ComputedRow>,
    key: keyof ComputedRow,
    direction: string
  ): Array<ComputedRow> =>
    array.slice().sort((curr: ComputedRow, nxt: ComputedRow) =>
      ((direction === "desc" ? curr[key] : nxt[key]) ?? "").localeCompare(
        (direction === "desc" ? nxt[key] : curr[key]) ?? "",
        undefined,
        {
          numeric: true,
          sensitivity: "base",
        }
      )
    );

  const filterRows = (
    array: Array<ComputedRow>,
    criteria: string,
    key: keyof ComputedRow,
    query: string
  ): Array<ComputedRow> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let filterFn: any = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
    if ((FilterCriteriaFn as any)[criteria] !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      filterFn = (FilterCriteriaFn as any)[criteria];
    }
    if (criteria === "" || query === "") filterFn = FilterCriteriaFn.CLEAR;

    return array.slice().filter(
      (row) =>
        filterFn &&
        typeof filterFn === "function" &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        filterFn((row as any)[key], query)
    );
  };

  const onSortHandle = (key: keyof ComputedRow): void => {
    let direction = sortDirection;
    if (key === sortBy) {
      direction = (sortDirection === "asc" ? "desc" : "asc") as SortOrder;
    }
    setSortBy(key);
    setSortDirection(direction);
    computedFilteredAndSortedRows.set(
      sortRows(computedFilteredRows.array, key, direction)
    );
  };

  const onFilterHandle = (
    criteria: string,
    query: string,
    key: string
  ): void => {
    const filteredRows = filterRows(
      computedRows.array,
      criteria,
      key as keyof ComputedRow,
      query
    );
    const sortedAndFilteredRows = sortRows(filteredRows, sortBy, sortDirection);

    computedFilteredAndSortedRows.set(sortedAndFilteredRows);
  };

  const compareStatusDates = (a: DSFileStatus, b: DSFileStatus) =>
    a.timestamp > b.timestamp ? a : b;

  const applyDragFromBody = (
    dragHandlers: HTMLElement[],
    table: HTMLTableElement
  ): void => {
    dragHandlers.forEach((dragHandler) => {
      // eslint-disable-next-line no-param-reassign
      dragHandler.style.height = `${table.clientHeight}px`;
    });
  };

  const convertToTitleCase = (word: string | undefined): string => {
    if (!word) {
      return "";
    }
    const lowerCaseWord = word.toLowerCase();
    const titleCaseWord =
      lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1);
    return titleCaseWord;
  };

  useEffect(() => {
    const tmpComputedRows = rows.map((fileRow): ComputedRow => {
      const parser = new FileNameParser(fileRow?.file_name);
      return {
        fileId: fileRow?.file_id,
        fileName: fileRow?.file_name,
        shortId:
          fileRow?.asset_version !== undefined && fileRow?.asset_version > 1
            ? fileRow?.short_id?.concat(
                " (v",
                fileRow?.asset_version.toString(),
                ")"
              )
            : fileRow?.short_id,
        assetId: fileRow?.asset_id,
        language:
          languageNames[fileRow?.component_language as string] ||
          parser.mapLanguageCodeToLanguageName(),
        createdByFullName: parseFullName(fileRow),
        createdByEmail: fileRow?.created_by?.email || "",
        lastModifiedDate: formattedDate(
          fileRow?.last_modified_date || 0,
          context
        ),
        status: mapTransferStatus(
          fileRow?.status?.reduce(compareStatusDates).value || "Unknown State"
        ),
        statusDetail: mapTransferStatus(
          `Step - ${fileRow?.status?.reduce(compareStatusDates).value || ""}` ||
            "Unknown State"
        ),
        fileType:
          convertToTitleCase(fileRow?.component_type) || parser.getFileType(),
      };
    });
    computedRows.set(tmpComputedRows);
    computedFilteredRows.set(tmpComputedRows);
    computedFilteredAndSortedRows.set(
      sortRows(tmpComputedRows, sortBy, sortDirection)
    );
    if (rows.length > 0 && !dataIsSetted) {
      setDataIsSetted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const table = useRef(null);
  const resizableTableColumn = useRef<ResizableTableColumns | null>(null);

  useEffect(() => {
    const tableElement = table.current as unknown as HTMLTableElement;
    const options = new ResizableOptions();
    resizableTableColumn.current =
      resizableTableColumn.current ||
      new ResizableTableColumns(tableElement, options);
    tableElement.style.tableLayout = "auto";
  }, []);

  useEffect(() => {
    if (dataIsSetted) {
      const tableElement = table.current as unknown as HTMLTableElement;
      tableElement.style.tableLayout = "fixed";
      tableElement.style.borderCollapse = "separate";
      const dragHandlers = ([] as HTMLElement[]).slice.call(
        (resizableTableColumn.current?.dragHandlesContainer
          ?.children as HTMLCollection) ?? []
      );
      applyDragFromBody(dragHandlers, tableElement);
    }
  }, [dataIsSetted]);

  const thRefs = new Map();
  // eslint-disable-next-line max-len
  const getContainerRef =
    (k: unknown): (() => HTMLElement) =>
    (): HTMLElement =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      thRefs.get(k);

  return (
    <div className="package-components-table">
      <Table aria-label="files" ref={table}>
        <TableHead>
          <TableRow>
            {fields.map((field, i) => (
              <FilesTableCell
                key={field.key}
                data-rtc-resizable={field.key}
                ref={(th: any) => thRefs.set(i, th)}
                style={field.styles}
              >
                <SortLabel
                  className="tableHeadContainer"
                  sortActive={sortBy === field.key}
                  sortDirection={sortDirection}
                  onSort={() => onSortHandle(field.key as keyof ComputedRow)}
                >
                  <div className="overflowTableHead">{field.value}</div>
                  <FilterDialog
                    onFilter={(c, v) => onFilterHandle(c, v, field.key)}
                    getContainerRef={getContainerRef(i)}
                  />
                </SortLabel>
              </FilesTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {computedFilteredAndSortedRows.array.map((fileRow?: ComputedRow) => (
            <TableRow
              key={md5((fileRow?.fileId || "") + Math.random().toString())}
            >
              <ContentTableCell scope="row">
                <Tooltip
                  placement="top"
                  title={`${fileRow?.status || ""} - ${
                    fileRow?.statusDetail || ""
                  }`}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        maxWidth: "500px",
                        whiteSpace: "break-spaces",
                      },
                    },
                  }}
                >
                  <TagForwardRef
                    tag={{
                      id: `tag-status-${fileRow?.shortId ?? "-"}`,
                      value: fileRow?.status ?? "-",
                      color: mapStatusColor(fileRow?.status ?? ""),
                      isValid: true,
                      isDuplicated: false,
                    }}
                    closable={false}
                  />
                </Tooltip>
              </ContentTableCell>
              <ContentTableCell>
                <OverflowableText text={fileRow?.fileType} />
              </ContentTableCell>
              <ContentTableCell>
                <OverflowableText text={fileRow?.language} />
              </ContentTableCell>
              <ContentTableCell>
                <OverflowableText text={fileRow?.lastModifiedDate} />
              </ContentTableCell>
              <ContentTableCell>
                <OverflowableText text={fileRow?.fileName} />
              </ContentTableCell>
              <ContentTableCell>
                <OverflowableText text={fileRow?.createdByEmail} />
              </ContentTableCell>
              <ContentTableCell>
                <OverflowableText text={fileRow?.shortId} />
              </ContentTableCell>
              <ContentTableCell>
                <OverflowableText text={fileRow?.assetId} />
              </ContentTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
export default StatusFilesGrid;
